import React, { useEffect } from "react";
import { Routes } from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { AuthAction, DrawerAction, LoadAction, ProfileAction } from "@temp/store/actions";
import {
  onMessageListener,
  requestFirebaseNotificationPermission,
} from "../utils/firebaseNew";
import { getItem, setItem, deviceId } from "@temp/utils/helper";
import { parse } from "query-string";
import { useHistory } from "react-router";
import rootReducer from "@temp/store/reducers";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../src/utils/mobileEventHandler";
export type RootState = ReturnType<typeof rootReducer>;
import { Partytown } from '@builder.io/partytown/react';


function Head() {
  return (
    <>
      <Partytown debug={true} forward={['dataLayer.push']} />
    </>
  );
}

Head()

const App: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ProfileInfo = useSelector((state: RootState) => state?.Profile?.profile);

  // get loads api call when notification recieved 
  const getLoadsOnNotification = (payload) => {
    dispatch(
      ProfileAction.setDashboardFilters({
        loadType: "Loads Received",
        inquiryType: payload == "spot_rate" ? "spot" : "contract",
        activeCategory: "new",
      })
    );
    dispatch(
      LoadAction.getLoads({
        refresh: false,
        fromNoti: true,
        inquiry_type:
          payload == "spot_rate" ? "spot_rate" : "contract_rate",
        status: "new",
        load_post_type: "received",
        load_allocate_type: "all",
        search: "",
        next_page: 1,
        load_type: "all",
      })
    );
  }

  // handle mobile back button press event 
  const handleBackMobile = () => {
    history.goBack()
    if (window.location.pathname == "/home") {
      if (sendEventToAndroid("exitApp")) {
        window.Android.exitApp(
          String("exit")
        );
      } else if (sendEventToiOS("accessCamera")) {
        window.webkit.messageHandlers.accessCamera.postMessage(
          JSON.stringify({
            access: "access",
          })
        );
      }
    }
  }

  const downloadCSV = (params) => {
    if (isBrowser) {
      window.open(params, "_blank");
    } else {
      if (sendEventToAndroid("dashboardGenerateReport")) {
        window.Android.generateCsv(String("dashboard-generate-report"));
      } else if (sendEventToiOS("dashboardGenerateReport")) {
        window.webkit.messageHandlers.accessCamera.postMessage(
          JSON.stringify({
            access: "dashboard-generate-report",
          })
        );
      } else {
      }
    }
  }

  // press notification 
  const callbackNotification = (click_action, data) => {
    if (data?.title == "Report Generated") {
      window.open(data?.report_url, "_blank");
    } else if (click_action == "https://mg-dev-poster-recipient-app.herokuapp.com/all-notification") {
      history.push("/all-notification", { title: data?.title, body: data?.body })
    } else {
      history.push(click_action.split(".com")[1])
    }
  }

  // update FCM token 
  const handleGetToken = async () => {

    if (getItem("token")) {

      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {

          if (ProfileInfo?.device_tokens && ProfileInfo?.device_tokens.length) {
            let isAvailable = ProfileInfo.device_tokens.find(
              (val) => val.fcm_token === firebaseToken
            );
            if (isAvailable) {
              return;
            }
          }
          dispatch(
            ProfileAction.updateFCM({
              fcm_token: firebaseToken,
              device_id: deviceId(),
            })
          );
        })
        .catch((err) => {
          return err;
        });
    }
  };


  useEffect(() => {
    const queryParams = parse(history.location.search);
    if (queryParams?.notiUrl) {
      dispatch(AuthAction.setNotiUrl(queryParams?.notiUrl))
    }
    dispatch(AuthAction.setNativeToken(queryParams?.token));
    if (queryParams?.token) {
      setItem("token", queryParams?.token);
      setItem("toc_accepted", true);
      setItem("changed_password", true);
      history.push('/home')
    }
    dispatch(AuthAction.toggleLoader(false))
    handleGetToken();

    onMessageListener(callbackNotification);
  }, []);

  // listen mobile events 
  useEffect(() => {
    const backPressHandle = (message) => {
      handleBackMobile()
    }

    const addedToNetworkMobile = (message) => {
      dispatch(DrawerAction.GetNetwork({ only_company_users: "" }))
      history.push("/network-new");
    }

    const mobileBackPress = (message) => {
      getLoadsOnNotification(message?.detail);
    };

    window.addEventListener("backButtonPressed", backPressHandle)
    window.addEventListener("UserAddedInNetwork", addedToNetworkMobile)
    window.addEventListener("backButtonPressedd", mobileBackPress)
    // return () => {
    //   window.removeEventListener("backButtonPressed", backPressHandle)
    //   window.removeEventListener("UserAddedInNetwork", addedToNetworkMobile)
    //   window.removeEventListener("backButtonPressedd", mobileBackPress)
    // }
  }, []);

  return (
    <div>
      <Routes />
    </div>
  );
};
export default App;