import React, { useEffect, useState, useRef } from "react";
import { IActiveLoadsPageProps } from "./types";
import {
	CustomModal,
	GoogleMap,
	Loader,
	LocationTimeline,
	TitleLayout,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import rootReducer from "../../../store/reducers";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
export type RootState = ReturnType<typeof rootReducer>;
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material/";
import { AuthAction, LoadAction } from "@temp/store/actions";
import { Images } from "@temp/assets";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ActiveVehicles, LoadViewer, Poster } from "../components";
import "./style.scss"
import { isBrowser } from "react-device-detect";

const Page: React.FC<IActiveLoadsPageProps> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams()
	const loadDetail = useSelector((state: RootState) => state.Load.loadDetail);
	const vehiclePinPointMarker = useSelector((state: RootState) => state?.Load?.vehiclePinPointMarker);
	const [showModal, setShowModal] = useState(false)
	const [isFulScreen, setisFulScreen] = useState(false)
	const [center, setCenter] = useState({ lat: 24.8631724, lng: 67.0251307 });
	const [pakCenter, setPakCenter] = useState({
		lat: 30.3753, lng: 69.3451
	})

	const [marker, setMarker] = useState({ lat: 24.9705032, lng: 67.1005716 });
	const [totalContainers, setTotalContainers] = useState(0);
	const [expand, setExpand] = useState(true);
	const [expandVehicle, setExpandVehicle] = useState(true);

	const [markers, setMarkers] = useState([]);

	const [is_seen_by_viewer, set_is_seen_viewer] = useState(false)

	useEffect(() => {
		let jobLoadRecId = params?.id
		setTimeout(() => {
			if (loadDetail?.is_seen == 0) {
				if (!is_seen_by_viewer) {
					isShowIndicationDot(jobLoadRecId)
					set_is_seen_viewer(true)
				}
			}
		}, 200);
	}, [loadDetail])

	const isShowIndicationDot = (jobLoadRecId) => {
		dispatch(LoadAction.showIndicationDotsViewer({
			load_id: jobLoadRecId,
		}))
	}

	// const callBack = (param) => {

	// 	if (param == "200") {
	// 		dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
	// 	} else {
	// 		history.goBack()
	// 	}
	// }

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		dispatch(LoadAction.getLoadDetail({ load_id: params?.id, callBack }));
	// 		dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
	// 	}, 200);
	// }, [])


//  for stopping 2 times API call of live-location and for zoom properly work as well (start)
	const callBack = (param) => {
		if (param === "200") {
			// Only dispatch pinPointsForVehicle if it has not been dispatched yet
			if (!pinPointsCalled.current) {
				dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
				pinPointsCalled.current = true;  // Mark the pinPointsForVehicle call as made
			}
		} else {
			history.goBack();
		}
	};

	const pinPointsCalled = useRef(false);  // Ref to track if pinPointsForVehicle has been called

	useEffect(() => {
		setTimeout(() => {
			dispatch(LoadAction.getLoadDetail({ load_id: params?.id, callBack }));
			// Only dispatch pinPointsForVehicle once, regardless of the live-locations API being called
			if (!pinPointsCalled.current) {
				dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
				pinPointsCalled.current = true;  // Mark the pinPointsForVehicle call as made
			}
		}, 200);
	}, []);

	//  for stopping 2 times API call of live-location and for zoom properly work as well (end)


	let pickup_dropoff_location = []
	let additional_locations = []

	if (loadDetail) {
		if (loadDetail?.additional_dropoffs.length) {
			pickup_dropoff_location.push(loadDetail?.locations[0])
			additional_locations.push(loadDetail?.locations[1])
			let tempIndex = null
			loadDetail?.additional_dropoffs?.forEach((element, index) => {
				if (element?.lat && element?.long) {
					pickup_dropoff_location[1] = element
					tempIndex = index
				}
			});
			loadDetail?.additional_dropoffs?.forEach((element, index) => {
				if (element?.lat && element?.long && index !== tempIndex) {
					additional_locations.push(element)
				}
			});

			if (pickup_dropoff_location.length !== 2) {
				pickup_dropoff_location[1] = loadDetail?.locations[1]
			}
		} else {
			pickup_dropoff_location.push(loadDetail?.locations[0])
			pickup_dropoff_location.push(loadDetail?.locations[1])
		}
	}


	useEffect(() => {
		if (loadDetail) {
			let totalCat = 0;
			if (loadDetail?.categories?.length > 0) {
				loadDetail?.categories.forEach(element => {
					if (element?.is_twin_load) {
						if (loadDetail?.load_type?.title === "Containerized") {
							totalCat += Number(element.vehicle_quantity) * 2
						} else {
							totalCat += Number(element.vehicle_quantity)
						}
					} else {
						totalCat += Number(element.vehicle_quantity)
					}
				});
			}
			setTotalContainers(totalCat)
		}
	}, [loadDetail])


	const addViewer = (data: any) => {
		history.push("/select-recipient/add-viewer", data);
	};

	const handleRefresh = async () => {
		dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
		dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
	}

	function getViewerLatLong() {
		dispatch(
			LoadAction.pinPointsForVehicle({
				load_id: "",

			})
		);
	}

	const [showDiv, setShowDiv] = useState(true)

	useEffect(() => {
		if (loadDetail) {
			dispatch(AuthAction.toggleLoader(true))
			setTimeout(() => {
				dispatch(AuthAction.toggleLoader(false))
				setShowDiv(false)
			}, 1000);
		}
	}, [loadDetail])

	if (loadDetail) {
		if (showDiv) {
			return (
				<Loader show={true} />
			)
		} else {
			return (
				<TitleLayout progressFlag={true} progress={0} titleOnly titleOnlyText={`Load ID - ${params?.id}`}>
					<div className="loadDetailWrapper activeLoadWrap">
						{showDiv && <div style={{ height: 1000 }}></div>}
						<div className="googleMapContainer">
							<div style={{ height: 320 }} className="googleMap">
								{
									!isBrowser ?
										<div onClick={() => {
											setisFulScreen(!isFulScreen)
											if (!isFulScreen) {
												document.querySelector('.googleMap').classList.add('inside-map-div1')
												document.querySelector('.googleMap').classList.remove('googleMap')
											} else {
												document.querySelector('.inside-map-div1').classList.add('googleMap')
												document.querySelector('.googleMap').classList.remove('inside-map-div1')
											}
										}} className="map-zoom-button">
											<img style={{
												height: '100%',
												width: '100%'
											}} src={!isFulScreen ? Images.ZoomMap : Images.UnZoomMap} />
										</div> : null
								}

								<GoogleMap
									// zoom={
									// 	vehiclePinPointMarker?.length > 1 ? 4
									// 		:
									// 		vehiclePinPointMarker?.length > 1 && pickup_dropoff_location[0]?.lat == null && pickup_dropoff_location[0]?.long == null ||
									// 			pickup_dropoff_location[1]?.lat == null && pickup_dropoff_location[1]?.long == null ? 4 :
									// 			pickup_dropoff_location[0]?.lat == null && pickup_dropoff_location[0]?.long == null ||
									// 				pickup_dropoff_location[1]?.lat == null && pickup_dropoff_location[1]?.long == null
									// 				? 12 :
									// 				pickup_dropoff_location[0]?.lat !== null && pickup_dropoff_location[0]?.long !== null ||
									// 					pickup_dropoff_location[1]?.lat !== null && pickup_dropoff_location[1]?.long !== null ?
									// 					8 :
									// 					pickup_dropoff_location?.length ? 12 :
									// 						16
									// }
									zoom={
										pickup_dropoff_location[0]?.lat !== null && pickup_dropoff_location[0]?.long !== null &&
											pickup_dropoff_location[1]?.lat == null && pickup_dropoff_location[1]?.long == null ? 4 :
											pickup_dropoff_location[0]?.lat == null && pickup_dropoff_location[0]?.long == null ||
												pickup_dropoff_location[1]?.lat == null && pickup_dropoff_location[1]?.long == null
												? 12 :
												pickup_dropoff_location[0]?.lat !== null && pickup_dropoff_location[0]?.long !== null ||
													pickup_dropoff_location[1]?.lat !== null && pickup_dropoff_location[1]?.long !== null ?
													16 :
													pickup_dropoff_location?.length ? 12 :
														16
									}
									screen="active_load"
									marker={
										vehiclePinPointMarker?.map((item) => {
											return {
												lat: item?.lat,
												lng: item?.lng,
												ChasisNo: item?.ChasisNo,
												VrnColor: item?.VrnColor,
												location: item?.location,
												EngineNo: item?.EngineNo,
												Ignition: item?.Ignition,
												speed: item?.speed,
												reg_no: item?.reg_no,
												is_moving: item?.is_moving,
												is_idle: item?.is_idle,
												vehicle_id: item?.vehicle_id
											}
										})
									}
									center={
										vehiclePinPointMarker?.length == 1 ?
											{
												lat: vehiclePinPointMarker[0]?.lat,
												lng: vehiclePinPointMarker[0]?.lng

											} :
											vehiclePinPointMarker?.length >= 2 ? pakCenter : center
									}

									locations={pickup_dropoff_location}
									additional_locations={additional_locations}
								/>

								{/* The below code for Google Maps zoom, 
								developed under Jalal's supervision around 4–5 months ago, 
								was modified due to client-reported issues. 
								The problem occurred when 3–4 vehicles were loaded,
								 as the map did not display the vehicles correctly and 
								 zoomed to a different area.
								*/}

								{/* <GoogleMap
									zoom={
										vehiclePinPointMarker?.length > 1 ? 4 :
											pickup_dropoff_location[0]?.lat == null && pickup_dropoff_location[0]?.long == null ||
												pickup_dropoff_location[1]?.lat == null && pickup_dropoff_location[1]?.long == null
												? 12 :
												pickup_dropoff_location[0]?.lat !== null && pickup_dropoff_location[0]?.long !== null ||
													pickup_dropoff_location[1]?.lat !== null && pickup_dropoff_location[1]?.long !== null ?
													16 :
													pickup_dropoff_location?.length ? 12 :
														16
									}
									screen="active_load"
									marker={
										vehiclePinPointMarker?.map((item) => {
											return {
												lat: item?.lat,
												lng: item?.lng,
												ChasisNo: item?.ChasisNo,
												VrnColor: item?.VrnColor,
												location: item?.location,
												EngineNo: item?.EngineNo,
												Ignition: item?.Ignition,
												speed: item?.speed,
												reg_no: item?.reg_no,
												is_moving: item?.is_moving,
												is_idle: item?.is_idle,
												vehicle_id: item?.vehicle_id

											}
										})
									}
									center={
										vehiclePinPointMarker?.length == 1 ?
											{
												lat: vehiclePinPointMarker[0]?.lat,
												lng: vehiclePinPointMarker[0]?.lng

											} :
											vehiclePinPointMarker?.length >= 2 ? pakCenter : center
									}

									locations={pickup_dropoff_location}
									additional_locations={additional_locations}
								/> */}

							</div>
						</div>

						<PullToRefresh
							onRefresh={handleRefresh}
						>
							<FadeInComponent>
								<Poster
									loadDetail={loadDetail}
									expand={expand}
									setExpand={setExpand}
									totalContainers={totalContainers}
									showMessage={true}
									fromViewer={true}
								/>
							</FadeInComponent>

							<FadeInComponent>
								<ActiveVehicles
									loadDetail={loadDetail}
									fromViewer={true}
									expandVehicle={expandVehicle}
									setExpandVehicle={setExpandVehicle}
									markersVehicle={vehiclePinPointMarker}
								/>
							</FadeInComponent>

							<FadeInComponent>
								<div className="accordion-container">
									<Accordion className="accordion-background"
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"

										>
											<p className="fw-5 accHeading">Location Details</p>
										</AccordionSummary>

										<AccordionDetails >
											<LocationTimeline
												location={[{ ...loadDetail?.locations[0] }, { ...loadDetail?.locations[1] }]}
												handleModal={(value: boolean) => { }}
												fromActive={false}
												is_viewer={false}
												LoadDetail={loadDetail}
												type={"received"}
												recipient_status={{
													status: 4
												}}
												is_recipient={false}
											></LocationTimeline>
										</AccordionDetails>
									</Accordion>
								</div>
							</FadeInComponent>

							<div>
								<LoadViewer
									showViewer={loadDetail?.total_awarded > 0}
									addViewer={addViewer}
									loadDetail={loadDetail}
								/>
							</div>

						</PullToRefresh>
					</div>

					<div className="allLocationModal">
						<CustomModal
							showModal={showModal}
							toggleModal={setShowModal}
							modalStyle={{ width: 300, minHeight: "unset", alignItems: "unset" }}
						>

						</CustomModal>
					</div>

				</TitleLayout >
			);

		}
	} else {
		return (<div></div>)
	}

};
export default Page;