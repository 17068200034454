import React, { useEffect, useState } from "react";
import { IProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import SwipeableDrawer from "@mui/material//SwipeableDrawer";
import { drawerData } from "@temp/utils/constants";
import { Button } from "@mui/material/";
import { Menu } from "@mui/icons-material/";
import { useDispatch, useSelector } from "react-redux";
import { CustomText } from "..";
import Dialog from "@mui/material//Dialog";
import DialogTitle from "@mui/material//DialogTitle";
import rootReducer from "@temp/store/reducers";
import { AuthAction, LoadAction, ProfileAction } from "@temp/store/actions";
import { deviceId, phoneNumberFormater, setItem, triggerMixpanelEvent } from "@temp/utils/helper";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../utils/mobileEventHandler";
import { isBrowser } from "react-device-detect";
import { requestFirebaseNotificationPermission } from "../../utils/firebaseNew";
import { store } from "@temp/store";
export type RootState = ReturnType<typeof rootReducer>;

export const DrawerNav: React.FC<IProps> = (IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const profile = useSelector((state: RootState) => state.Profile.profile);
  const selectedDrawerTab = useSelector(
    (state: RootState) => state.Profile.activeDrawerTab
  );
  const [showModal, setShowModal] = useState(false);

  const handleDrawerItem = (index, name) => {
    dispatch(ProfileAction.setActiveDrawerTab("hello"));
    setShow(false);
    switch (name) {
      case "Logout":
        if (sendEventToAndroid("logoutCall")) {
          window.Android.logoutCall("hello");
        } else if (sendEventToiOS("logoutCall")) {
          window.webkit.messageHandlers.logoutCall.postMessage(
            JSON.stringify({
              show_navigation_bar: "hello",
            })
          );
        } else {
          setShowModal(true);
        }
        break;
      case "My Contacts":
        history.push("/network-new");
        triggerMixpanelEvent("Network/Contacts Listing",
          "Click on my contacts button from hamburger to get contacts listing")
        break;
      case "Help":
        if (sendEventToAndroid("helpScreenCall")) {
          window.Android.helpScreenCall("hello");
        } else if (sendEventToiOS("helpScreenCall")) {
          window.webkit.messageHandlers.helpScreenCall.postMessage(
            JSON.stringify({
              show_navigation_bar: "hello",
            })
          );
        } else {
          history.push("/help");
        }
        break;
      case "My Profile":
        if (sendEventToAndroid("profileScreenCall")) {
          window.Android.profileScreenCall("hello");
        } else if (sendEventToiOS("profileScreenCall")) {
          window.webkit.messageHandlers.profileScreenCall.postMessage(
            JSON.stringify({
              show_navigation_bar: "hello",
            })
          );
        } else {
          history.push("/my-profile");
        }
        break;
     
      case "Terms and Conditions":
        if (sendEventToAndroid("termAndConditionCall")) {
          window.Android.termAndConditionCall("hello");
        } else if (sendEventToiOS("termAndConditionCall")) {
          window.webkit.messageHandlers.termAndConditionCall.postMessage(
            JSON.stringify({
              show_navigation_bar: "hello",
            })
          );
        } else {
          history.push("/terms-and-condition", { from: "terms" });
        }

        break;
      case "Saved Locations":
        triggerMixpanelEvent("Save location",
          "Click on my saved locations button from hamburger to get listing")
        if (sendEventToAndroid("savedLocationScreenCall")) {
          window.Android.savedLocationScreenCall("hello");
        } else if (sendEventToiOS("savedLocationScreenCall")) {
          window.webkit.messageHandlers.savedLocationScreenCall.postMessage(
            JSON.stringify({
              show_navigation_bar: "hello",
            })
          );
        } else {
          // history.push("/locations/pick");
          history.push("/locations");
        }
        break;
      case "My Vehicles":
        history.push("/vehicles");
        triggerMixpanelEvent("Listing of my vehicles",
          "Click on my vehicles button from hamburger to get vehicles listing")
        break;
      case "Account Balance":
        history.push("/account-balance");
        break;
      case "Draft Loads":
        history.push("/load-draft");
        break;
      // case "Load History":
      //   history.push("/load-history");
      //   break;
      // case "Reports":
      //   dispatch(ProfileAction.setReportsFilter({
      //     activeCategoryReports: "posted",
      //     activeDateFilter: 'weekly',
      //     fromResponseDate: new Date(),
      //     responseDate: new Date(),
      //   }))
      //   setTimeout(() => {
      //     history.push("/reports");
      //   }, 300)
      //   break;
      case "Dashboard & Reports":
        dispatch(ProfileAction.setReportsFilter({
          activeCategoryReports: "posted",
          activeDateFilter: 'today',
          fromResponseDate: new Date(),
          responseDate: new Date(),
        }))
        setTimeout(() => {
          history.push("/new-dashboard");
        }, 300)
        break;
      // case "Promotions":
      //   history.push("/promotions");
      //   break;
      case "Awarded Contracts":
        if (history?.location?.pathname?.split("/")[1] == "dashboard") {
        } else {
        }
        history.push('/dashboard/contract-posted/awarded', { showPostedRecievedTabs: true, showTabs: true })
        store.dispatch(LoadAction.getLoads({
          refresh: true,
          inquiry_type: "contract_rate",
          status: "awarded",
          load_post_type: "posted",
          load_allocate_type: "all",
          search: "",
          next_page: 1,
          load_type: "all",
        })
        )
        store.dispatch(ProfileAction.setDashboardFilters({
          loadType: "Loads Posted",
          inquiryType: "contract",
          activeCategory: 'awarded',
          selectedValue: "All"
        }))
        break;
    }
  };

  const callBack = () => {
    setItem("token", null);
    setItem("toc_accepted", null);
    setItem("changed_password", null);
    dispatch(AuthAction.resetStore());
    window.location.reload();
  };

  const logOut = async () => {
    setShow(false);
    setShowModal(false);
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        dispatch(AuthAction.logOut({ fcm_token: firebaseToken, device_id: deviceId(), cb: callBack }));
      })
      .catch(() => {
        dispatch(AuthAction.logOut({ cb: callBack }));
      });
  };

   useEffect(() => {
        const target = message => {
          setItem("token", null);
          setItem("toc_accepted", null);
          setItem("changed_password", null);
          window.location.reload();
        }
        window.addEventListener("logoutSuccessfull", target);
        return () => {
          window.removeEventListener("logoutSuccessfull", (e) => { })
        }
      }, [])

  return (
    <div className="drawerBtn">
      <Button
        className="drawer-button"
        onClick={() => {
          if (!isBrowser) {
            if (sendEventToAndroid("menuBurgerClicked")) {
              window.Android.menuBurgerClicked("hello");
            } else if (sendEventToiOS("menuBurgerClicked")) {
              window.webkit.messageHandlers.menuBurgerClicked.postMessage(
                JSON.stringify({
                  show_navigation_bar: "hello",
                })
              );
            } else {
              setShow(true);
            }
          } else {
            setShow(true);
          }
        }}
      >
        <Menu />
      </Button>
      <SwipeableDrawer
        anchor={"left"}
        open={show}
        onClose={() => {
          setShow(false);
        }}
        onOpen={() => {
          setShow(true);
        }}
      >
        <div className="drawer-header">
          <div className="drawer-close">
            <div onClick={() => setShow(false)} className="drawer-close-button">
              <img alt="" src={Images.BackArrow} />
            </div>
            <img src={Images.MgLogo} style={{ width: "60px" }} />
          </div>
          <div className="user-info">
            <img
              alt=""
              className="user-profile-image"
              src={profile?.picture || Images.myProfileImage}
            />
            <div>
              <p className="title">{profile?.name}</p>
              <p className="phone">
                {/* {`+92 ${profile?.phone_no}`} */}

                {`${`${phoneNumberFormater(profile?.phone_code, profile?.phone_no)}`} `}

              </p>
            </div>
          </div>
        </div>
        <div className="drawer-body">
          {drawerData.map((item, index) => {
            return (
              <div
                style={
                  index === selectedDrawerTab
                    ? { backgroundColor: Colors.Secondary }
                    : {}
                }
                onClick={() => {
                  handleDrawerItem(index, item.name);
                }}
                key={index}
                className="drawer-list"
              >
                <div className="drawer-icon-wrapper">
                  <span className={`drawer-icon ${item.iconClass} ${index === selectedDrawerTab ? 'active' : ''}`}></span>
                </div>
                <span
                  className={
                    index === selectedDrawerTab
                      ? "item-list active"
                      : "item-list"
                  }
                >
                  {item.name}
                </span>
              </div>
            );
          })}
          {/* {
            isAndroid && (
              <div
                onClick={() => {
                  if (sendEventToAndroid("clearCache")) {
                    window.Android.clearCache("hello");
                  }
                  setShow(false);
                }}
                className="drawer-list"
              >
                <div className="drawer-icon-wrapper">
                  <span className={`drawer-icon icons-cache`}></span>
                </div>
                <span
                  className={"item-list"}
                >
                  Clear Cache
                </span>
              </div>
            )
          } */}
        </div>
      </SwipeableDrawer>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgb(241,241,241)",
            color: "black",
            borderRadius: 20,
            width: 286,
          },
        }}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showModal}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              Log Out?
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Are you sure you want to logout?
            </CustomText.OpenSansRegular>
            <div className="action-button-container">
              <Button
                onClick={() => {
                  setShowModal(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Cancel
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  logOut();
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Log Out
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </Dialog>
    </div>
  );
};

export default DrawerNav;
