import React, { useEffect, useState, useRef } from "react";
import { ILoadDetailsPageProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { Accordion, Button, Checkbox, AccordionSummary } from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import { useParams } from "react-router-dom";
import OutlineButton from "@temp/components/OutLineButton";
import { AuthAction, CounterAtion, LoadAction, ProfileAction } from "@temp/store/actions";
import { showMessage, getFullTime, dateIsGreater, setItem, isObjectEmpty, triggerMixpanelEvent, urlRegex, price_units_transalation } from "@temp/utils/helper";
import { RecipientStatus } from "../components/RecipientStatus";
import { VehicleCounter } from "../components/VehicleCount";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { Grid } from "@mui/material/";
import Radio from "@mui/material//Radio";
import { AwardedVehicles } from "../components/AwardedVehicles";
import { Counter } from "@temp/components/Counter";
import { CONTRACT_DETAILS_URL, DASHBOARD_URL, LOAD_HISTORY_URL, SELECT_VEHICLE } from "@temp/app/routes";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
import { LOAD_TYPE } from "@temp/constants";
import InfoIcon from "@mui/icons-material/Info";
import domToPdf from 'dom-to-pdf';
import { isBrowser } from "react-device-detect";
import { parse } from "query-string";
import OutlinedInput from "@mui/material//OutlinedInput";

var numeral = require("numeral");
import {
  CustomText,
  VehicleIncDec,
  PrimaryButton,
  TitleLayout,
  CustomModal,
  OutLineButton,
  SimpleCheckBox,
  NewInput,
  TextInput,
  SimpleInput
} from "@components";
import {
  AdditionalDetail,
  CategoryCargoHeading,
  LocatonDetail,
  PaymentTerms,
  Poster,
  TransactionType,
  UploadedDocuments,
  ThreeColumnRTL,
  TwoColumnRTLWeight,
  TwoColumnRTLVehicle,
  PickupAndDropOff,
  VehicleCounterContract,
  AwardedVehiclesViewer,
  RecipientPrices,
  UploadDocByRecipient,
  UploadedDocumentsRecipient,
  LoadViewer,
  ActiveVehicles,


} from "../components";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { NeotiateBidAcceptReject } from "../components/NegotiateBidAcceptReject";
import { convertDataForFclAndBulkLoadDetailRedux, convertDataForLoadDetailRedux } from "@temp/utils/dataFormat";
import { Delete } from "@mui/icons-material";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

var clickHoldTimerInc = null
var clickHoldTimerDec = null
const Page: React.FC<ILoadDetailsPageProps> = (props) => {
  const downloadRef = useRef(null);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [toShowAccordion, setToShowAccordion] = useState(false)

  const [url, setURL] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [activeExpand, setActiveExpand] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
    }, 200);
  }, [])


  useEffect(() => {
    const queryParams = parse(history.location.search);
    dispatch(AuthAction.setNativeToken(queryParams?.token));
    if (queryParams?.token) {
      setItem("token", queryParams?.token);
      setItem("toc_accepted", true);
      setItem("changed_password", true);
    }
    if (queryParams?.pdf_generate) {
      setToShowAccordion(true)
    }
    setTimeout(() => {
      dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
    }, 200);
    dispatch(ProfileAction.GetProfile());
    dispatch(ProfileAction.JobLoadMeta(0));
  }, []);


  const [reciStatus, setReciStatus] = useState(null)




  const loadDetail = useSelector((state: RootState) => state.Load.loadDetail);
  const profile = useSelector((state: RootState) => state.Profile.profile);


  const declineReasons = useSelector((state: RootState) => state.Profile.postLoadMeta.decline_reasons);
  const bulk_vehicles = useSelector((state: any) => state?.Profile?.postLoadMeta?.vehicle_types);
  const filtered_bulk_vehicles = bulk_vehicles ? bulk_vehicles?.filter((item) => item?.is_detention == 1) : [];
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);
  // const packageType = postLoadMeta.package_types;
  // const PreferredVehicleType = postLoadMeta?.preferred_vehicles;
  // const load_type = postLoadMeta.load_types;
  // const inquiry_type = postLoadMeta.inquiry_types;

  // All modal state 
  const [showModal, setShowModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successFeeModal, setSuccessFeeModal] = useState(false);
  const [respondModal, setRespondModal] = useState(false);
  const [showModalNew, toggleModal] = useState(false);
  const [showMarkCompleteModal, setShowMarkCompleteModal] = useState(false);
  const [responded, setResponded] = useState([]);
  const [expand, setExpand] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [category_numbers, set_category_numbers] = useState([]);


  const [selectAllCat, setSelectAllCat] = useState(false);
  const [all_declined_disable, set_all_declined_disable] = useState(false)

  const [selectedValue, setSelectedValue] = React.useState("");
  const [respondToLcl, setRespondToLcl] = useState(false);
  const [frightTon, setFrightTon] = useState(null);
  const [pricePerTon, setPricePerTon] = useState(null);
  const [loadingPricePerTon, setLoadingPricePerTon] = useState(null);
  const [unLoadingPricePerTon, setUnLoadingPricePerTon] = useState(null);
  const [showForward, setShowForward] = useState(false);
  const [cargoDelete, setCargoDelete] = useState(false);
  const [isDeadlineComplete, setIsDeadlineComplete] = useState(false);
  const [declineReasonOther, setDeclineReasonOther] = useState("");
  const [lclBulk, setLCLBulk] = useState(false);
  const [awardVehicleNo, setAwardVehicleNo] = useState(1);
  const [awardVehicleNoArray, setAwardVehicleNoArray] = useState([]);
  const [awardVehicleNoArrayUnit, setawardVehicleNoArrayUnit] = useState([])
  const [showPopUp, setShowPopUp] = useState(false);
  const [aggreedPrice, setAggreedPrice] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [is_bid_freight, set_is_bid_freight] = useState(false);
  const [showLclFields, setShowLCLFiends] = useState(false);
  const [bit_detention_rates, set_bit_detention_rates] = useState([]);
  const [additionalCharges, setAddionalCharges] = useState("");
  const [info, setInfo] = useState("");
  const [recipient_mark_complete, set_recipient_mark_complete] = useState([])
  const [totalContainers, setTotalContainers] = useState(0);
  const [bid_deposit_fee_amount, set_bid_deposit_fee_amount] = useState('')
  const [disableAmount, setDisableAmount] = useState(false)
  const [detentionRate, setDetentionRate] = useState("");
  const [recipient_uploaded, set_recipient_uploaded] = useState([])
  const [detentionTariffPolicy, setDetentionTariffPolicy] = useState("");

  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const load_type = postLoadMeta?.load_types?.find((val: any) => val?.id === PostLoad?.load_type);
  const packageType = postLoadMeta.package_types;
  const inquiry_type = postLoadMeta.inquiry_types;
  const PreferredVehicleType = postLoadMeta?.preferred_vehicles;
  const vehiclePinPointMarker = useSelector((state: RootState) => state?.Load?.vehiclePinPointMarker);

  useEffect(() => {
    let data;
    if (loadDetail?.categories?.length > 0) {
      loadDetail?.categories?.forEach((item) => {

        if (item?.recipient_status == 4) {
          data = item?.recipient_status == 4
        }
      })
      setReciStatus(data)

    }
    else {
      if (loadDetail?.recipient_status == 4) {
        data = loadDetail?.recipient_status == 4
      }
      setReciStatus(data)
    }

  }, [loadDetail])


  useEffect(() => {
    if (loadDetail) {
      if (loadDetail?.is_load_history) {
        cloneALoadCallBackLoadHistory(loadDetail)
        setShowDetail(false)
      } else {
        setShowDetail(true)
      }
    }
  }, [loadDetail])

  const addViewer = (data: any) => {
    history.push("/select-recipient/add-viewer", data);
  };

  const isShowIndicationDot = (jobLoadRecId) => {
    if (!loadDetail?.is_viewer) {
      dispatch(LoadAction.showIndicationDots({
        load_id: loadDetail?.id,
        recipients: [jobLoadRecId?.id],
      }))
    }
  }

  const isShowIndicationDotForWeight = jobLoadRecId => {
    if (loadDetail?.is_viewer) {
    } else {
      dispatch(LoadAction.showIndicationDots({
        load_id: loadDetail?.id,
        recipients: [jobLoadRecId?.id],
      }))

    }

  }

  const cloneALoadCallBackLoadHistory = (loadDetailHistory: any) => {
    const callBack = () => {
      if (!isObjectEmpty(loadDetailHistory)) {
        let data = {};
        if (
          loadDetailHistory.load_type.title === LOAD_TYPE.bulk ||
          loadDetailHistory.load_type.title === LOAD_TYPE.bulk
        ) {
          data = convertDataForFclAndBulkLoadDetailRedux(
            loadDetailHistory,
            packageType,
            load_type,
            inquiry_type,
            PreferredVehicleType,
            "history",
            "Loads Received"
          );
        } else {
          data = convertDataForLoadDetailRedux(
            loadDetailHistory,
            packageType,
            load_type,
            inquiry_type,
            "history",
            "Loads Received"
          );
        }

        // return
        if (!isObjectEmpty(data)) {
          dispatch(ProfileAction.postLoadData({ ...data }));
          history.push("/confirm-load", { from_load_history: true, fromNoti: true });
        }
      }
    }
    dispatch(ProfileAction.JobLoadMeta(callBack));
  };

  useEffect(() => {
    if (loadDetail) {
      let totalCat = 0;
      if (loadDetail?.categories?.length > 0) {
        loadDetail?.categories.forEach(element => {
          if (element?.is_twin_load) {
            if (loadDetail?.load_type?.title === "Containerized") {
              totalCat += Number(element.vehicle_quantity) * 2
            } else {
              totalCat += Number(element.vehicle_quantity)
            }
          } else {
            totalCat += Number(element.vehicle_quantity)
          }
        });
      }
      setTotalContainers(totalCat)
    }
  }, [loadDetail])

  useEffect(() => {
    if (loadDetail) {
      set_recipient_uploaded(loadDetail?.my_uploads_by_recipient)
    }
  }, [loadDetail])



  // decline reason
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setDeclineReasonOther("");
  };

  const addVehicleClicked = (item) => {
    setAwardVehicleNo(() => item + 1);
  };

  useEffect(() => {
    if (loadDetail?.my_tracking_url?.length > 0) {
      setURL(loadDetail?.my_tracking_url[0]?.url);
      setUserName(loadDetail?.my_tracking_url[0]?.username);
      setPassword(loadDetail?.my_tracking_url[0]?.password);
    }
  }, [loadDetail])

  const handleLoadTrackingCredentials = () => {
    const payload = {
      url: url,
      username: username,
      password: password,
      load_id: loadDetail?.id,
    }
    dispatch(LoadAction.hanldeTrackingCredentials(payload));
  }

  // select category checkbox function
  const selectCat = (id, number) => {

    let selected_cat = [...selectedCategories];
    let numbers = [...category_numbers]
    if (selected_cat.indexOf(id) === -1) {
      selected_cat.push(id);
      numbers.push(number)
    } else {
      selected_cat.splice(selected_cat.indexOf(id), 1);
      numbers.splice(numbers.indexOf(number), 1)
    }

    setSelectedCategories(selected_cat);
    set_category_numbers(numbers)

    if (loadDetail?.categories?.length == selected_cat.length) {
      setSelectAllCat(true);
    } else {
      setSelectAllCat(false);
    }
  };

  // lcl and bulk weight and volume prefield
  const [disable_f_ton_field, set_disable_f_ton_field] = useState(false)
  useEffect(() => {
    if (loadDetail?.transaction_type?.title == "Bid") {
      let find = loadDetail?.recipients?.find(
        (itm) =>
          (loadDetail?.is_allocated && loadDetail?.allocates?.allocated_by == itm.recipient.id && itm.status !== 0) ||
          (itm.recipient?.id === profile?.id && itm.status !== 0)
      );

      if (find) {
        set_is_bid_freight(true);
        setRespondToLcl(true);
        setShowLCLFiends(true);
        setFrightTon(find?.bid?.bid_freight_ton);
        setPricePerTon(find?.bid?.price_per_freight);
        setLoadingPricePerTon(find?.bid?.loading_price_per_freight);
        setUnLoadingPricePerTon(find?.bid?.unloading_price_per_freight);
        setDetentionRate(find?.bid?.detention_reate)
        setAddionalCharges(find?.bid?.bid_detention_additional_charges);
        setDetentionRate(find?.bid?.bid_detention_rate)
        setDetentionTariffPolicy(find?.bid?.detention_tarif_policy)
        if (loadDetail?.is_allow_partial_load == 0) {
          set_disable_f_ton_field(true)
        }


      } else {
        if (loadDetail?.is_allow_partial_load == 0) {
          set_is_bid_freight(true);
          setRespondToLcl(true);
          if (loadDetail?.price_unit == "CBM") {
            setFrightTon(loadDetail?.cargo_volume);
          } else if (loadDetail?.price_unit == "Liter") {
            setFrightTon(loadDetail?.cargo_liter);
          } else if (loadDetail?.price_unit == "Ton") {
            setFrightTon(loadDetail?.gross_weight);
          } else if (loadDetail?.price_unit == "Kg") {
            setFrightTon(Number(loadDetail?.gross_weight) * 1000)
          } else {
            setFrightTon(loadDetail?.total_freight_ton);
          }
          set_disable_f_ton_field(true)
        }
      }
    } else {
      if (loadDetail?.is_allow_partial_load == 0) {
        set_is_bid_freight(true);
        setRespondToLcl(true);
        setFrightTon(loadDetail?.total_freight_ton);
        set_disable_f_ton_field(true)
      }
    }
  }, [loadDetail]);


  useEffect(() => {
    if (loadDetail?.is_container_deposit_by_transporter || !loadDetail?.is_allow_partial_load) {
      let new_respond = [];
      loadDetail?.categories?.forEach((item) => {

        if (item?.recipient_status !== 2) {
          new_respond.push({
            job_load_category_id: item?.id,
            no_of_vehicles: loadDetail?.is_unit_basis == 1 ? item?.unit_quantity : item?.vehicle_quantity,
            bid_price_per_vehicle: "",
            bid_price_loading: "",
            bid_price_unloading: "",
            is_disabled: true,
            job_load_recipient_id: item?.recipients[0]?.id,
            is_loading_required: item?.is_loading_required ? true : false,
            is_unloading_required: item?.is_unloading_required ? true : false,
            // detention_tarif_policy: "",
          });
        }
      });
      setResponded(new_respond);



    }

    else if (loadDetail?.is_cross_stuffing_required) {
      let new_respond = [...responded];
      loadDetail?.categories?.forEach((item) => {
        if (item?.recipient_status !== 2) {
          new_respond.push({
            job_load_category_id: item?.id,
            no_of_vehicles: item?.vehicle_quantity,
            bid_price_per_vehicle: "",
            bid_price_loading: "",
            bid_price_unloading: "",
            job_load_recipient_id: item?.recipients[0]?.id,
            is_loading_required: item?.is_loading_required ? true : false,
            is_unloading_required: item?.is_unloading_required ? true : false,
            // detention_tarif_policy: ""

          });
        }
      });
      setResponded(new_respond);

    }
  }, [loadDetail]);

  // click respond button
  const respond = (item) => {

    let new_respond = [...responded];
    if (new_respond.indexOf(item?.category_id) === -1) {
      let data = {
        job_load_category_id: item?.id,
        // no_of_vehicles: (loadDetail?.is_cross_stuffing_required || loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_unit_basis) ? loadDetail?.is_unit_basis?item?.unit_quantity: item?.vehicle_quantity : 1,
        // no_of_vehicles:loadDetail?.is_unit_basis?item?.unit_quantity:item?.vehicle_quantity,
        no_of_vehicles: 1,
        bid_price_per_vehicle: "",
        bid_price_loading: "",
        bid_price_unloading: "",
        fieldType: ['text', 'text', 'text', 'text', 'text', 'text'],
        bid_detention_rate: "",
        detention_tarif_policy: "",
      }
      if (item?.is_loading_required) {
        data["is_loading_required"] = true
      } else {
        data["is_loading_required"] = false
      }
      if (item?.is_unloading_required) {
        data["is_unloading_required"] = true
      } else {
        data["is_unloading_required"] = false
      }
      new_respond.push(data);

    }
    else {
      new_respond.splice(new_respond.indexOf(item), 1);
    }
    setResponded(new_respond);
    setShowForward(!showForward);
  };


  // check if already submitted bid
  useEffect(() => {
    if (loadDetail) {
      if (loadDetail?.transaction_type?.title == "Bid") {
        if (loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load) {
          if (loadDetail?.already_bid.length) {
            setResponded(loadDetail?.already_bid);
          } else {
            let new_respond = [];

            loadDetail?.categories?.forEach((item) => {

              if (item?.recipient_status !== 2) {
                new_respond.push({
                  job_load_category_id: item?.id,
                  // no_of_vehicles: item?.vehicle_quantity,
                  no_of_vehicles: loadDetail?.is_unit_basis == 1 ? item?.unit_quantity : item?.vehicle_quantity,
                  bid_price_per_vehicle: "",
                  bid_price_loading: "",
                  bid_price_unloading: "",
                  is_disabled: true,
                  job_load_recipient_id: item?.recipients[0]?.id,
                  is_loading_required: item?.is_loading_required ? true : false,
                  is_unloading_required: item?.is_unloading_required ? true : false,
                  // detention_tarif_policy: "",
                });
              }
            });
            setResponded(new_respond);

          }
        } else {
          setResponded(loadDetail?.already_bid);
        }
        set_bid_deposit_fee_amount(loadDetail?.already_bid[0]?.bid_deposit_fee_amount)
      }
      set_bid_deposit_fee_amount('')
      setDisableAmount(false)
      if (loadDetail?.already_bid?.length) {
        if (loadDetail?.is_container_deposit_by_transporter) {
          if (loadDetail?.already_bid[0]?.status !== 0) {
            setDisableAmount(true)
          }
        }
        set_bid_deposit_fee_amount(loadDetail?.already_bid[0]?.bid_deposit_fee_amount)
      }
    }
  }, [loadDetail]);


  const callBackToDashboard = () => {
    // history.replace(DASHBOARD_URL);
    triggerMixpanelEvent("Respond to Load", "respond to load by recipient")
    history.replace('/dashboard/spot-recieved/new', { showTabs: true })
  };



  // accept load or submit bid api call
  const acceptLoad = () => {
    let flag = true;
    if (loadDetail?.transaction_type?.title === "Bid") {
      responded.forEach((item) => {
        item["bid_deposit_fee_amount"] = bid_deposit_fee_amount
        if (loadDetail?.is_container_deposit_by_transporter && !loadDetail?.is_quote_price_deposit_fee_inclusive && !bid_deposit_fee_amount) {
          showMessage.current({
            message: "Bid deposit fee amount is required.",
            status: "error",
          });
          flag = false;
        }

        else {
          flag = true;
        }
      });
      if (flag) {

        // return
        dispatch(
          LoadAction.acceptLoad({
            load_id: params?.id,
            responded,
            callBackToDashboard,
          })
        );
      }
      setResponded([]);

    } else {
      responded.forEach((item) => {
        item["bid_deposit_fee_amount"] = bid_deposit_fee_amount
        if (loadDetail?.is_container_deposit_by_transporter && !loadDetail?.is_quote_price_deposit_fee_inclusive && !bid_deposit_fee_amount) {
          showMessage.current({
            message: "Bid deposit fee amount is required.",
            status: "error",
          });
          flag = false;
        } else {
          flag = true;
        }
      });
      if (flag) {

        // return
        dispatch(
          LoadAction.acceptLoad({
            load_id: params?.id,
            responded,
            callBackToDashboard,
          })
        );
        setResponded([]);
      }
    }
  };

  // accept load lcl and bulk weight and volumme
  const acceptLclWeight = () => {
    if (loadDetail?.transaction_type?.title === "Bid") {

      let payload = {
        bid_freight_ton: frightTon,
        price_per_freight: pricePerTon,
        total_price_freight: Number(pricePerTon) * frightTon,
        loading_price_per_freight: loadingPricePerTon,
        loading_price_total_freight: loadingPricePerTon
          ? Number(loadingPricePerTon) * frightTon
          : null,
        unloading_price_per_freight: unLoadingPricePerTon,
        unloading_price_freight: unLoadingPricePerTon
          ? Number(unLoadingPricePerTon) * frightTon
          : null,
        bid_detention_rate: detentionRate,
        bid_detention_additional_charges: additionalCharges,
        detention_tarif_policy: detentionTariffPolicy
      };
      if (frightTon == ".0" || frightTon == ".00") {
        showMessage.current({
          message: `Please enter a valid value of ${loadDetail?.price_unit}.`,
          status: "error",
        });
      } else {
        // return
        dispatch(
          LoadAction.acceptLoad({
            load_id: params?.id,
            responded: payload,
            callBackToDashboard,
          })
        );

      }
      // dispatch(
      //   LoadAction.acceptLoad({
      //     load_id: params?.id,
      //     responded: payload,
      //     callBackToDashboard,
      //   })
      // );
      // if (frightTon > Number(loadDetail?.total_freight_ton)) {
      //   showMessage.current({
      //     message:
      //       // "Fright ton should be less than total fright ton.",
      //       "Freight ton should be less than or equal to the total freight ton",
      //     status: "error",
      //   });
      // } else {
      //   dispatch(
      //     LoadAction.acceptLoad({
      //       load_id: params?.id,
      //       responded: payload,
      //       callBackToDashboard,
      //     })
      //   );
      // }
    } else {
      let payload = {
        bid_freight_ton: frightTon,
        price_per_freight: Number(loadDetail?.total_offer_price),
        total_price_freight: Number(loadDetail?.total_offer_price) * frightTon,
        loading_price_per_freight: Number(
          loadDetail?.loading_price_per_freight
        ),
        loading_price_total_freight: loadDetail?.loading_price_per_freight
          ? Number(loadDetail?.loading_price_per_freight) * frightTon
          : null,
        unloading_price_per_freight: Number(loadDetail?.price_per_freight),
        unloading_price_freight: loadDetail?.price_per_freight
          ? Number(loadDetail?.price_per_freight) * frightTon
          : null,
      };

      if (frightTon == ".0" || frightTon == ".00") {
        showMessage.current({
          message: `Please enter a valid value of ${loadDetail?.price_unit}.`,
          status: "error",
        });
      } else {
        // return
        dispatch(
          LoadAction.acceptLoad({
            load_id: params?.id,
            responded: payload,
            callBackToDashboard,
          })
        )
      }
      // if (frightTon > Number(loadDetail?.total_freight_ton)) {
      //   showMessage.current({
      //     message:"Freight ton should be less than or equal to the total freight ton",
      //     status: "error",
      //   });
      // } else {
      //   dispatch(
      //     LoadAction.acceptLoad({
      //       load_id: params?.id,
      //       responded: payload,
      //       callBackToDashboard,
      //     })
      //   );
      // }
    }
  };

  // disable button for vehicle basis
  const [firsTime, setFirstTime] = useState(true)
  useEffect(() => {
    if (responded.length) {
      setFirstTime(false)
    } else {
      setFirstTime(true)
    }
  }, [responded])
  const handleDisableVehicle = () => {
    if (loadDetail?.transaction_type?.title == "Bid") {
      if (loadDetail?.quote_by == 'vehicle' || loadDetail?.quote_by == "weight_volume") {
        if (firsTime) {
          return true
        } else {
          return responded?.filter(
            (val) => (val.is_loading_required &&
              (Number(val.bid_price_loading) === 0 || val.bid_price_loading === '')) ||
              (val.is_unloading_required &&
                (Number(val.bid_price_unloading) === 0 || val.bid_price_unloading === "")) ||
              Number(val.bid_price_per_vehicle) === 0 ||
              val.bid_price_per_vehicle === "" && val?.no_of_vehicles == "" || val?.no_of_vehicles == 0
          ).length
            ? true
            : false;
        }
      }
    } else {
      return responded.length ? false : true
    }
  }

  const handleDisableSpotContractVehicle = () => {
    return responded?.every((item) => item?.no_of_vehicles) ? false : true
  }

  // disable button for qoute by weight volumee
  const handleSubmitBidButtons = () => {
    if (loadDetail?.transaction_type?.title == "Offer") {
      return frightTon ? false : true
    }
    else {
      if (loadDetail?.is_loading_required && loadDetail?.is_unloading_required) {
        return (loadDetail?.transaction_type?.title == "Bid" && (Number(frightTon) !== 0) && (Number(pricePerTon) !== 0) && (Number(loadingPricePerTon) !== 0) && (Number(unLoadingPricePerTon) !== 0)) ? false : true
      } else if (loadDetail?.is_loading_required && !loadDetail?.is_unloading_required) {
        return ((Number(frightTon) !== 0) && (Number(pricePerTon) !== 0) && (Number(loadingPricePerTon) !== 0)) ? false : true
      } else if (loadDetail?.is_unloading_required && !loadDetail?.is_loading_required) {
        return ((Number(frightTon) !== 0) && (Number(pricePerTon) !== 0) && (Number(unLoadingPricePerTon) !== 0)) ? false : true
      } else if (loadDetail?.transaction_type?.title == "Bid") {
        return ((Number(frightTon) !== 0) && (Number(pricePerTon) !== 0)) ? false : true
      }
    }
  }



  const handleSubmitSpotContractWeight = () => {
    return frightTon ? false : true
  }

  // decline load api call
  const declineByRecipient = () => {
    if (loadDetail?.load_type?.title == "Non-Containerized" && !loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume") {
      dispatch(
        LoadAction.recipientDeclined({
          load_id: params?.id,
        })
      )
    } else {
      dispatch(
        LoadAction.recipientDeclined({
          load_id: params?.id,
          job_load_category_ids: selectedCategories,
          decline_reason: selectedValue,
          decline_reason_other: declineReasonOther,
          category_numbers
        })
      );

    }
  }



  // const handleRefresh = async () => {
  //   dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
  //   dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
  // }

  const [isInteractingWithMap, setIsInteractingWithMap] = useState(false);

  const handleMapInteractionStart = () => setIsInteractingWithMap(true);
  const handleMapInteractionEnd = () => setIsInteractingWithMap(false);

  const handleTouchStart = (event) => {
    const target = event.target.closest(".mapContent");
    if (target) {
      event.stopPropagation(); // Prevent bubbling to PullToRefresh
      handleMapInteractionStart();
    }
  };

  const handleTouchEnd = (event) => {
    const target = event.target.closest(".mapContent");
    if (target) {
      event.stopPropagation(); // Prevent bubbling to PullToRefresh
      handleMapInteractionEnd();
    }
  };

  const handleRefresh = async () => {
    try {
      // Dispatch actions to load data
      await dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
      await dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));

      // Optionally, show success message or handle any other state after refresh
    } catch (error) {
      // Handle any error that might occur during dispatch
    }
  };

  // after award add vehicle by recipient  
  const addVehicle = (params) => {
    history.push("/bulk-add-vehicle-screen", {
      from: "detail",
      vehicle_no: params?.index,
      load_id: loadDetail?.id,
      cat_id: params?.cat_id,
      already_added: params?.containers?.length,
      required_vehicles: params?.required_vehicles
    });
  };

  // after award add vehicle refrence by recipient  
  const addRef = (params) => {
    // return
    history.push("/container-reference", {
      from: "detail",
      vehicle_no: params?.index,
      load_id: loadDetail?.id,
      cat_id: params?.cat_id,
      ref_data: params?.ref_data,
      final_cat: params?.final_category,
      category: params.category,
      loadDetail: params?.loadDetail,
      is_cross_stuff: params?.is_cross_stuff,
      already_added: params?.already_added
    });
  };


  useEffect(() => {
    // let new_data = [...awardVehicleNoArray]
    // loadDetail?.categories?.forEach((element,ind) => {
    //   if(!new_data.indexOf(ind)){
    //     new_data.push(element?.vehicles_drivers_refs?.length||1)
    //   }
    // });
    if (loadDetail) {
      setAwardVehicleNoArray(loadDetail?.awardVehicleNoArray)
      setawardVehicleNoArrayUnit(loadDetail?.awardVehicleNoArrayUnit)
    }
  }, [loadDetail])


  const renderRTL = (key, val) => {
    return (
      <div className="details-Content">
        <div className="details-rtl">
          <CustomText.OpenSansRegular>{key}</CustomText.OpenSansRegular>
        </div>
        <div className="details-rtl">
          <CustomText.OpenSansSemiBold>{val}</CustomText.OpenSansSemiBold>
        </div>
      </div>
    );
  };

  const acceptContractSpot = () => {
    let is_cat = loadDetail?.categories.length;
    let categories = []
    loadDetail?.categories.forEach(element => {
      if (element?.recipients[0]?.status == 0) {
        categories.push({ job_load_recipient_id: element?.recipients[0]?.id, job_load_category_id: element?.id })
      }
    });
    if (is_cat) {
      let payload = {
        load_id: loadDetail?.id,
        categories: responded
      };

      dispatch(LoadAction?.acceptRequestVehicle(payload));
    } else {
      let payload = {
        load_id: loadDetail?.id,
        job_load_recipient_id: is_cat
          ? loadDetail?.categories[0]?.recipients[0]?.id
          : loadDetail?.recipients[0]?.id,
        job_load_category_id: is_cat ? loadDetail?.categories[0]?.id : null,
        total_freight_ton: frightTon

      };
      dispatch(LoadAction?.acceptRequestVehicle(payload));
    }
  };



  useEffect(() => {
    if (loadDetail?.vehicles_drivers_refs?.length > 0) {
      let id = loadDetail?.is_allocated ? loadDetail?.allocates[0]?.allocated_by : profile?.id
      setAwardVehicleNo(loadDetail?.vehicles_drivers_refs?.filter((item) => item?.recipient_id == id)?.length || 1);
    }
  }, [loadDetail]);

  useEffect(() => {
    if (loadDetail?.contract_id && loadDetail?.total_awarded > 0) {

      setIsDeadlineComplete(true)
    }
  }, [loadDetail])

  const renderComplete = () => {
    setIsDeadlineComplete(true);
  };

  const generatePdf = () => {
    const options = {
      filename: `Load ID ${loadDetail?.id}`
    };
    domToPdf(downloadRef?.current, options, () => {
    });
    setTimeout(() => {
      closeAccordion()
    }, 1000);
  };

  const showAccordion = () => {
    document.querySelector('.loadDetailWrapper').classList.add('activePrint');
    setToShowAccordion(true)

  }

  const closeAccordion = () => {
    document.querySelector('.loadDetailWrapper').classList.remove('activePrint');
    setToShowAccordion(false)

  }

  useEffect(() => {
    const target = message => {
      closeAccordion()
    }
    window.addEventListener("pdfDownloadComplete", target);
  }, [])

  const backToHome = () => {
    history.replace('/dashboard/spot-recieved/awarded', { showTabs: true })
  }

  // detention rate not included when first value false 
  let is_detention_true = loadDetail?.detention_included_transport ? false : false

  const RenderResponseDeadline = ({ date, completed, iconComponent }: any) => {
    if (moment(date).format('x') >= moment().format('x')) {
      if (!loadDetail?.contract_id) {
        setIsDeadlineComplete(false);
      }
    } else {
      setIsDeadlineComplete(true);
    }
    return !isDeadlineComplete ? (
      <div className="details-Content" style={{ flexDirection: 'column' }}>
        <div className="">
          <div className="fw-5 text-center mb-3">
            <span className="titleName">{`${loadDetail?.transaction_type?.title == "Bid" ? 'Bidding' : 'Offer'} Response Deadline`}</span>
            <span className="seperator">/</span>
            <span className="urduLabel">{`${loadDetail?.transaction_type?.title == "ریٹ" ? 'ریٹ' : 'ریٹ'} دینے کا بقیہ وقت `}</span>
          </div>
        </div>
        <div className="" style={{ paddingLeft: 5, marginBottom: 10 }}>
          <CustomText.OpenSansSemiBold>
            <Counter
              iconComponent={iconComponent}
              date={moment(date).toDate()}
              renderComplete={renderComplete}
            />
          </CustomText.OpenSansSemiBold>
        </div>
      </div>
    ) : (
      ""
    );
  };

  // download pdf 
  const clickDownloadPdf = () => {
    showAccordion()
    if (isBrowser) {
      setTimeout(() => {
        generatePdf();
      }, 1000);
    } else {
      if (sendEventToAndroid("loadDetailPDF")) {
        window.Android.loadDetailPDF(
          String(params?.id)
        );
      } else if (sendEventToiOS("loadDetailPDF")) {
        window.webkit.messageHandlers.loadDetailPDF.postMessage(
          JSON.stringify({
            url: params?.id,
          })
        );
      } else {
      }
    }
  }

  let is_negotiate_bid_weight_volume = loadDetail?.recipients?.find((item) => item?.recipient?.id == profile?.id && item?.bid && item?.status !== 4)

  // const cloneALoad = (id: number) => {
  //   dispatch(
  //     LoadAction.getLoadDetail({
  //       load_id: id,
  //       cb: (data) => {
  //         cloneALoadCallBack(data);
  //       },
  //     })
  //   );
  // };

  const cloneALoadCallBack = (loadDetail: any, id) => {
    let new_detail = null
    if (id) {
      let filter = loadDetail?.categories?.filter((item) => item?.id == id)
      new_detail = {
        ...loadDetail,
        categories: filter,
      }
    } else {
      new_detail = {
        ...loadDetail
      }
    }
    dispatch(ProfileAction.postLoadDataRemove());
    dispatch(CounterAtion.resetPostloadCounterData())
    if (!isObjectEmpty(loadDetail)) {
      let data = {};
      if (loadDetail.load_type.title === LOAD_TYPE.bulk) {
        data = convertDataForFclAndBulkLoadDetailRedux(
          new_detail,
          packageType,
          load_type,
          inquiry_type,
          PreferredVehicleType,
          'forward',
          null
        );
      } else {
        data = convertDataForLoadDetailRedux(
          new_detail,
          packageType,
          load_type,
          inquiry_type,
          'forward',
          null
        );
      }
      if (!isObjectEmpty(data)) {
        dispatch(ProfileAction.postLoadData({ ...data }));
        if (loadDetail.inquiry_type.title === "Spot Rate") {
          history.push(SELECT_VEHICLE);
        } else {
          history.push(CONTRACT_DETAILS_URL);
        }
      }
    }
  };

  const uploadFileRecipient = (data) => {
    let final_data = {
      ...data,
      path: data?.file_url,
    }

    let payload = {
      load_id: loadDetail?.id,
      documents: [final_data]
    }
    dispatch(LoadAction.uploadDocRecipient(payload))
    let new_data = [...recipient_uploaded]
    new_data.push(final_data)
    set_recipient_uploaded(new_data)
  }

  const handleChangeFile = (event: any) => {
    const formData = new FormData();
    formData.append("file", event?.target?.files[0]);
    dispatch(ProfileAction.uploadFile({ data: formData, cb: uploadFileRecipient }));

  };

  const [showDetail, setShowDetail] = useState(false)
  const [is_seen_cats, set_is_seen_cats] = useState([])
  const [is_seen, set_is_seen] = useState(false)


  if (loadDetail && showDetail) {
    let allocated_user = loadDetail?.is_allocated ? loadDetail?.allocates[0]?.allocated_by : profile?.id

    let already_gave_freight_ton = loadDetail?.recipients?.find(
      (item) => item?.status !== 0 && item?.recipient?.id == allocated_user
    );

    let already_responded_to_category
    let recipient_status

    if (loadDetail?.categories?.length) {
      loadDetail?.categories?.forEach(item => {
        item?.recipients.forEach(element => {
          if (element?.recipient?.id == profile?.id && element?.status == 4) {
            recipient_status = element
          }
        });
      })
    } else {
      loadDetail?.recipients.forEach(element => {
        if (element?.recipient?.id == profile?.id && element?.status == 4) {
          recipient_status = element
        }
      });
    }




    return (
      <div ref={downloadRef}>
        <TitleLayout progressFlag={true} progress={0} active={toShowAccordion ? false : true} titleOnly titleOnlyText={`Load ID - ${loadDetail?.id}`}>
          {loadDetail?.id &&
            (params?.type == "received" || params?.type === "posted") ? (
            <>
              {
                !loadDetail?.is_viewer ?
                  <div className="download-pdf-button-wrapper">
                    {
                      !toShowAccordion &&
                      <OutLineButton
                        classProp='tinyBtn'
                        dataColor="green"
                        title="Export PDF"
                        is_recipient={true}
                        urdutitle={"پی ڈی ایف برآمد کریں"}
                        icon={
                          <span className="icons-export"></span>
                        }
                        onClick={clickDownloadPdf}
                      />
                    }
                  </div> : null
              }
              <PullToRefresh
                onRefresh={handleRefresh} disabled={isInteractingWithMap}
              >
                <div className="loadDetailWrapper loadDetailRecipientWrapper customLoadDetailWrapper">
                  <div>
                    {/* next step action  */}
                    {
                      !loadDetail?.is_viewer ?
                        <div>
                          {loadDetail?.next_action_step && (
                            <div className="error-container info">
                              <div>
                                <InfoIcon className="error-icon" />
                              </div>
                              <div className="error-text">
                                {loadDetail?.next_action_step}
                              </div>
                            </div>
                          )}

                          {/* response deadline  */}
                          {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                          <RenderResponseDeadline date={loadDetail?.deadline_at} />
                          {/* </FadeInComponent> */}

                          {/* poster detail */}
                          {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                          <Poster
                            loadDetail={loadDetail}
                            expand={expand}
                            setExpand={setExpand}
                            totalContainers={totalContainers}
                            type={params?.type}
                            set_bid_deposit_fee_amount={set_bid_deposit_fee_amount}
                            bid_deposit_fee_amount={bid_deposit_fee_amount}
                            for_bit_amount={true}
                            disableAmount={disableAmount}
                            showMessage={true}
                            is_recipient={true}
                          />
                          {/* </FadeInComponent> */}

                          {loadDetail?.active_vehicles?.length > 0 ? (
                            <div
                              className="activeVehiclesContainer"
                              // onTouchStart={handleMapInteractionStart}
                              // onTouchEnd={handleMapInteractionEnd}
                              onTouchStart={handleTouchStart}
                              onTouchEnd={handleTouchEnd}
                              onMouseDown={handleMapInteractionStart}
                              onMouseUp={handleMapInteractionEnd}
                              style={{
                                touchAction: "pan-y", // Allow vertical scrolling for accordion
                              }}
                            >
                              <ActiveVehicles
                                loadDetail={loadDetail}
                                expandVehicle={activeExpand}
                                setExpandVehicle={setActiveExpand}
                                fromRecipient={true}
                                markersVehicle={vehiclePinPointMarker}
                              />
                            </div>
                          ) :
                            null}


                          {/* Location Details */}
                          {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                          <LocatonDetail
                            loadDetail={loadDetail}
                            type={params?.type}
                            recipient_status={recipient_status}
                            is_recipient={true}
                          />
                          {/* </FadeInComponent> */}

                          {/* Load Tracking Credentials */}
                          {
                            loadDetail?.is_vehicle_tracking == 1 &&
                              loadDetail?.vehicles_drivers_refs_all?.find((item) => item?.recipient_id == profile?.id) ||
                              loadDetail?.vehicles_drivers_refs?.find((item) => item?.recipient_id == profile?.id)
                              ?
                              // <FadeInComponent is_apply={toShowAccordion ? false : true}>
                              <div className="accordion-container">
                                <Accordion className="accordion-background">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <CustomText.OpenSansSemiBold
                                      textStyle={{
                                        fontSize: "0.875rem",
                                        color: Colors.Black,
                                      }}
                                    >
                                      <span className="titleName">Load Tracking Credentials</span>
                                      <span className="seperator">/</span>
                                      <span className="urduLabel">لوڈ ٹریکنگ اسناد</span>
                                    </CustomText.OpenSansSemiBold>
                                  </AccordionSummary>

                                  <div className="accordion-content">

                                    <div className="inputField">
                                      <div className="label" style={{ alignItems: 'unset' }}>
                                        <span className="titleName">URL</span>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">یو آر ایل</span>
                                      </div>
                                      <div className="field">
                                        <TextInput
                                          inputProps={{
                                            step: "1",
                                          }}
                                          onChange={(e) => {
                                            setURL(e.target.value)
                                          }}
                                          value={url}
                                          type="text"
                                          placeholder="Enter URL, eg(www.xyz.com)"
                                          disabled={!loadDetail?.show_recipient_mark_complete_btn}
                                        />
                                      </div>
                                    </div>

                                    <div className="inputField">
                                      <div className="label" style={{ alignItems: 'unset' }}>
                                        <span className="titleName">User Name</span>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">صارف کا نام</span>
                                      </div>
                                      <div className="field">
                                        <TextInput
                                          inputProps={{
                                            step: "1",
                                          }}
                                          onChange={(e) => {
                                            setUserName(e.target.value)
                                          }}
                                          value={username ? username : ""}
                                          type="text"
                                          aria-describedby="standard-weight-helper-text"
                                          placeholder="Enter User Name"
                                          disabled={!loadDetail?.show_recipient_mark_complete_btn}
                                        />
                                      </div>
                                    </div>

                                    <div className="inputField">
                                      <div className="label" style={{ alignItems: 'unset' }}>
                                        <span className="titleName">Password</span>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">پاس ورڈ</span>
                                      </div>
                                      <div className="field">
                                        <TextInput
                                          inputProps={{
                                            step: "1",
                                          }}
                                          onChange={(e) => {
                                            setPassword(e.target.value)
                                          }}
                                          value={password ? password : ""}
                                          type="text"
                                          aria-describedby="standard-weight-helper-text"
                                          placeholder="Enter Password"
                                          disabled={!loadDetail?.show_recipient_mark_complete_btn}
                                        />
                                      </div>
                                    </div>

                                    <OutLineButton
                                      disabled={!loadDetail?.show_recipient_mark_complete_btn}
                                      buttonStyle={{
                                        height: 44,
                                        fontSize: "1rem",
                                      }}
                                      title={loadDetail?.my_tracking_url?.length > 0 ? "Update" : "Submit"}
                                      is_recipient={true}
                                      urdutitle={loadDetail?.my_tracking_url?.length > 0 ? "اپ ڈیٹ کرائیں" : "جمع کرائیں"}
                                      onClick={() => {
                                        if (urlRegex.test(url)) {
                                          handleLoadTrackingCredentials()
                                        } else {
                                          showMessage.current({ message: "Please enter a valid url, eg(www or https)", status: 'error' })
                                        }

                                      }}
                                    />
                                  </div>
                                </Accordion>
                              </div>
                              // </FadeInComponent>
                              : null
                          }

                          {/* Payment Terms */}
                          {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                          <PaymentTerms
                            loadDetail={loadDetail}
                            is_recipient={true}
                          />
                          {/* </FadeInComponent > */}

                          {/* additional detail */}
                          {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                          <AdditionalDetail
                            loadDetail={loadDetail}
                            is_recipient={true}
                          />
                          {/* </FadeInComponent> */}

                          {/* Transaction type */}
                          {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                          <TransactionType
                            loadDetail={loadDetail}
                            is_recipient={true}
                          />
                          {/* </FadeInComponent> */}

                        </div> : null
                    }
                    {/* Categioess for qoute type by vehicle */}
                    {loadDetail?.categories.length ? (
                      <>
                        {loadDetail?.categories?.map((item, index) => {

                          let new_respond = [...responded];
                          let respond_to_load = responded?.find((resp) => resp?.job_load_category_id == item?.id);
                          let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                          let alreadyResponded = item?.recipients?.find((itm) => itm.recipient?.id === allocated_user && itm.status !== 0)
                          let final_category = item;
                          let is_negotiate_bid = item?.recipients.find((item) => item?.recipient?.id == allocated_user && item?.bid && item?.status !== 4)
                          let is_responded_view = (alreadyResponded && !dateIsGreater(loadDetail?.deadline_at)
                            && loadDetail?.transaction_type?.title == "Bid")
                            || (alreadyResponded && loadDetail?.transaction_type?.title == "Offer")
                            || (alreadyResponded && alreadyResponded?.status == 2
                              && loadDetail?.transaction_type?.title == "Bid")
                          let bidData = alreadyResponded?.bid
                          already_responded_to_category = is_responded_view

                          return (
                            // <FadeInComponent is_apply={toShowAccordion ? false : true} >
                            <div className="accordion-container">
                              {
                                (loadDetail?.is_viewer && item?.total_awarded > 0) || (!loadDetail?.is_viewer) ?
                                  <Accordion className="accordion-background" onClick={() => {
                                    let jobLoadRecId = item?.recipients?.find((rec) => rec?.recipient?.id == allocated_user)

                                    if (!jobLoadRecId?.is_seen) {
                                      if (!is_seen_cats?.find((cat) => cat == item?.id)) {
                                        isShowIndicationDot(jobLoadRecId)
                                        let cats = [...is_seen_cats]
                                        cats.push(item?.id)
                                        set_is_seen_cats(cats)
                                      }
                                    }
                                  }}>
                                    <CategoryCargoHeading
                                      loadDetail={loadDetail}
                                      item={item} index={index}
                                      categoryLength={loadDetail?.categories.length}
                                      is_recipient={true}
                                    />
                                    {
                                      !loadDetail?.is_viewer ?
                                        <div>
                                          <div className="accordion-content">
                                            <ThreeColumnRTL loadDetail={loadDetail} item={item} is_recipient={true} />
                                            <TwoColumnRTLVehicle loadDetail={loadDetail} item={item} type={params?.type} is_recipient={true} />
                                          </div>
                                        </div>
                                        : null
                                    }
                                    <div>
                                      {/* not load_viewer portion  */}
                                      {!loadDetail?.is_viewer ? (
                                        <div>
                                          {/* Already respond to category view */}
                                          {is_responded_view ? (
                                            <div>
                                              <div className="accordion-content">
                                                {/* when status is not 4  */}
                                                <div>
                                                  <div>
                                                    <div className="fw-5">
                                                      <span className="titleName">Vehicles Response</span>
                                                      <span className="seperator">/</span>
                                                      <span className="urduLabel">گاڑیوں کا جواب</span>
                                                    </div>
                                                  </div>
                                                  {/* already respond to category different status view  */}
                                                  <div style={{ marginTop: 5 }}>
                                                    <div style={{ marginBottom: 10, }} className="flexRow-class remJustifySpaceBtw" >
                                                      {alreadyResponded?.status !== 2 && (
                                                        <div>
                                                          {loadDetail?.contract_id ? (
                                                            <VehicleCounterContract recipient={alreadyResponded} />
                                                          ) : (
                                                            <div>
                                                              <VehicleCounter recipient={alreadyResponded} is_recipient={true} />
                                                            </div>
                                                          )}
                                                        </div>
                                                      )}
                                                      <RecipientStatus from={'recipient'} status={alreadyResponded?.status} decline_by={alreadyResponded?.declined_by} is_recipient={true} />
                                                    </div>
                                                    {
                                                      alreadyResponded?.status !== 2 && (
                                                        <div>
                                                          {
                                                            loadDetail?.transaction_type?.title == "Bid" && (
                                                              <RecipientPrices
                                                                no_of_vehicles={bidData?.awarded_no_of_vehicles ? bidData.awarded_no_of_vehicles : bidData?.no_of_vehicles || null}
                                                                no_of_freight={null}
                                                                price_per_vehicle={bidData?.bid_price_per_vehicle || null}
                                                                price_per_freight={null}
                                                                loading_price={bidData?.bid_price_loading || null}
                                                                unloading_price={bidData?.bid_price_unloading || null}
                                                                detention_rate={bidData?.bid_detention_rate}
                                                                is_unit_basis={loadDetail?.is_unit_basis}
                                                                is_negotiate_bid={bidData?.bid_negotiated_amount}
                                                                negotiate_bid_detention={bidData?.bid_negotiated_detention_rate}
                                                                price_unit={loadDetail?.price_unit}
                                                                item={item}
                                                                loadDetail={loadDetail}
                                                                detention_tarif_policy={bidData?.detention_tarif_policy}
                                                                bid_detention_additional_charges={bidData?.bid_detention_additional_charges}

                                                              />
                                                            )
                                                          }
                                                        </div>
                                                      )
                                                    }

                                                    {/* negotiated bid section  */}
                                                    <div>
                                                      {
                                                        is_negotiate_bid && loadDetail?.show_recipient_mark_complete_btn
                                                          ?
                                                          <NeotiateBidAcceptReject loadDetail={loadDetail} bid={is_negotiate_bid} /> : null
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* when status is 4 */}

                                                <div>
                                                  {alreadyResponded?.status == 4 && (
                                                    <>
                                                      {
                                                        loadDetail?.is_cross_stuffing_required ?
                                                          <div>
                                                            {Array(awardVehicleNoArray[index] > 0 ? awardVehicleNoArray[index] : 1)
                                                              .fill("")
                                                              .map((item, index) => {
                                                                let new_index = index + 1;
                                                                return (
                                                                  <AwardedVehicles
                                                                    loadId={loadDetail?.id}
                                                                    addRef={addRef}
                                                                    addVehicle={addVehicle}
                                                                    lclDetail={loadDetail}
                                                                    index={new_index}
                                                                    loadDetail={loadDetail}
                                                                    final_cat_id={final_category?.id}
                                                                    final_category={final_category}
                                                                    userRole={params?.type}
                                                                    required_vehicles={alreadyResponded?.bid?.awarded_no_of_vehicles}
                                                                    orignal_recipient_id={loadDetail?.is_allocated ? loadDetail?.allocates[0]?.allocated_by : profile?.id}
                                                                  />
                                                                );
                                                              })}
                                                            <PrimaryButton
                                                              buttonStyle={{
                                                                height: 40,
                                                              }}
                                                              disabled={loadDetail?.show_recipient_mark_complete_btn ? false : true}
                                                              title={"Add More"}
                                                              rightIcon={true}
                                                              onClick={() => {
                                                                let new_data = [...awardVehicleNoArray]
                                                                new_data[index] = new_data[index] + 1
                                                                setAwardVehicleNoArray(new_data)
                                                              }}
                                                            />
                                                          </div> : loadDetail?.is_unit_basis ?
                                                            <div>
                                                              {Array(awardVehicleNoArrayUnit[index] > 0 ? awardVehicleNoArrayUnit[index] : 1)
                                                                .fill("")
                                                                .map((item, index) => {
                                                                  let new_index = index + 1;
                                                                  return (
                                                                    <AwardedVehicles
                                                                      loadId={loadDetail?.id}
                                                                      addRef={addRef}
                                                                      addVehicle={addVehicle}
                                                                      lclDetail={loadDetail}
                                                                      index={new_index}
                                                                      loadDetail={loadDetail}
                                                                      final_category={final_category}
                                                                      userRole={params?.type}
                                                                      required_vehicles={alreadyResponded?.bid?.awarded_no_of_vehicles}
                                                                      orignal_recipient_id={loadDetail?.is_allocated ? loadDetail?.allocates[0]?.allocated_by : profile?.id}
                                                                    />
                                                                  );
                                                                })}
                                                              <PrimaryButton
                                                                buttonStyle={{
                                                                  height: 40,
                                                                }}
                                                                disabled={loadDetail?.show_recipient_mark_complete_btn ? false : true}
                                                                title={"Add More"}
                                                                rightIcon={true}
                                                                onClick={() => {
                                                                  // addVehicleClicked(awardVehicleNo);
                                                                  let new_data = [...awardVehicleNoArrayUnit]
                                                                  new_data[index] = new_data[index] + 1
                                                                  setawardVehicleNoArrayUnit(new_data)
                                                                }}
                                                              />
                                                            </div> :
                                                            <>
                                                              {Array(
                                                                Number(
                                                                  alreadyResponded?.bid
                                                                    ?.awarded_no_of_vehicles
                                                                )
                                                              )
                                                                .fill("")
                                                                .map((item, index) => {
                                                                  let new_index = index + 1;
                                                                  return (
                                                                    <AwardedVehicles
                                                                      loadId={loadDetail?.id}
                                                                      addRef={addRef}
                                                                      addVehicle={addVehicle}
                                                                      final_category={
                                                                        final_category
                                                                      }
                                                                      index={new_index}
                                                                      loadDetail={loadDetail}
                                                                      orignal_recipient_id={loadDetail?.is_allocated ? loadDetail?.allocates[0]?.allocated_by : profile?.id}
                                                                      required_vehicles={alreadyResponded?.bid?.awarded_no_of_vehicles}
                                                                    />
                                                                  );
                                                                })}
                                                            </>
                                                      }
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            // Not respond to a catetory view 
                                            <div>
                                              <div className="accordion-content">
                                                {
                                                  respond_to_load?.job_load_category_id ? (
                                                    <div>
                                                      {loadDetail?.transaction_type?.title != "Offer" && dateIsGreater(loadDetail?.deadline_at) && (
                                                        <div>
                                                          <div>
                                                            {/* is cross sttuffing message  */}
                                                            <div>
                                                              {loadDetail?.is_cross_stuffing_required && loadDetail?.transaction_type?.title == "Bid" ? (
                                                                <div className="error-container info" style={{ marginBottom: '1rem' }}>
                                                                  <div>
                                                                    <InfoIcon className="error-icon" />
                                                                  </div>
                                                                  <div className="error-text BidPriceTextWrap">
                                                                    <span>Bid price should be inclusive of local transportation + shifting charges + upcountry transportation. Part load can not be accepted.</span>
                                                                    <span className="seperator">/</span>
                                                                    <span className="urduLabel">ریٹ کی قیمت مقامی ٹرانسپورٹیشن+ شفٹنگ چارجز + اپ کنٹری ٹرانسپورٹیشن سمیت ہونی چاہئے۔ پارٹ لوڈ قبول نہیں کیا جا سکتا</span>
                                                                  </div>
                                                                </div>
                                                              ) : null}
                                                            </div>

                                                            {/* offer per price  */}
                                                            <div className="inputField" >
                                                              <div className="label divBlockComplete">
                                                                <div className="divBlock">
                                                                  <span className="titleName">Price / {loadDetail?.is_unit_basis ? 'Item' : 'Vehicle'} (Without GST)</span>
                                                                  <span className="seperator">/</span>
                                                                </div>
                                                                <div className="divBlock">
                                                                  <span className="urduLabel">ریٹ / فی {loadDetail?.is_unit_basis ? 'آئٹم' : 'گاڑی'} (GST کے بغیر)</span>
                                                                  <InfoIcon
                                                                    onClick={() => {
                                                                      setInfo("Price is inclusive of Free Time.");
                                                                      toggleModal(true);
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="field">
                                                                <NewInput
                                                                  onChange={(e) => {
                                                                    if (e.target.value <= Number(item?.max_bid)
                                                                      || Number(item?.max_bid) == 0 || Number(item?.max_bid) == null
                                                                    ) {
                                                                      new_respond[respond_index].bid_price_per_vehicle = e.target.value;
                                                                      setResponded(new_respond)
                                                                    } else {
                                                                      showMessage.current({
                                                                        message:
                                                                          "Price can not be greater than max bid amount.",
                                                                        status: 'error'
                                                                      })
                                                                    }
                                                                  }
                                                                  }
                                                                  value={respond_to_load?.bid_price_per_vehicle ? respond_to_load?.bid_price_per_vehicle : ''}
                                                                  showEnd={"PKR"}
                                                                  decimal={loadDetail?.is_unit_basis ? true : false}

                                                                />
                                                              </div>
                                                            </div>

                                                            {/* detention price  */}
                                                            <div className="inputField" >
                                                              <div className="label divBlockComplete">
                                                                <span className="titleName">Detention Rate / {loadDetail?.is_unit_basis ? 'Item' : 'Vehicle'} (Without GST)</span>
                                                                <span className="seperator">/</span>
                                                                <div className="divBlock">
                                                                  <span className="urduLabel">ڈیٹنشن ریٹ / {loadDetail?.is_unit_basis ? 'آئٹم' : 'فی گاڑی'} (GST کے بغیر)</span>
                                                                </div>
                                                                <InfoIcon
                                                                  onClick={() => {
                                                                    setInfo("Please enter daily Detention Rates only for applicable vehicle categories.");
                                                                    toggleModal(true);
                                                                  }}
                                                                />
                                                              </div>
                                                              <div className="field">
                                                                <NewInput
                                                                  onChange={(e) => {
                                                                    if (respond_to_load?.bid_detention_rate == ".00") {
                                                                      showMessage.current({
                                                                        message: "Please enter a valid value",
                                                                        status: "error"
                                                                      })
                                                                      new_respond[respond_index].bid_detention_rate = ""
                                                                      setResponded(new_respond)
                                                                    } else {
                                                                      new_respond[respond_index].bid_detention_rate = e.target.value;
                                                                      setResponded(new_respond)

                                                                    }
                                                                    // new_respond[respond_index].bid_detention_rate = e.target.value;
                                                                    // setResponded(new_respond)
                                                                  }
                                                                  }
                                                                  value={respond_to_load?.bid_detention_rate ? respond_to_load?.bid_detention_rate : ""}
                                                                  showEnd={"PKR"}
                                                                  placeholder={"Optional"}
                                                                  decimal={loadDetail?.is_unit_basis ? true : false}

                                                                />
                                                              </div>
                                                            </div>

                                                            {/* tariff policy  */}
                                                            {loadDetail?.is_unit_basis ?
                                                              <div className="inputField" >
                                                                <div className="label divBlockComplete">
                                                                  <span className="titleName">Detention Tariff & Policy</span>
                                                                  <span className="seperator">/</span>
                                                                  <div className="divBlock">
                                                                    <span className="urduLabel">ڈیٹنشن ٹیرف اور پالیسی</span>

                                                                  </div>

                                                                </div>
                                                                {/* <div className="field">
                                                                <SimpleInput
                                                                  onChange={(e) => {
                                                                    new_respond[respond_index].detention_tarif_policy = e.target.value;
                                                                    setResponded(new_respond)
                                                                    
                                                                  }
                                                                  }
                                                                  value={respond_to_load?.detention_tarif_policy ? respond_to_load?.detention_tarif_policy : ""}
                                                                  id="detention_tarif_policy"
                                                                  name="detention_tarif_policy"
                                                                  type="text"
                                                                  placeholder={"Optional"}
                                                                  maxLength={500}
                                                                />

                                                              </div> */}
                                                                {

                                                                }
                                                                <div className="textareaWrapper">
                                                                  <textarea style={{ outline: "none", }}
                                                                    // value={respond_to_load?.detention_tarif_policy ? respond_to_load?.detention_tarif_policy : ""}
                                                                    // onChange={(e) => {
                                                                    //   new_respond[respond_index].detention_tarif_policy = e.target.value;
                                                                    //   setResponded(new_respond);
                                                                    // }}
                                                                    value={respond_to_load?.detention_tarif_policy}
                                                                    onChange={(e) => {
                                                                      let new_respond = [...responded];

                                                                      let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                      new_respond[respond_index].detention_tarif_policy = e.target.value;
                                                                      setResponded(new_respond);
                                                                    }}
                                                                    placeholder="Optional"
                                                                  />
                                                                </div>
                                                              </div>
                                                              : null}

                                                            {/* for twin load */}
                                                            {item?.is_twin_load && loadDetail?.load_type?.title == LOAD_TYPE.fcl ?
                                                              <>
                                                                <div className="inputField" >
                                                                  <div className="label divBlockComplete">
                                                                    <div className="divBlock">
                                                                      <span className="titleName">Price / Container (Without GST)</span>
                                                                      <span className="seperator">/</span>
                                                                    </div>
                                                                    <div className="divBlock">
                                                                      <span className="urduLabel">ریٹ / فی کنٹینر (GST کے بغیر)</span>
                                                                    </div>
                                                                  </div>
                                                                  <div className="field priceFieldDisabled customDisableClass">
                                                                    <NewInput
                                                                      value={numeral(Number(respond_to_load?.bid_price_per_vehicle) / 2).format("0,0")}
                                                                      showEnd={"PKR"}
                                                                      disabled={true}
                                                                      aria-describedby="standard-weight-helper-text"

                                                                    />
                                                                  </div>
                                                                </div>

                                                              </> : null}

                                                            {/* other additional charges containerized */}
                                                            <div className="fw-5 iconLabel mb-3 divBlockComplete">
                                                              <span className="titleName">Other Additional Charges (if applicable){" "}</span>
                                                              <span className="seperator">/</span>
                                                              <div className="divBlock">
                                                                <span className="urduLabel"> اضافی چارجز ( اگر لاگو ہوں )</span>
                                                              </div>
                                                              <InfoIcon
                                                                onClick={() => {
                                                                  setInfo("Please enter other charges that are applicable to the request. Where category specific, please identify which categories they apply to.");
                                                                  toggleModal(true);
                                                                }}
                                                              />
                                                            </div>
                                                            <div className="textareaWrapper">
                                                              <textarea style={{ outline: "none", }}
                                                                maxLength={250}
                                                                disabled={respond_to_load?.is_bid && index !== currentIndex}
                                                                value={respond_to_load?.bid_detention_additional_charges}
                                                                onChange={(e) => {
                                                                  let new_respond = [...responded];
                                                                  let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                  new_respond[respond_index].bid_detention_additional_charges = e.target.value;
                                                                  setResponded(new_respond);
                                                                }}
                                                                id={"cargo_description"}
                                                                name={"cargo_description"}
                                                                placeholder="Optional"
                                                              />
                                                            </div>
                                                          </div>

                                                          {
                                                            // (loadDetail?.load_type?.title == LOAD_TYPE.bulk || loadDetail?.load_type?.title == LOAD_TYPE.bulk) && 
                                                            item?.is_loading_required ? (
                                                              <div className="inputField" >
                                                                <div className="label divBlockComplete">
                                                                  <span className="titleName">Loading Price / {loadDetail?.is_unit_basis ? 'Item' : 'Vehicle'} (Without GST)</span>
                                                                  <span className="seperator">/</span>
                                                                  <div className="divBlock">
                                                                    <span className="urduLabel">لوڈنگ ریٹ / فی {loadDetail?.is_unit_basis ? ' آئٹم' : 'گاڑی'} (GST کے بغیر)</span>
                                                                  </div>
                                                                </div>
                                                                <div className="field" >
                                                                  <NewInput
                                                                    onChange={(e) => {
                                                                      new_respond[respond_index].bid_price_loading = e.target.value;
                                                                      setResponded(new_respond)
                                                                    }}
                                                                    value={respond_to_load?.bid_price_loading ? respond_to_load?.bid_price_loading : ''}
                                                                    showEnd={"PKR"}
                                                                    disabled={respond_to_load?.is_bid && index !== currentIndex}
                                                                    decimal={loadDetail?.is_unit_basis ? true : false}
                                                                  />
                                                                </div>
                                                              </div>
                                                            ) : null}

                                                          {
                                                            // (loadDetail?.load_type?.title == LOAD_TYPE.bulk || loadDetail?.load_type?.title == LOAD_TYPE.bulk) && 
                                                            item?.is_unloading_required ? (
                                                              <div className="inputField" >
                                                                <div className="label divBlockComplete">
                                                                  <span className="titleName">UnLoading Price / {loadDetail?.is_unit_basis ? 'Item' : 'Vehicle'} (Without GST)</span>
                                                                  <span className="seperator">/</span>
                                                                  <div className="divBlock">
                                                                    <span className="urduLabel">ان لوڈنگ ریٹ / فی{loadDetail?.is_unit_basis ? 'آئٹم' : 'گاڑی'} (GST کے بغیر)</span>
                                                                  </div>
                                                                </div>
                                                                <div className="field">
                                                                  <NewInput
                                                                    onChange={(e) => {
                                                                      new_respond[respond_index].bid_price_unloading = e.target.value;
                                                                      setResponded(new_respond)
                                                                    }}
                                                                    value={respond_to_load?.bid_price_unloading ? respond_to_load?.bid_price_unloading : ''}
                                                                    showEnd={"PKR"}
                                                                    disabled={respond_to_load?.is_bid && index !== currentIndex}
                                                                    decimal={loadDetail?.is_unit_basis ? true : false}
                                                                  />
                                                                </div>
                                                              </div>
                                                            ) : null}
                                                        </div>
                                                      )}
                                                      {loadDetail?.is_cross_stuffing_required && loadDetail?.transaction_type?.title == "Offer" ? (
                                                        <div className="error-container info">
                                                          <div>
                                                            <InfoIcon className="error-icon" />
                                                          </div>
                                                          <div className="error-text BidPriceTextWrap">
                                                            <span>Offer price should be inclusive of local transportation + shifting charges + upcountry transportation. Part load can not be accepted.</span>
                                                            <span className="seperator">/</span>
                                                            <span className="urduLabel">ریٹ کی قیمت مقامی ٹرانسپورٹیشن+ شفٹنگ چارجز + اپ کنٹری ٹرانسپورٹیشن سمیت ہونی چاہئے۔ پارٹ لوڈ قبول نہیں کیا جا سکتا</span>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      <div className="flexRow-class">

                                                        <div
                                                          style={{
                                                            width: (loadDetail?.is_unit_basis == 0 &&
                                                              (loadDetail?.is_allow_partial_load == 0 ||
                                                                Number(item?.vehicle_quantity) <= 1 ||
                                                                loadDetail?.is_container_deposit_by_transporter == 1 ||
                                                                loadDetail?.is_cross_stuffing_required == 1)
                                                            )
                                                              || (loadDetail?.is_unit_basis == 1 &&
                                                                (loadDetail?.is_allow_partial_load == 0 ||
                                                                  Number(item?.unit_quantity) <= 1))
                                                              || (loadDetail?.is_unit_basis == 1 &&
                                                                loadDetail?.is_allow_partial_load == 1 &&
                                                                Number(item?.unit_quantity) <= 1)
                                                              || (!loadDetail?.is_unit_basis &&
                                                                loadDetail?.is_allow_partial_load == 1 &&
                                                                Number(item?.vehicle_quantity) <= 1
                                                              ) ? '' : '100%'
                                                          }}
                                                        >
                                                          {
                                                            <div className="w-100">
                                                              {
                                                                (loadDetail?.is_unit_basis == 0 &&
                                                                  (loadDetail?.is_allow_partial_load == 0 ||
                                                                    Number(item?.vehicle_quantity) <= 1 ||
                                                                    loadDetail?.is_container_deposit_by_transporter == 1 ||
                                                                    loadDetail?.is_cross_stuffing_required == 1)
                                                                )
                                                                  || (loadDetail?.is_unit_basis == 1 &&
                                                                    (loadDetail?.is_allow_partial_load == 0 ||
                                                                      Number(item?.unit_quantity) <= 1))
                                                                  || (loadDetail?.is_unit_basis == 1 &&
                                                                    loadDetail?.is_allow_partial_load == 1 &&
                                                                    Number(item?.unit_quantity) <= 1)
                                                                  || (!loadDetail?.is_unit_basis &&
                                                                    loadDetail?.is_allow_partial_load == 1 &&
                                                                    Number(item?.vehicle_quantity) <= 1
                                                                  )
                                                                  ?
                                                                  <div style={{
                                                                    display: 'flex',
                                                                    alignItems: "center"
                                                                  }}>
                                                                    <div >
                                                                      <CustomText.OpenSansSemiBold>
                                                                        {loadDetail?.is_unit_basis ? "Item quantity"
                                                                          : "No. of vehicles"}
                                                                      </CustomText.OpenSansSemiBold>
                                                                      <span className="seperator">/</span>
                                                                      <span className="urduLabel">
                                                                        {loadDetail?.is_unit_basis ? "آئٹم کی مقدار" : "گاڑیوں کی تعداد"}
                                                                      </span>
                                                                    </div>
                                                                    <div style={{
                                                                      marginLeft: 20
                                                                    }}>
                                                                      {respond_to_load?.no_of_vehicles}
                                                                    </div>
                                                                  </div>
                                                                  : <VehicleIncDec
                                                                    className="labelWithIncrementField"
                                                                    value={
                                                                      respond_to_load?.no_of_vehicles
                                                                    }
                                                                    updateValue={(val) => {

                                                                      if ((!loadDetail?.is_unit_basis && val <= Number(item?.vehicle_quantity) || val <= Number(item?.unit_quantity))) {
                                                                        let new_respond = [...responded];
                                                                        let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                        new_respond[respond_index].no_of_vehicles = val;
                                                                        setResponded(new_respond);
                                                                      } else {
                                                                        if (!loadDetail?.is_unit_basis) {
                                                                          showMessage.current({
                                                                            message:
                                                                              // "You can not respond more vehicle than no of vehicles offered.", 
                                                                              "You can not select more vehicle than the no. of vehicles offered.",
                                                                            status: 'error'
                                                                          })
                                                                        } else {
                                                                          showMessage.current({
                                                                            message:
                                                                              // "You can not respond more item quantity than item quantity offered.", 
                                                                              "You can not select more items than the items offered.",
                                                                            status: 'error'
                                                                          })
                                                                        }
                                                                      }

                                                                    }}
                                                                    withInputBox={true}
                                                                    onMouseDown={(data) => {
                                                                      if (data == "inc") {
                                                                        let validate = responded.find((itm) => itm?.job_load_category_id === item?.id).no_of_vehicles
                                                                        if ((!loadDetail?.is_unit_basis && validate < Number(item?.vehicle_quantity) || validate < Number(item?.unit_quantity))) {
                                                                          let new_respond = [...responded];
                                                                          let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                          new_respond[respond_index].no_of_vehicles += 1;
                                                                          setResponded(new_respond);
                                                                        } else {
                                                                          if (!loadDetail?.is_unit_basis) {
                                                                            showMessage.current({
                                                                              message:
                                                                                // "You can not respond more vehicle than no of vehicles offered.", 
                                                                                "You can not select more vehicle than the no. of vehicles offered.",
                                                                              status: 'error'
                                                                            })
                                                                          } else {
                                                                            showMessage.current({
                                                                              message:
                                                                                // "You can not respond more item quantity than item quantity offered.", 
                                                                                "You can not select more items than the items offered.",
                                                                              status: 'error'
                                                                            })
                                                                          }
                                                                        }
                                                                        clickHoldTimerInc = setInterval(() => {
                                                                          let new_respond = [...responded];
                                                                          let validate2 = new_respond.find((itm) => itm?.job_load_category_id === item?.id).no_of_vehicles
                                                                          if ((!loadDetail?.is_unit_basis && validate2 < Number(item?.vehicle_quantity) || validate2 < Number(item?.unit_quantity))) {
                                                                            let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                            new_respond[respond_index].no_of_vehicles += 1;
                                                                            setResponded(new_respond);
                                                                          } else {
                                                                            if (!loadDetail?.is_unit_basis) {
                                                                              showMessage.current({
                                                                                message:
                                                                                  // "You can not respond more vehicle than no of vehicles offered.", 
                                                                                  "You can not select more vehicle than the no. of vehicles offered.",
                                                                                status: 'error'
                                                                              })
                                                                            } else {
                                                                              showMessage.current({
                                                                                message:
                                                                                  // "You can not respond more item quantity than item quantity offered.", 
                                                                                  "You can not select more items than the items offered.",
                                                                                status: 'error'
                                                                              })
                                                                            }
                                                                          }
                                                                        }, 200);
                                                                      } else {
                                                                        let validate = responded.find((itm) => itm?.job_load_category_id === item?.id).no_of_vehicles
                                                                        if (validate > 1) {
                                                                          let new_respond = [...responded];
                                                                          let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                          new_respond[respond_index].no_of_vehicles -= 1;
                                                                          setResponded(new_respond);
                                                                        } else {
                                                                          if (!loadDetail?.is_unit_basis) {
                                                                            showMessage.current({
                                                                              message: "You can not respond less than one vehicle.", status: 'error'
                                                                            })
                                                                          } else {
                                                                            showMessage.current({
                                                                              message:
                                                                                //  "You can not respond less than one item quantity.", 
                                                                                "You cannot select less than one",
                                                                              status: 'error'
                                                                            })
                                                                          }
                                                                        }
                                                                        clickHoldTimerDec = setInterval(() => {
                                                                          let new_respond = [...responded];
                                                                          let validate2 = new_respond.find((itm) => itm?.job_load_category_id === item?.id).no_of_vehicles
                                                                          if (validate2 > 1) {
                                                                            let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                            new_respond[respond_index].no_of_vehicles -= 1;
                                                                            setResponded(new_respond);
                                                                          } else {
                                                                            if (!loadDetail?.is_unit_basis) {
                                                                              showMessage.current({
                                                                                message: "You can not respond less than one vehicle.", status: 'error'
                                                                              })
                                                                            } else {
                                                                              showMessage.current({
                                                                                message:
                                                                                  // "You can not respond less than one item quantity.", 
                                                                                  "You cannot select less than one",
                                                                                status: 'error'
                                                                              })
                                                                            }
                                                                          }
                                                                          setResponded(new_respond);
                                                                        }, 200);
                                                                      }
                                                                    }}
                                                                    onMouseUp={() => {
                                                                      clearInterval(clickHoldTimerInc);
                                                                      clearInterval(clickHoldTimerDec)
                                                                    }}
                                                                    onTouchStart={(data) => {
                                                                      if (data == "inc") {
                                                                        clickHoldTimerInc = setInterval(() => {
                                                                          let new_respond = [...responded];
                                                                          let validate2 = new_respond.find((itm) => itm?.job_load_category_id === item?.id).no_of_vehicles
                                                                          if ((!loadDetail?.is_unit_basis && validate2 < Number(item?.vehicle_quantity) || validate2 < Number(item?.unit_quantity))) {
                                                                            let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                            new_respond[respond_index].no_of_vehicles += 1;
                                                                            setResponded(new_respond);
                                                                          } else {
                                                                            if (!loadDetail?.is_unit_basis) {
                                                                              showMessage.current({
                                                                                message:
                                                                                  // "You can not respond more vehicle than no of vehicles offered.", 
                                                                                  "You can not select more vehicle than the no. of vehicles offered.",
                                                                                status: 'error'
                                                                              })
                                                                            } else {
                                                                              showMessage.current({
                                                                                message:
                                                                                  // "You can not respond more item quantity than item quantity offered.", 
                                                                                  "You can not select more items than the items offered.",
                                                                                status: 'error'
                                                                              })
                                                                            }
                                                                          }
                                                                        }, 200);
                                                                      } else {
                                                                        clickHoldTimerDec = setInterval(() => {
                                                                          let new_respond = [...responded];
                                                                          let validate2 = new_respond.find((itm) => itm?.job_load_category_id === item?.id).no_of_vehicles
                                                                          if (validate2 > 1) {
                                                                            let respond_index = new_respond.findIndex((itm) => itm.job_load_category_id == item.id);
                                                                            new_respond[respond_index].no_of_vehicles -= 1;
                                                                            setResponded(new_respond);
                                                                          } else {
                                                                            if (!loadDetail?.is_unit_basis) {
                                                                              showMessage.current({
                                                                                message: "You can not respond less than one vehicle.", status: 'error'
                                                                              })
                                                                            } else {
                                                                              showMessage.current({
                                                                                message:
                                                                                  // "You can not respond less than one item quantity.", 
                                                                                  "You cannot select less than one",
                                                                                status: 'error'
                                                                              })
                                                                            }
                                                                          }
                                                                          setResponded(new_respond);
                                                                        }, 200);
                                                                      }
                                                                    }}
                                                                    onTouchEnd={() => {
                                                                      clearInterval(clickHoldTimerInc);
                                                                      clearInterval(clickHoldTimerDec)
                                                                    }}
                                                                  />
                                                              }
                                                            </div>
                                                          }
                                                        </div>
                                                        {loadDetail?.transaction_type
                                                          ?.title == "Bid" && (
                                                            <div>
                                                              {loadDetail?.is_unit_basis ?
                                                                item?.is_twin_load == 1 ?
                                                                  <>
                                                                    <CustomText.OpenSansRegular>
                                                                      {`${numeral((Number(respond_to_load?.bid_price_per_vehicle) * Number(respond_to_load?.no_of_vehicles)) +
                                                                        (Number(respond_to_load?.bid_price_loading) * (Number(respond_to_load?.no_of_vehicles) * 2) || 0) + (Number(respond_to_load?.bid_price_unloading) * (Number(respond_to_load?.no_of_vehicles) * 2) || 0)).format('0,0') + " PKR"}`}
                                                                    </CustomText.OpenSansRegular>

                                                                  </> :
                                                                  <>
                                                                    <CustomText.OpenSansRegular>{`${numeral(
                                                                      (Number(respond_to_load?.bid_price_per_vehicle) *
                                                                        Number(respond_to_load?.no_of_vehicles)) +
                                                                      (Number(respond_to_load?.bid_price_loading) * Number(respond_to_load?.no_of_vehicles)) +
                                                                      (Number(respond_to_load?.bid_price_unloading) * Number(respond_to_load?.no_of_vehicles))).format("0,0.00")} PKR`}
                                                                    </CustomText.OpenSansRegular>
                                                                  </>
                                                                :

                                                                item?.is_twin_load == 1 ?
                                                                  <>
                                                                    <CustomText.OpenSansRegular>
                                                                      {`${numeral((Number(respond_to_load?.bid_price_per_vehicle) * Number(respond_to_load?.no_of_vehicles)) +
                                                                        (Number(respond_to_load?.bid_price_loading) * (Number(respond_to_load?.no_of_vehicles) * 2) || 0) + (Number(respond_to_load?.bid_price_unloading) * (Number(respond_to_load?.no_of_vehicles) * 2) || 0)).format('0,0') + " PKR"}`}
                                                                    </CustomText.OpenSansRegular>

                                                                  </> :
                                                                  <>
                                                                    <CustomText.OpenSansRegular>{`${numeral(
                                                                      (Number(respond_to_load?.bid_price_per_vehicle) *
                                                                        Number(respond_to_load?.no_of_vehicles)) +
                                                                      (Number(respond_to_load?.bid_price_loading) * Number(respond_to_load?.no_of_vehicles)) +
                                                                      (Number(respond_to_load?.bid_price_unloading) * Number(respond_to_load?.no_of_vehicles))).format("0,0")} PKR`}
                                                                    </CustomText.OpenSansRegular>
                                                                  </>


                                                              }

                                                            </div>
                                                          )}
                                                        {
                                                          !loadDetail?.contract_id && !respond_to_load?.is_bid ? (
                                                            <div
                                                              style={{
                                                                cursor: "pointer",
                                                              }}
                                                              onClick={() => {
                                                                if (respond_to_load?.is_bid) {
                                                                  setCurrentIndex(index);
                                                                } else {
                                                                  let new_respond = [...responded];
                                                                  let findIndex = responded.findIndex((itm) => itm.job_load_category_id == item?.id);
                                                                  new_respond.splice(findIndex, 1);
                                                                  setResponded(new_respond);
                                                                }
                                                              }}
                                                            >
                                                              {respond_to_load?.is_bid ? (
                                                                <span className="icons-edit clr-success"></span>
                                                              ) : (
                                                                <span className="icons-cross" ></span>
                                                              )}
                                                            </div>
                                                          ) : null
                                                        }
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      {!loadDetail?.contract_id && (
                                                        <div>
                                                          {getFullTime(
                                                            loadDetail?.deadline_at
                                                          ) > getFullTime(new Date()) && (
                                                              <PrimaryButton
                                                                buttonStyle={{
                                                                  height: 44,
                                                                }}
                                                                title="Respond"
                                                                is_recipient={true}
                                                                urdutitle={"جواب دیں"}
                                                                onClick={() => {
                                                                  respond(item);
                                                                }}
                                                              />
                                                            )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          {loadDetail?.is_viewer && final_category?.vehicles_drivers_refs.length ? (
                                            <div style={{ marginLeft: 10, marginRight: 10, }} >
                                              {final_category?.vehicles_drivers_refs.map((item, index) => {
                                                return (
                                                  <AwardedVehiclesViewer loadDetail={loadDetail} loadId={loadDetail?.id} item={item} index={index} />
                                                );
                                              })}
                                            </div>
                                          ) : null}
                                        </div>
                                      )}
                                    </div>
                                  </Accordion> : null
                              }
                            </div>
                            // </FadeInComponent>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {/* For Qoute type by weight and volume */}
                        {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                        <div className="accordion-container">
                          <Accordion className="accordion-background"
                            onClick={() => {
                              let jobLoadRecId = loadDetail?.recipients?.find((rec) => rec?.recipient?.id == allocated_user)
                              if (!jobLoadRecId?.is_seen) {
                                if (!is_seen) {
                                  isShowIndicationDot(jobLoadRecId)
                                  set_is_seen(true)
                                }
                              }
                            }}
                          >
                            <CategoryCargoHeading loadDetail={loadDetail} is_recipient={true} />
                            {
                              !loadDetail?.is_viewer ?
                                <div>
                                  <div className="accordion-content">
                                    <ThreeColumnRTL loadDetail={loadDetail} is_recipient={true} />
                                    <div>
                                    </div>
                                    <TwoColumnRTLWeight type={params?.type} loadDetail={loadDetail} is_recipient={true} />
                                  </div>
                                  <div className="dividing-bordered" />
                                </div> : null
                            }
                            <div>
                              {/* if not viewer  */}
                              {!loadDetail?.is_viewer ? (
                                <div>
                                  {(already_gave_freight_ton &&
                                    loadDetail?.transaction_type?.title ==
                                    "Offer") ||
                                    (already_gave_freight_ton && loadDetail?.transaction_type?.title == "Bid" &&
                                      getFullTime(loadDetail?.deadline_at) < getFullTime(new Date()) || already_gave_freight_ton?.status == 2) ? (
                                    // when acceptedd weight volume
                                    <div className="accordion-content">
                                      <div>
                                        <div>
                                          <CustomText.OpenSansRegular>
                                            <span className="titleName">Response</span>
                                            <span className="seperator">/</span>
                                            <span className="urduLabel">جواب</span>
                                          </CustomText.OpenSansRegular>
                                        </div>

                                        {

                                        }

                                        <div style={{ marginTop: 10 }}>
                                          <div
                                            style={{
                                              marginBottom: 10,
                                            }}
                                            className="flexRow-class"
                                          >
                                            <RecipientStatus
                                              status={
                                                already_gave_freight_ton?.status
                                              }
                                              decline_by={
                                                already_gave_freight_ton?.declined_by
                                              }
                                              from="recipient"
                                              is_recipient={true}
                                            />
                                            <VehicleCounter
                                              recipient={already_gave_freight_ton}
                                              price_unit={loadDetail?.price_unit}
                                              is_recipient={true}
                                            />
                                          </div>
                                          {
                                            already_gave_freight_ton?.status !== 2 && (
                                              <div>
                                                {
                                                  loadDetail?.transaction_type?.title == "Bid" && (
                                                    <RecipientPrices
                                                      no_of_vehicles={null}
                                                      no_of_freight={already_gave_freight_ton?.bid?.awarded_freight_ton || already_gave_freight_ton?.bid?.bid_freight_ton}
                                                      price_per_vehicle={null}
                                                      price_per_freight={already_gave_freight_ton?.bid?.price_per_freight}
                                                      loading_price={already_gave_freight_ton?.bid?.loading_price_per_freight}
                                                      unloading_price={already_gave_freight_ton?.bid?.unloading_price_per_freight}
                                                      detention_rate={already_gave_freight_ton?.bid?.bid_detention_rate}
                                                      is_unit_basis={loadDetail?.is_unit_basis}
                                                      is_negotiate_bid={already_gave_freight_ton?.bid?.bid_negotiated_amount}
                                                      price_unit={loadDetail?.price_unit}
                                                      detention_tarif_policy={already_gave_freight_ton?.bid?.detention_tarif_policy}
                                                      bid_detention_additional_charges={already_gave_freight_ton?.bid?.bid_detention_additional_charges}

                                                    />
                                                  )
                                                }
                                              </div>
                                            )

                                          }
                                        </div>
                                        {
                                          is_negotiate_bid_weight_volume && loadDetail?.show_recipient_mark_complete_btn ?
                                            <NeotiateBidAcceptReject bid={is_negotiate_bid_weight_volume} loadDetail={loadDetail} /> : null
                                        }
                                      </div>
                                      {already_gave_freight_ton?.status == 4 && (
                                        <div>
                                          {Array(awardVehicleNo)
                                            .fill("")
                                            .map((item, index) => {
                                              let new_index = index + 1;
                                              return (
                                                <AwardedVehicles
                                                  loadId={loadDetail?.id}
                                                  addRef={addRef}
                                                  addVehicle={addVehicle}
                                                  lclDetail={loadDetail}
                                                  index={new_index}
                                                  loadDetail={loadDetail}
                                                  orignal_recipient_id={loadDetail?.is_allocated ? loadDetail?.allocates[0]?.allocated_by : profile?.id}
                                                />
                                              );
                                            })}
                                          <PrimaryButton
                                            buttonStyle={{ height: 44 }}
                                            title={"Add More"}
                                            disabled={loadDetail?.show_recipient_mark_complete_btn ? false : true}
                                            rightIcon={true}
                                            onClick={() => {
                                              addVehicleClicked(awardVehicleNo);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {is_bid_freight || respondToLcl || showLclFields ? (
                                        <div className="accordion-content">
                                          <Grid style={{ display: "flex", alignItems: "center", paddingBottom: 16, }}>
                                            {loadDetail?.is_allow_partial_load ?
                                              <Grid item xs={11}>
                                                <div className="iconLabel fw-5" style={{ alignItems: 'unset' }}>
                                                  Enter {loadDetail?.price_unit}
                                                  <span className="seperator">/</span>
                                                  <span className="urduLabel">{price_units_transalation[loadDetail?.price_unit]} درج کریں </span>
                                                  {
                                                    loadDetail?.gross_weight && loadDetail?.cargo_volume && loadDetail?.price_unit !== "F.Ton" ?
                                                      <InfoIcon
                                                        onClick={() => {
                                                          setInfo(`Poster has selected pricing item ${loadDetail?.price_unit} so please quote per ${loadDetail?.price_unit} charges.`);
                                                          toggleModal(true);
                                                        }}
                                                      /> : null
                                                  }
                                                </div>
                                              </Grid> : null}
                                          </Grid>

                                          {loadDetail?.is_cross_stuffing_required ? (
                                            <div className="error-container info">
                                              <div>
                                                <InfoIcon className="error-icon" />
                                              </div>
                                              <div className="error-text">
                                                Bid price will be inclusive of shifting & local & upcountry transport charges.
                                              </div>
                                            </div>
                                          ) : null}

                                          {
                                            loadDetail?.is_allow_partial_load ?
                                              <div className="inputField">
                                                <div className="field">
                                                  <NewInput
                                                    onChange={(e) => {
                                                      if (loadDetail?.price_unit == "CBM") {
                                                        if (e.target.value > Number(loadDetail?.cargo_volume)) {
                                                          showMessage.current({ message: `${loadDetail?.price_unit} should be less than or equal to total ${loadDetail?.price_unit}.`, status: 'error' })
                                                          setFrightTon(Number(loadDetail?.cargo_volume));
                                                        } else {
                                                          setFrightTon(e.target.value);
                                                        }
                                                      } else if (loadDetail?.price_unit == "Liter") {
                                                        if (e.target.value > Number(loadDetail?.cargo_liter)) {
                                                          showMessage.current({ message: `${loadDetail?.price_unit} should be less than or equal to total ${loadDetail?.price_unit}.`, status: 'error' })
                                                          setFrightTon(Number(loadDetail?.cargo_liter));
                                                        } else {
                                                          setFrightTon(e.target.value);
                                                        }
                                                      } else if (loadDetail?.price_unit == "Ton") {
                                                        if (e.target.value > Number(loadDetail?.gross_weight)) {
                                                          showMessage.current({ message: `${loadDetail?.price_unit} should be less than or equal to total ${loadDetail?.price_unit}.`, status: 'error' })
                                                          setFrightTon(Number(loadDetail?.gross_weight));
                                                        } else {
                                                          setFrightTon(e.target.value);
                                                        }
                                                      } else if (loadDetail?.price_unit == "Kg") {
                                                        if (e.target.value > Number(loadDetail?.gross_weight) * 1000) {
                                                          showMessage.current({ message: `${loadDetail?.price_unit} should be less than or equal to total ${loadDetail?.price_unit}.`, status: 'error' })
                                                          setFrightTon(Number(loadDetail?.gross_weight) * 1000);
                                                        } else {
                                                          setFrightTon(e.target.value);
                                                        }
                                                      } else {
                                                        if (e.target.value > Number(loadDetail?.total_freight_ton)) {
                                                          showMessage.current({ message: `${loadDetail?.price_unit} should be less than or equal to total ${loadDetail?.price_unit}.`, status: 'error' })
                                                          setFrightTon(Number(loadDetail?.total_freight_ton))
                                                        } else {
                                                          setFrightTon(e.target.value);
                                                        }
                                                      }
                                                    }}
                                                    disabled={disable_f_ton_field}
                                                    value={frightTon ? frightTon : ""}
                                                    showEnd={loadDetail?.price_unit}
                                                    decimal={true}
                                                  />
                                                </div>
                                              </div> :
                                              <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: 16 }}>
                                                <Grid item xs={6}>
                                                  <div className="transactionFieldLabel">
                                                    {loadDetail?.price_unit}
                                                    <span className="seperator">/</span>
                                                    <span className="urduLabel">{price_units_transalation[loadDetail?.price_unit]}</span>
                                                  </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <CustomText.OpenSansSemiBold>
                                                    {loadDetail?.price_unit == "CBM" ? numeral(loadDetail?.cargo_volume).format("0,0.00") : loadDetail?.price_unit == "Liter" ? numeral(loadDetail?.cargo_liter).format('0,0.00') : loadDetail?.price_unit == "Ton" ? numeral(loadDetail?.gross_weight).format('0,0.00') : loadDetail?.price_unit == "Kg" ? numeral(loadDetail?.gross_weight * 1000).format('0,0.00') : numeral(frightTon).format("0,0.00")}
                                                  </CustomText.OpenSansSemiBold>
                                                </Grid>
                                              </Grid>
                                          }


                                          {loadDetail?.quote_by == "weight_volume" && loadDetail?.transaction_type.title == "Bid" ? (
                                            <div className="inputField" >
                                              <div className="fw-5 iconLabel mb-3 divBlockComplete">
                                                <div className="divBlock" style={{ width: '100%' }}>
                                                  <span className="titleName">Price / {loadDetail?.price_unit} (Without GST)</span>
                                                  <span className="seperator">/</span>
                                                </div>
                                                <div className="divBlock">
                                                  <span className="urduLabel"> ریٹ /  فی  {loadDetail?.price_unit} (GST کے بغیر)</span>
                                                </div>
                                                <InfoIcon
                                                  onClick={() => {
                                                    setInfo(
                                                      "Price is inclusive of Free Time."
                                                    );
                                                    toggleModal(true);
                                                  }}
                                                />
                                              </div>
                                              <div className="field">
                                                <NewInput
                                                  onChange={(e) => {
                                                    if (loadDetail?.transaction_type?.title == "Bid") {
                                                      if (e.target.value <= Number(loadDetail?.max_bid)
                                                        || Number(loadDetail?.max_bid) == 0 || Number(loadDetail?.max_bid) == null
                                                      ) {
                                                        setPricePerTon(e.target.value);
                                                      } else {
                                                        showMessage.current({
                                                          message:
                                                            // "Offer price can not be greater than max bid price.", 
                                                            "Price can not be greater than max bid amount.",
                                                          status: 'error'
                                                        })
                                                      }
                                                    } else {
                                                      setPricePerTon(e.target.value);
                                                    }

                                                  }}
                                                  value={pricePerTon ? pricePerTon : ""}
                                                  showEnd={"PKR"}
                                                  decimal={true}
                                                />
                                              </div>
                                            </div>
                                          ) : null}

                                          {loadDetail?.transaction_type?.title == "Bid" &&
                                            loadDetail?.quote_by == "weight_volume" && loadDetail?.is_loading_required ? (
                                            <div className="inputField" >
                                              <div className="label divBlockComplete">
                                                {/* Loading Price / {loadDetail?.cargo_volume && loadDetail?.gross_weight ? "F.Ton" : "Ton"} */}
                                                <span className="titleName">Loading Price / {loadDetail?.price_unit} (Without GST)</span>
                                                <span className="seperator">/</span>
                                                <div className="divBlock">
                                                  <span className="urduLabel">لوڈنگ ریٹ / فی {loadDetail?.price_unit} (GST کے بغیر)</span>
                                                </div>
                                              </div>
                                              <div className="field" >
                                                <NewInput
                                                  onChange={(e) => {
                                                    setLoadingPricePerTon(e.target.value);
                                                  }}
                                                  value={loadingPricePerTon ? loadingPricePerTon : ""}
                                                  showEnd={"PKR"}
                                                  decimal={true}
                                                />
                                              </div>
                                            </div>
                                          ) : null}

                                          {loadDetail?.transaction_type.title == "Bid" &&
                                            loadDetail?.quote_by == "weight_volume" && loadDetail?.is_unloading_required ? (
                                            <div className="inputField" >
                                              <div className="label divBlockComplete">
                                                {/* Unloading Price / {loadDetail?.cargo_volume && loadDetail?.gross_weight ? "F.Ton" : "Ton"} */}
                                                <span className="titleName">Unloading Price / {loadDetail?.price_unit} (Without GST)</span>
                                                <span className="seperator">/</span>
                                                <div className="divBlock">
                                                  <span className="urduLabel">ان لوڈنگ ریٹ / فی  {loadDetail?.price_unit}  (GST کے بغیر)</span>
                                                </div>
                                              </div>
                                              <div className="field">
                                                <NewInput
                                                  onChange={(e) => {
                                                    setUnLoadingPricePerTon(e.target.value);
                                                  }}
                                                  value={unLoadingPricePerTon ? unLoadingPricePerTon : ""}
                                                  showEnd={"PKR"}
                                                  decimal={true}
                                                />
                                              </div>
                                            </div>
                                          ) : null}

                                          {loadDetail?.transaction_type?.title !==
                                            "Offer" ? (
                                            <>

                                              <div className="inputField" >
                                                <div className="label divBlockComplete">
                                                  <span className="titleName">Detention Rates (Without GST)</span>
                                                  <span className="seperator">/</span>
                                                  <div className="divBlock">
                                                    <span className="urduLabel">ڈیٹنشن ریٹ  (GST کے بغیر)</span>
                                                  </div>
                                                  <InfoIcon
                                                    onClick={() => {
                                                      setInfo("Please enter daily Detention Rates only for applicable vehicle categories.");
                                                      toggleModal(true);
                                                    }}
                                                  />
                                                </div>
                                                <div className="field">
                                                  <NewInput
                                                    onChange={(e) => {
                                                      if (detentionRate == ".0" || detentionRate == ".00") {
                                                        showMessage.current({
                                                          message: "Please enter a valid value",
                                                          status: "error"
                                                        })
                                                        setDetentionRate("")
                                                      } else {
                                                        setDetentionRate(e.target.value)

                                                      }
                                                      // setDetentionRate(e.target.value)
                                                    }}
                                                    value={detentionRate ? detentionRate : ""}
                                                    showEnd={"PKR"}
                                                    placeholder={'Optional'}
                                                    decimal={true}
                                                  />
                                                </div>
                                              </div>



                                              {/* tariff policy  */}

                                              <div className="inputField" >
                                                <div className="label divBlockComplete">
                                                  <span className="titleName">Detention Tariff & Policy</span>
                                                  <span className="seperator">/</span>
                                                  <div className="divBlock">
                                                    <span className="urduLabel">ڈیٹنشن ٹیرف اور پالیسی</span>

                                                  </div>

                                                </div>


                                                <div className="textareaWrapper">
                                                  <textarea style={{ outline: "none", }}
                                                    value={detentionTariffPolicy ? detentionTariffPolicy : ""}
                                                    onChange={(e) => {
                                                      setDetentionTariffPolicy(e.target.value)
                                                    }}
                                                    id={"cargo_description"}
                                                    name={"cargo_description"}
                                                    placeholder="Optional"
                                                  />
                                                </div>
                                              </div>

                                              <div className="fw-5 iconLabel divBlockComplete">
                                                <span className="titleName">Other Additional Charges (if applicable){" "}</span>
                                                <span className="seperator">/</span>
                                                <div className="divBlock">
                                                  <span className="urduLabel">اضافی چارجز ( اگر لاگو ہوں )</span>
                                                </div>
                                                <InfoIcon
                                                  onClick={() => {
                                                    setInfo(
                                                      "Please enter other charges that are applicable to the request. Where category specific, please identify which categories they apply to."
                                                    );
                                                    toggleModal(true);
                                                  }}
                                                />
                                              </div>
                                              <div className="textareaWrapper">
                                                <textarea
                                                  style={{
                                                    outline: "none",
                                                  }}
                                                  maxLength={250}
                                                  onChange={(e) =>
                                                    setAddionalCharges(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={additionalCharges}
                                                  id={"cargo_description"}
                                                  name={"cargo_description"}
                                                  placeholder="Optional"
                                                />
                                              </div>

                                              {
                                                loadDetail?.transaction_type
                                                  ?.title == "Bid" && loadDetail?.quote_by == "weight_volume" &&
                                                <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: 16 }}>
                                                  <Grid item xs={6}>
                                                    <div className="transactionFieldLabel">
                                                      {"Total Price"}
                                                      <span className="urduLabel"></span>
                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={6}>
                                                    <CustomText.OpenSansRegular textStyle={{
                                                      marginLeft: 4

                                                    }}>
                                                      {`${numeral(
                                                        ((frightTon) * Number(pricePerTon)) +
                                                        Number(loadingPricePerTon) * Number(frightTon) +
                                                        Number(unLoadingPricePerTon) * Number(frightTon)).format("0,0.00")} PKR`}
                                                    </CustomText.OpenSansRegular>
                                                  </Grid>
                                                </Grid>
                                              }


                                            </>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <div className="accordion-content">
                                          {!loadDetail?.contract_id && (
                                            <div>
                                              {getFullTime(loadDetail.deadline_at) >
                                                getFullTime(new Date()) && (
                                                  <PrimaryButton buttonStyle={{ height: 44 }}
                                                    title="Respond"
                                                    is_recipient={true}
                                                    urdutitle={"جواب دیں"}
                                                    onClick={() => {
                                                      setShowLCLFiends(true);
                                                    }}
                                                  />
                                                )}
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      {
                                        loadDetail?.contract_id && (
                                          <div>
                                            <div className="accordion-content">
                                              <Grid style={{ display: "flex", alignItems: "center", paddingBottom: 16, }}>
                                                <Grid item xs={11}>
                                                  <div className="iconLabel fw-5" style={{ alignItems: 'unset' }}>
                                                    <span>Enter {loadDetail?.price_unit}</span>
                                                    <span className="seperator">/</span>
                                                    <span className="urduLabel"> {price_units_transalation[loadDetail?.price_unit]} درج کریں </span>
                                                    {
                                                      loadDetail?.gross_weight && loadDetail?.cargo_volume && loadDetail?.price_unit !== "F.Ton" ?
                                                        <InfoIcon
                                                          onClick={() => {
                                                            setInfo(`Poster has selected pricing unit ${loadDetail?.price_unit} so please quote per ${loadDetail?.price_unit} charges.`);
                                                            toggleModal(true);
                                                          }}
                                                        /> : null
                                                    }
                                                  </div>
                                                </Grid>
                                              </Grid>
                                              <div className="inputField">
                                                <div className="field">
                                                  <NewInput
                                                    onChange={(e) => {
                                                      if (e.target.value > Number(loadDetail?.total_freight_ton)) {
                                                        showMessage.current({ message: `${loadDetail?.price_unit} should be less than or equal to total ${loadDetail?.price_unit}.`, status: 'error' })
                                                        setFrightTon(loadDetail?.total_freight_ton);
                                                      } else {
                                                        setFrightTon(e.target.value);
                                                      }
                                                    }}
                                                    disabled={disable_f_ton_field}
                                                    value={frightTon ? frightTon : ""}
                                                    showEnd={loadDetail?.price_unit}
                                                    decimal={true}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      }
                                    </div>
                                  )}
                                </div>
                                // if is viewer
                              ) : (
                                <div>
                                  {loadDetail?.is_viewer &&
                                    loadDetail?.vehicles_drivers_refs.length ? (
                                    <div style={{ marginLeft: 10, marginRight: 10, }} >
                                      {loadDetail?.vehicles_drivers_refs.map(
                                        (item, index) => {
                                          return (
                                            <AwardedVehiclesViewer
                                              loadId={loadDetail?.id}
                                              item={item}
                                              loadDetail={loadDetail}
                                              index={index}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          </Accordion>
                        </div>
                        {/* </FadeInComponent> */}
                      </>
                    )}

                    {
                      !loadDetail?.is_viewer ?
                        <div>
                          {!loadDetail?.contract_id && (
                            <div className="accordion-content">
                              {getFullTime(
                                loadDetail?.deadline_at
                              ) > getFullTime(new Date()) && (
                                  <div>
                                    <>
                                      {profile.is_forward_allowed ? (
                                        <OutlineButton
                                          buttonStyle={{
                                            height: 44,
                                            marginBottom: 20,
                                            marginTop: 20,
                                          }}
                                          title="Forward"
                                          is_recipient={true}
                                          urdutitle={"آگے"}
                                          onClick={() => {
                                            let cats = []
                                            if (loadDetail?.categories.length) {
                                              loadDetail?.categories?.forEach(element => {
                                                cats.push({
                                                  ...element,
                                                  is_checked: true,
                                                })
                                              });
                                            }
                                            let final_data = {
                                              ...loadDetail,
                                              categories: cats
                                            }
                                            history.push(
                                              `/forward-load/${loadDetail?.id}/`,
                                              { loadDetail: final_data }
                                            );
                                          }}
                                          disabled={!loadDetail?.show_recipient_mark_complete_btn}
                                        />
                                      ) : null}
                                    </>
                                  </div>
                                )}
                            </div>
                          )}
                        </div> : null
                    }

                    {/* Documents Uploaded By Recipient */}
                    {
                      !loadDetail?.is_viewer && recipient_uploaded?.length ?
                        // <FadeInComponent is_apply={toShowAccordion ? false : true}>
                        <UploadedDocumentsRecipient loadDetail={loadDetail} data={recipient_uploaded} uploadedBy={"You"} is_poster={false} is_recipient={true} />
                        // </FadeInComponent> 
                        : null

                    }

                    {/* Documents Uploaded By Poster  */}
                    {
                      !loadDetail?.is_viewer ?
                        // <FadeInComponent is_apply={toShowAccordion ? false : true}>
                        <UploadedDocuments loadDetail={loadDetail} uploadedBy={"Poster"} is_recipient={true} />
                        // </FadeInComponent> 
                        : null
                    }
                    {

                    }

                    <div>
                      <LoadViewer
                        showViewer={reciStatus}
                        addViewer={addViewer}
                        loadDetail={loadDetail}
                      />
                    </div>

                    {
                      !loadDetail?.is_viewer && getFullTime(loadDetail?.deadline_at) > getFullTime(new Date()) ?
                        // <FadeInComponent is_apply={toShowAccordion ? false : true}>
                        <div className="accordion-container">
                          <Accordion className="accordion-background">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div className="fw-5">
                                <span className="titleName">Upload Document</span>
                                <span className="seperator">/</span>
                                <span className="urduLabel">دستاویزات</span>
                              </div>
                            </AccordionSummary>
                            <div className="accordion-content upload-doc">
                              <UploadDocByRecipient
                                loadDetail={loadDetail}
                                handleChangeFile={handleChangeFile}
                                recipient_uploaded={recipient_uploaded}
                                set_recipient_uploaded={set_recipient_uploaded}
                                uploadFileRecipient={uploadFileRecipient}
                              />
                            </div>
                          </Accordion>
                        </div>
                        // </FadeInComponent>
                        : null
                    }

                    {!loadDetail?.is_viewer ? (
                      <div>
                        {/* accept and decline button for qoute by vehicle  */}
                        <div>
                          {getFullTime(loadDetail?.deadline_at) >
                            getFullTime(new Date()) && (
                              <>
                                {(loadDetail?.categories.length && loadDetail?.transaction_type?.title == "Offer" && (loadDetail?.categories?.filter(
                                  (item) => !item?.recipient_status
                                ).length > 0) || (loadDetail?.transaction_type?.title == "Bid" && loadDetail?.categories.length))
                                  ? (
                                    // <FadeInComponent>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <PrimaryButton
                                        onClick={() => {
                                          let arr = []
                                          let nums = []
                                          if (loadDetail?.categories?.length > 0) {
                                            loadDetail?.categories?.forEach((element, index) => {
                                              if (element.recipient_status == 2) {
                                                arr.push(
                                                  element?.id
                                                )
                                                nums.push(index + 1)
                                              }
                                            });
                                            setSelectedCategories(arr);
                                            set_category_numbers(nums)
                                            if (arr.length == loadDetail.categories.length) {
                                              setSelectAllCat(true)
                                              set_all_declined_disable(true)
                                            }
                                          }
                                          setShowModal(!showModal);
                                        }}
                                        buttonStyle={{
                                          backgroundColor: Colors.Red,
                                          width: "48%",
                                        }}
                                        title="decline"
                                        is_recipient={true}
                                        urdutitle="مسترد"
                                      />
                                      <PrimaryButton
                                        disabled={
                                          !loadDetail?.contract_id ?
                                            handleDisableVehicle() :
                                            handleDisableSpotContractVehicle()
                                        }
                                        onClick={() => {
                                          if (loadDetail?.contract_id) {
                                            if (postLoadMeta?.success_fee?.is_charge_spot_success_fee) {
                                              setSuccessFeeModal(true);
                                            } else {
                                              acceptContractSpot()
                                            }
                                            // setShowPopUp(true);
                                            setLCLBulk(false);
                                            if (loadDetail?.categories?.length > 0) {
                                              setAggreedPrice(
                                                Number(
                                                  loadDetail?.categories[0]
                                                    ?.total_offer_price
                                                )
                                              );
                                            } else {
                                              setAggreedPrice(
                                                Number(loadDetail?.total_offer_price)
                                              );
                                            }
                                          } else {
                                            if (
                                              loadDetail?.is_container_deposit_by_transporter
                                            ) {
                                              if (
                                                loadDetail?.categories?.length ==
                                                responded.length
                                              ) {
                                                if (postLoadMeta?.success_fee?.is_charge_spot_success_fee) {
                                                  setSuccessFeeModal(true);
                                                } else {
                                                  acceptLoad()
                                                }
                                                // setSuccessFeeModal(true);
                                                setLCLBulk(false);
                                              } else {
                                                showMessage.current({
                                                  message:
                                                    // "You can not partially respond when deposit by transporter is yes.",
                                                    "You can not partially respond when deposit by transporter is selected.",
                                                  status: "error",
                                                });
                                              }
                                            } else {
                                              if (postLoadMeta?.success_fee?.is_charge_spot_success_fee) {
                                                setSuccessFeeModal(true);
                                              } else {
                                                acceptLoad()
                                              }
                                              // setSuccessFeeModal(true);
                                              setLCLBulk(false);
                                            }
                                          }
                                        }}
                                        buttonStyle={{
                                          width: "48%",
                                        }}
                                        title={
                                          loadDetail?.transaction_type?.title ==
                                            "Bid" && loadDetail?.updateBid
                                            ? "Update Bid"
                                            : loadDetail?.transaction_type?.title ==
                                              "Bid" && !loadDetail?.updateBid
                                              ? "Submit Bid"
                                              : "accept"
                                        }
                                        is_recipient={true}
                                        urdutitle={
                                          loadDetail?.transaction_type?.title ==
                                            "Bid" && loadDetail?.updateBid
                                            ? "بولی کو اپ ڈیٹ کریں"
                                            : loadDetail?.transaction_type
                                              ?.title == "Bid" &&
                                              !loadDetail?.updateBid
                                              ? "بولی ریٹ جمع کروائیں"
                                              : "قبول کریں"
                                        }
                                      />
                                    </div>
                                    // </FadeInComponent>
                                  ) : null}
                              </>
                            )}
                        </div>

                        {/* accept and decline button for qoute by weight  */}
                        <div>
                          {getFullTime(loadDetail?.deadline_at) >
                            getFullTime(new Date()) && (
                              <>
                                {!loadDetail.categories.length && (
                                  <>
                                    {
                                      (loadDetail?.recipient_status == 0 && loadDetail?.transaction_type?.title == "Offer") ||
                                        (loadDetail?.recipient_status == 0 || loadDetail?.recipient_status == 1 && loadDetail?.transaction_type?.title == "Bid") ? (
                                        // <FadeInComponent>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <PrimaryButton
                                            onClick={() => {
                                              setShowModal(!showModal);
                                            }}
                                            disabled={loadDetail?.categories?.filter((item) => item?.recipient_status).length}
                                            buttonStyle={{
                                              backgroundColor: Colors.Red,
                                              width: "48%",
                                            }}
                                            title="decline"
                                            is_recipient={true}
                                            urdutitle="مسترد"
                                          />
                                          <PrimaryButton
                                            disabled={
                                              !loadDetail?.contract_id ?
                                                handleSubmitBidButtons() :
                                                handleSubmitSpotContractWeight()
                                            }
                                            onClick={() => {
                                              if (loadDetail?.contract_id) {
                                                if (postLoadMeta?.success_fee?.is_charge_contract_success_fee) {
                                                  setSuccessFeeModal(true);
                                                } else {
                                                  acceptContractSpot()
                                                }
                                                // setShowPopUp(true);
                                                setLCLBulk(true);
                                                if (
                                                  loadDetail?.categories.length > 0
                                                ) {
                                                  setAggreedPrice(
                                                    Number(
                                                      loadDetail?.categories[0]
                                                        ?.recipients[0]?.bid
                                                        ?.bid_price_per_vehicle
                                                    )
                                                  );
                                                } else {
                                                  setAggreedPrice(
                                                    Number(
                                                      loadDetail?.total_offer_price
                                                    )
                                                  );
                                                }
                                              } else {
                                                if (postLoadMeta?.success_fee?.is_charge_spot_success_fee == true) {
                                                  setSuccessFeeModal(true);
                                                } else {
                                                  acceptLclWeight()
                                                }
                                                // setSuccessFeeModal(true);
                                                setLCLBulk(true);
                                              }
                                            }}
                                            buttonStyle={{
                                              width: "48%",
                                            }}
                                            title={
                                              loadDetail?.transaction_type?.title ==
                                                "Bid" && loadDetail?.updateBid
                                                ? "Update Bid"
                                                : loadDetail?.transaction_type
                                                  ?.title == "Bid" &&
                                                  !loadDetail?.updateBid
                                                  ? "Submit Bid"
                                                  : "accept"
                                            }
                                            is_recipient={true}
                                            urdutitle={
                                              loadDetail?.transaction_type?.title ==
                                                "Bid" && loadDetail?.updateBid
                                                ? "بولی کو اپ ڈیٹ کریں"
                                                : loadDetail?.transaction_type
                                                  ?.title == "Bid" &&
                                                  !loadDetail?.updateBid
                                                  ? "بولی ریٹ جمع کروائیں"
                                                  : "قبول کریں"
                                            }
                                          />
                                        </div>
                                        // </FadeInComponent>
                                      ) : null
                                    }

                                  </>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </PullToRefresh >
              <div>
                {!loadDetail?.is_viewer ? (
                  <div>
                    {/* allocate button  */}
                    {profile?.is_allocate_allowed ? (
                      <div>
                        {/* {!loadDetail?.contract_id && ( */}
                        <div>
                          {!loadDetail.allocates.length &&
                            new Date().getTime() <
                            new Date(
                              loadDetail?.deadline_at
                            ).getTime() && (
                              // <FadeInComponent>
                              <PrimaryButton
                                onClick={() => {
                                  dispatch(
                                    LoadAction.setAllocatJob(loadDetail?.id)
                                  );
                                  history.push(
                                    "/select-recipient/allocate",
                                    {
                                      from: "detail",
                                      load_id: loadDetail.id,
                                      loadDetail,
                                    }
                                  );
                                }}
                                buttonStyle={{
                                  // backgroundColor: "#7ac251",
                                  width: "100%",
                                  marginTop: 10
                                }}
                                title="Allocate Load"
                                is_recipient={true}
                                urdutitle="لوڈ مختص کریں"
                                disabled={!loadDetail?.show_recipient_mark_complete_btn}
                              />
                              // </FadeInComponent>
                            )}
                        </div>
                        {/* )} */}
                      </div>
                    ) : null}

                    {/* marked load button  */}
                    {
                      (loadDetail?.show_recipient_mark_complete_btn && loadDetail?.recipient_added_vehicle) ?
                        // <FadeInComponent>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                          <OutlineButton
                            buttonStyle={{
                              height: 44,
                              width: 330,
                              marginTop: 10
                            }}
                            title="Mark Load as Complete"
                            is_recipient={true}
                            urdutitle="لوڈ کو مکمل کے بطور نشان زد کریں"
                            onClick={() => {
                              setShowMarkCompleteModal(true)
                            }}
                          />
                        </div>
                        // </FadeInComponent> 
                        : null
                    }
                  </div>
                ) : null}
              </div>
            </>
          ) : null
          }


          {/* Decline Modal */}
          <CustomModal showModal={showModal} toggleModal={setShowModal}>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
                <span className="declineMsgWrap">
                  <span className="titleName">Are you sure you want to decline load?</span>
                  <span className="seperator">/</span>
                  <span className="urduLabel">کیا آپ واقعی لوڈ کو مسترد کرنا چاہتے ہیں؟</span>
                </span>
              </CustomText.OpenSansSemiBold>
            </div>
            <div>
              {loadDetail?.categories.length ? (
                <div className="flexRow">
                  <Checkbox
                    checked={selectAllCat}
                    disabled={all_declined_disable}
                    onClick={() => {
                      if (!selectAllCat) {
                        let new_ids = [...selectedCategories];
                        let new_nums = [...category_numbers]
                        loadDetail?.categories?.forEach((element, index) => {
                          if (new_ids.indexOf(element?.id) == -1) {
                            new_ids.push(element?.id);
                            new_nums.push(index + 1)
                          }
                        });
                        setSelectedCategories(new_ids);
                        set_category_numbers(new_nums)

                      } else {
                        let new_ids = []
                        let new_nums = []
                        loadDetail?.categories?.forEach((element, index) => {
                          if (element?.recipient_status == 2) {
                            new_ids.push(element?.id)
                            new_nums.push(index + 1)
                          }
                        });
                        setSelectedCategories(new_ids);
                        set_category_numbers(new_nums)
                      }
                      setSelectAllCat(!selectAllCat);
                    }}
                    style={{
                      color: "#7ac251",
                    }}
                  />
                  <CustomText.OpenSansRegular
                    textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
                  >
                    {
                      loadDetail?.is_unit_basis ? "All Item Categories" : "All Vehicle Categories"
                    }
                    <span className="seperator">/</span>
                    <span className="urduLabel">
                      {
                        loadDetail?.is_unit_basis ? "تمام آئٹم  کیٹاگری" : "تمام گاڑیوں کے کیٹاگری"
                      }
                    </span>

                  </CustomText.OpenSansRegular>
                </div>
              ) : null}

              {loadDetail?.categories?.map((item, index) => {
                return (
                  <>
                    {/* {!item?.recipient_status ? */}
                    <div className="flexRow">
                      <Checkbox
                        checked={
                          selectedCategories.indexOf(item?.id) == -1 ? false : true
                        }
                        disabled={item?.recipient_status == 2 ? true : false}
                        onClick={() => {
                          selectCat(item?.id, index + 1);
                        }}
                        style={{
                          color: "#7ac251",
                        }}
                      />
                      <CustomText.OpenSansRegular
                        textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
                      >
                        {`${loadDetail?.is_unit_basis ? "Item Category" : 'Category'} ${index + 1}`} {item?.recipient_status == 2 && " (declined)"}
                        <span className="seperator">/</span>
                        <span className="urduLabel"> {`${loadDetail?.is_unit_basis ? "آئٹم  کیٹاگری " : " کیٹاگری "} ${index + 1}`} </span>

                      </CustomText.OpenSansRegular>
                    </div>
                    {/* : null
                    } */}
                  </>
                );
              })}

              {/* {!loadDetail?.categories.length && (
                <div className="flexRow">
                  <Checkbox
                    checked={cargoDelete ? true : false}
                    onClick={() => {
                      setCargoDelete(!cargoDelete);
                    }}
                    style={{
                      color: "#7ac251",
                    }}
                  />
                  <CustomText.OpenSansRegular
                    textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
                  >
                    Cargo Detail
                  </CustomText.OpenSansRegular>
                </div>
              )} */}
            </div>
            <PrimaryButton
              onClick={() => {
                if (!loadDetail?.categories?.length) {
                  declineByRecipient()
                  setShowModal(false);
                  // if (cargoDelete) {
                  //   setShowModal(false);
                  //   setShowReasonModal(true);
                  // } 
                  // else {
                  //   showMessage.current({
                  //     message: "Please select Checkbox.",
                  //     status: "error",
                  //   });
                  // }
                }
                else {
                  if (selectedCategories.length) {
                    setShowModal(false);
                    setShowReasonModal(true);
                  }
                  else {
                    showMessage.current({
                      message: "Please select category.",
                      status: "error",
                    });
                  }
                }
              }}
              buttonStyle={{
                backgroundColor: Colors.Red,
                marginTop: 10,
                marginBottom: 10,
                height: 40,
              }}
              is_recipient={true}
              title="decline"
              urdutitle="مسترد"
            />

            <OutlineButton
              onClick={() => setShowModal(false)}
              buttonStyle={{
                height: 40,
              }}
              is_recipient={true}
              title="Cancel"
              urdutitle="منسوخ "
            />
          </CustomModal>

          {/* Provide Reason Modal */}
          <CustomModal
            showModal={showReasonModal}
            toggleModal={setShowReasonModal}
          >
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
                Please Provide Reason
              </CustomText.OpenSansSemiBold>
            </div>
            <div>
              {declineReasons?.map((reason, index) => {
                return (
                  <div className="flexRow customRadioCheck furtherRadioCheck">
                    <Radio
                      checked={selectedValue === reason}
                      onChange={handleChange}
                      value={reason}
                      onClick={() => { }}
                      style={{
                        color: "#7ac251",
                      }}
                    />
                    <CustomText.OpenSansRegular
                      textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
                    >
                      {reason}
                    </CustomText.OpenSansRegular>
                  </div>
                );
              })}

              {selectedValue.toLowerCase() === "other" && (
                <div>
                  <input
                    placeholder="Give reason"
                    type="text"
                    className="input-box formControl"
                    value={declineReasonOther}
                    onChange={(e) => {
                      if (e.target.value.length <= 100) {
                        setDeclineReasonOther(e.target.value);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <PrimaryButton
              onClick={() => {
                // if (!loadDetail?.categories?.length) {
                //   // if (cargoDelete) {
                //   //   declineByRecipient();
                //   //   setShowReasonModal(false);
                //   //   setSuccessModal(true);
                //   // } 
                //   // else {
                //   //   showMessage.current({
                //   //     message: "Please select Checkbox.",
                //   //     status: "error",
                //   //   });
                //   // }
                // } else {
                //   if (selectedCategories.length) {
                //     declineByRecipient();
                //     setShowReasonModal(false);
                //     { selectAllCat ? setSuccessModal(true) : null }
                //     // setSuccessModal(true);
                //   } else {
                //     showMessage.current({
                //       message: "Please select category.",
                //       status: "error",
                //     });
                //   }
                // }
                if ((selectedValue.length) && (selectedValue.toLowerCase() !== "other")) {

                  declineByRecipient();
                  setShowReasonModal(false);
                  { selectAllCat ? setSuccessModal(true) : null }
                } else if (selectedValue.toLowerCase() === "other") {
                  if (!declineReasonOther?.length) {
                    showMessage.current({
                      message: "Please provide reason.",
                      status: "error",
                    });
                  } else {
                    declineByRecipient();
                    setShowReasonModal(false);
                    { selectAllCat ? setSuccessModal(true) : null }
                  }
                } else {
                  showMessage.current({
                    message: "Please select reason.",
                    status: "error",
                  });
                }
              }}
              buttonStyle={{
                backgroundColor: Colors.Red,
                marginTop: 10,
                marginBottom: 10,
                height: 40,
              }}
              title="decline"
            />
          </CustomModal>

          {/* Success decline Modal */}

          <CustomModal showModal={successModal} toggleModal={setSuccessModal}>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <CustomText.OpenSansSemiBold
                textStyle={{ fontSize: "1.125rem", textAlign: "center" }}
              >
                Successfully Declined!
              </CustomText.OpenSansSemiBold>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  textAlign: "center",
                  maxWidth: 250,
                  padding: "0 25px",
                }}
              >
                Your Load ID - {params?.id} has been moved to load history. <br />
                <span
                  className="blueLink"
                  onClick={() => {
                    dispatch(ProfileAction.updateHistoryFilters({
                      loadType: "Loads Received",
                      inquiryType: 'spot',
                    }))
                    setTimeout(() => {
                      history.replace(LOAD_HISTORY_URL)
                    }, 500);
                  }
                  }
                >
                  View Load
                </span>
              </CustomText.OpenSansRegular>
            </div>
            <PrimaryButton
              onClick={() => {
                setSuccessModal(false);
                history.replace('/dashboard/spot-recieved/new', { showTabs: true });
              }}
              buttonStyle={{
                marginTop: 10,
                marginBottom: 10,
                height: 40,
              }}
              title="OKAY"
            />
          </CustomModal>

          {/* Success Fee Modal */}
          <CustomModal
            showModal={successFeeModal}
            toggleModal={setSuccessFeeModal}
          >
            <span
              className="icons-cross directionRight"
              onClick={() => setSuccessFeeModal(false)}
            ></span>
            <div
              style={{
                marginTop: 10,
                textAlign: "center",
              }}
            >
              <div className="imgWrapper">
                {/* <img src={Images.Pay} style={{ width: "100%" }} /> */}
                <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
                  Do you agree to pay the Success Fee?
                </CustomText.OpenSansSemiBold>
              </div>

              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  textAlign: "center",
                  padding: "0 25px",
                  margin: "20px 0",
                  maxWidth: 300,
                }}
              >

                {postLoadMeta?.success_fee?.charge_spot_success_fee_note}

                {/* A {`${metaData.success_fee} %`} Success Fee of the transaction
                value will be payable to Meri Gari for any vehicles requested
                under this contract. The fee will be applicable only on requests
                of vehicles under an awarded contract. */}
              </CustomText.OpenSansRegular>
            </div>

            <div className="modalFooterWrapper">
              <OutLineButton
                onClick={() => {
                  setSuccessFeeModal(false);
                }}
                title="NO"
              />
              <PrimaryButton
                onClick={() => {
                  setSuccessFeeModal(false);
                  if (lclBulk) {
                    if (!loadDetail?.contract_id) {
                      acceptLclWeight();
                    } else {
                      acceptContractSpot();
                    }
                  } else {
                    if (!loadDetail?.contract_id) {
                      acceptLoad();
                    } else {
                      acceptContractSpot();
                    }
                  }
                }}
                title="YES, AGREE"
              />
            </div>
          </CustomModal>

          {/* Success Fee Modal */}

          <CustomModal showModal={showPopUp} toggleModal={setShowPopUp}>
            <span
              className="icons-cross directionRight"
              onClick={() => setShowPopUp(false)}
            ></span>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {/* <CustomText.OpenSansSemiBold
                textStyle={{ fontSize: 18, textAlign: "center" }}
              >
                Success Fee Charge
              </CustomText.OpenSansSemiBold> */}
            </div>

            <div>
              <CustomText.OpenSansRegular
                textStyle={{ fontSize: "0.875rem", textAlign: "center" }}
              >

                {postLoadMeta?.success_fee?.charge_spot_success_fee_note}

                {/* This request for vehicles is subject to a{" "}
                {`${metaData.success_fee} %`} success fee charge payable to Meri
                Gari. Meri Gari will invoice you following award of this
                transaction. */}
              </CustomText.OpenSansRegular>
            </div>

            {/* <div className="modalText">
              {[
                renderRTL(
                  "Gross Transaction Value",
                  `${numeral(aggreedPrice).format("0,0")} PKR`
                ),
                renderRTL(
                  "Success Fee Payable to Meri Gari ",
                  `${numeral(
                    (aggreedPrice * Number(metaData.success_fee)) / 100
                  ).format("0,0")} PKR`
                ),
              ]}
            </div> */}

            {/* <div>
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: 12,
                  textAlign: "center",
                  opacity: 0.75,
                }}
              >
                By pressing 'Yes, Continue' below you agree to pay Meri Gari the
                Success Fee.
              </CustomText.OpenSansRegular>
            </div> */}

            <div className="modalFooterWrapper">
              <OutlineButton
                title="No"
                onClick={() => setShowPopUp(false)}
                buttonStyle={{ transform: 'scale(0.9)' }}
              />
              <PrimaryButton
                onClick={() => {
                  acceptContractSpot();
                  setShowPopUp(false);
                }}
                buttonStyle={{ transform: 'scale(0.9)' }}
                title="Yes, Continue"
              />
            </div>
          </CustomModal>

          {/* Respond Modal */}
          <CustomModal showModal={respondModal} toggleModal={setRespondModal}>
            <div
              style={{
                marginTop: 10,
                textAlign: "center",
              }}
            >
              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
                Location 1 Responded!
              </CustomText.OpenSansSemiBold>
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  textAlign: "center",
                  maxWidth: 250,
                  padding: "0 25px",
                  margin: "20px 0",
                }}
              >
                Location 1 response has been successfully submitted! Would you
                like to respond to more categories?
              </CustomText.OpenSansRegular>
            </div>
            <div className="modalFooterWrapper">
              <OutLineButton
                onClick={() => {
                  setRespondModal(false);
                }}
                title="NO"
              />
              <PrimaryButton
                onClick={() => {
                }}
                title="YES, CONTINUE"
              />
            </div>
          </CustomModal>

          <CustomModal
            modalStyle={{
              width: 300,
              minHeight: 100,
            }}
            showModal={showModalNew}
            toggleModal={toggleModal}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InfoIcon color="primary" style={{ fontSize: 40 }} />
              <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
            </div>
          </CustomModal>

          <CustomModal showModal={showMarkCompleteModal} toggleModal={setShowMarkCompleteModal} modalStyle={{ minHeight: "unset" }}>
            <div className="text-center">
              <CustomText.OpenSansBold textStyle={{ marginTop: 20, marginBottom: 20 }}>Complete Load</CustomText.OpenSansBold>
              <CustomText.OpenSansRegular>Are you sure you want to complete a load?</CustomText.OpenSansRegular>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 40
              }}>
                <PrimaryButton
                  buttonStyle={{ height: 40, width: "47%", backgroundColor: Colors.Red }}
                  title="No"
                  // disabled={!loadDetail?.marked_completed_by_me_as_recipient?.categories?.filter(item => item?.mark_complete_status == null).length}
                  onClick={() => {
                    setShowMarkCompleteModal(false)
                  }}
                />
                <PrimaryButton
                  buttonStyle={{ height: 40, width: "47%" }}
                  title="Yes"
                  // disabled={!loadDetail?.marked_completed_by_me_as_recipient?.categories?.filter(item => item?.mark_complete_status == null).length}
                  onClick={() => {
                    setShowMarkCompleteModal(false)
                    dispatch(LoadAction.markAsCompleteByRecipient({
                      load_id: loadDetail?.id,
                      cb: backToHome
                    }))
                  }}
                />
              </div>
            </div>
          </CustomModal>
        </TitleLayout >
      </div >
    );
  } else {
    return <div></div>;
  }
};
export default Page;
