import React, { useEffect, useState } from "react";
import { IActiveLoadPageProps, ParamsProps } from "./types";
import "./style.scss";
import {
	CustomText,
	GoogleMap,
	LocationTimeline,
	OutLineButton,
	PrimaryButton,
	TextInput,
	TitleLayout,
	CustomModal
} from "@components";
import { Colors, Images } from "@temp/assets";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import { RenderRTL } from "../components/RenderRTL";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { LoadAction, ProfileAction } from "@temp/store/actions";
import { ContactCard } from "../components/ContactCard";
import moment from "moment";
import {
	Button, Checkbox,
	// createStyles,
	//  makeStyles, 
	//  Theme, 
} from "@mui/material/";
export type RootState = ReturnType<typeof rootReducer>;
import { isBrowser } from "react-device-detect";
import Accordion from "@mui/material//Accordion/Accordion";
import AccordionSummary from "@mui/material//AccordionSummary/AccordionSummary";
import { UploadDocByRecipient, UploadedDocumentsRecipient } from "../components";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import {
	sendEventToAndroid,
	sendEventToiOS,
} from "../../../utils/mobileEventHandler";
var numeral = require("numeral");

// const useStyles = makeStyles((theme: Theme) =>
// 	createStyles({
// 		root: {
// 			flexGrow: 1,
// 			marginTop: 15,
// 		},
// 		paper: {
// 			backgroundColor: "transparent",
// 			border: "none",
// 		},
// 	})
// );
const Page: React.FC<IActiveLoadPageProps> = (props) => {
	// const classes = useStyles();
	const params: ParamsProps = useParams();

	const dispatch = useDispatch();
	const location = useLocation()
	const history = useHistory()
	const { state } = location

	const [marker, setMarker] = useState(null);
	const [driverMarker, setDriverMarker] = useState(null);
	const [center, setCenter] = useState(
		{ lat: 24.8631724, lng: 67.0251307 }
	);
	const [isFulScreen, setisFulScreen] = useState(false)
	const [lastLocation, setLastLocation] = useState('');
	const [expand, setExpand] = useState(true);
	const [expandChecklist, setExpandChecklist] = useState(true);
	const [expandLocationHistory, setExpandLocationHistory] = useState(true);
	// const [share_on_whatsapp, set_share_on_whatsapp] = useState(null)
	const [showModal, setShowModal] = useState(false)

	const profile = useSelector((state: RootState) => state.Profile.profile);
	const vehicleMarker = useSelector((state: RootState) => state?.Load?.vehicleMarker)

	let firstRender = true;
	let firstRender2 = true;
	let showDriverCenter = false

	useEffect(() => {
		dispatch(
			LoadAction.getActiveLoadDetail({
				load_id: params?.load_id,
				job_load_driver_id: params?.job_load_driver_id,
			})
		);
		dispatch(LoadAction.getLoadDetail({ load_id: params?.load_id }));
	}, []);

	const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
	const LoadDetail = useSelector((state: RootState) => state.Load.activeLoadDetail);
	const myDetail = useSelector((state: RootState) => state.Load.loadDetail);



	const activeLoadDetail =
		LoadDetail?.quote_by === "vehicle" || LoadDetail?.is_unit_basis
			? LoadDetail?.categories?.length > 0
				? LoadDetail?.categories[0]
				: null
			: LoadDetail?.drivers?.length > 0
				? LoadDetail?.drivers[0]
				: null;

	const driverDetail =
		LoadDetail?.quote_by === "vehicle" || LoadDetail?.is_unit_basis
			? activeLoadDetail?.drivers?.length > 0
				? activeLoadDetail?.drivers[0]
				: null
			: LoadDetail?.drivers?.length > 0
				? LoadDetail?.drivers[0]
				: null;

	const [driverChecks, setDriverChecks] = useState(null)

	const slicedData = driverDetail?.locations.slice(0, 5);
	const slicedSixData = driverDetail?.locations.slice(5);



	useEffect(() => {
		if (driverDetail) {
			let data = {
				has_arrived_for_loading: driverDetail?.checklist?.has_arrived_for_loading ? true : false,
				has_cargo_loaded: driverDetail?.checklist?.has_cargo_loaded ? true : false,
				has_arrived_at_destination: driverDetail?.checklist?.has_arrived_at_destination ? true : false,
				has_delievered: driverDetail?.checklist?.has_delievered ? true : false,
				has_empty_returned: driverDetail?.checklist?.has_empty_returned ? true : false,
				has_empty_pickup: driverDetail?.checklist?.has_empty_pickup ? true : false
			}
			setDriverChecks(data)
		}
	}, [driverDetail])


	const handleDriverCheckList = (data) => {

		let final_data = {
			job_load_driver_id: params?.job_load_driver_id,
			load_id: params?.load_id,
			checklist_id: driverDetail?.checklist?.id,
			updated_key: data?.updated_key,
			status: data?.status,
		}

		// return
		dispatch(LoadAction.updateDriverChecklist(final_data))
	}

	const goBack = () => {
		history.goBack()
	}

	const completeJob = () => {
		let data = {
			job_load_driver_id: params?.job_load_driver_id,
			load_id: params?.load_id,
			checklist_id: driverDetail?.checklist?.id,
			has_marked_completed: true,
			cb: goBack
		}
		dispatch(LoadAction.updateDriverChecklist(data))
	}


	let pickup_dropoff_location = []
	let additional_locations = []
	if (myDetail) {
		if (myDetail?.additional_dropoffs.length) {
			pickup_dropoff_location.push(myDetail?.locations[0])
			additional_locations.push(myDetail?.locations[1])
			let tempIndex = null
			myDetail?.additional_dropoffs?.forEach((element, index) => {
				if (element?.lat && element?.long) {
					pickup_dropoff_location[1] = element
					tempIndex = index
				}
			});
			myDetail?.additional_dropoffs?.forEach((element, index) => {
				if (element?.lat && element?.long && index !== tempIndex) {
					additional_locations.push(element)
				}
			});
		} else {
			pickup_dropoff_location.push(myDetail?.locations[0])
			pickup_dropoff_location.push(myDetail?.locations[1])
		}
	}


	let hasLatLong = myDetail?.locations[0]?.lat && myDetail?.locations[0]?.long && myDetail?.locations[1]?.lat && myDetail?.locations[1] ? true : false

	const DeliveryChecklist = ({ label, value, id, disabled, time, urdutitle, userName, updatedBySys }) => {

		return (
			<div>
				<div
					// style={{
					//   pointerEvents: state?.fromPoster || !myDetail?.show_recipient_mark_complete_btn ? 'none' : 'auto'
					// }}
					className="checkList">
					<div className="checkListGrid">
						<div className="row">
							<span className="titleName">{label}</span>
							<span className="urduLabel">{urdutitle}</span>
						</div>
						<div className="row">
							<div className="leftSideDelivery">
								{updatedBySys && (
									<span className="fw-5" style={{ marginBottom: '0.5rem' }}>
										Update by System <br />
										{moment(updatedBySys).format("DD-MM-YYYY, hh:mm A")}
									</span>
								)}
								{userName && (
									<span className="fw-5">
										Updated by  {userName} <br />
										{moment(time).format("DD-MM-YYYY, hh:mm A")}
										<br />
									</span>
								)}

								{/* {time  && (
									<span className="fw-5">
										{moment(time).format("DD-MM-YYYY, hh:mm A")}
										<br />
									</span>
								)} */}



							</div>
							{/* <div className="leftSideDelivery">
							<span className="fw-5">{time ? moment(time).format("DD-MM-YYYY, hh:mm A") : 'N/A'}</span>
									<span className="fw-5">
										{
											userName ? 
											`Updated by  ${userName}` : 
											updatedBySys == null && value == true ?
											`Updated by system` : null
										}

									</span>

							</div> */}


							<div className={`icon ${value ? 'active' : ''}`}>
								<Checkbox
									icon={<RadioButtonUncheckedIcon />}
									checkedIcon={<CheckCircleIcon />}
									checked={value}
									disabled={disabled}
									onChange={(e) => {
										if (id == 1) {
											if (value) {
												let tem_data = {
													...driverChecks,
													has_arrived_for_loading: false,

												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_arrived_for_loading",
													status: false
												})
											} else {
												let tem_data = {
													...driverChecks,
													has_arrived_for_loading: true,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_arrived_for_loading",
													status: true
												})
											}
										} else if (id == 2) {
											if (value) {
												let tem_data = {
													...driverChecks,
													has_cargo_loaded: false,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_cargo_loaded",
													status: false
												})
											} else {
												let tem_data = {
													...driverChecks,
													has_cargo_loaded: true,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_cargo_loaded",
													status: true
												})
											}
										} else if (id == 3) {
											if (value) {
												let tem_data = {
													...driverChecks,
													has_arrived_at_destination: false,

												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_arrived_at_destination",
													status: false
												})
											} else {
												let tem_data = {
													...driverChecks,
													has_arrived_at_destination: true,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_arrived_at_destination",
													status: true
												})
											}
										} else if (id == 4) {
											if (value) {
												let tem_data = {
													...driverChecks,
													has_delievered: false,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_delievered",
													status: false
												})
											} else {
												let tem_data = {
													...driverChecks,
													has_delievered: true,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_delievered",
													status: true
												})
											}
										} else if (id == 5) {
											if (value) {
												let tem_data = {
													...driverChecks,
													has_empty_returned: false,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_empty_returned",
													status: false
												})
											} else {
												let tem_data = {
													...driverChecks,
													has_empty_returned: true,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_empty_returned",
													status: true
												})
											}
										} else if (id == 6) {
											if (value) {
												let tem_data = {
													...driverChecks,
													has_empty_pickup: false,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_empty_pickup",
													status: false
												})
											} else {
												let tem_data = {
													...driverChecks,
													has_empty_pickup: true,
												}
												setDriverChecks(tem_data)
												handleDriverCheckList({
													updated_key: "has_empty_pickup",
													status: true
												})
											}
										}
									}}
								/>
								<label className="">{value ? "Completed" : "Pending"}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};


	const addVehicleLastLocation = () => {
		dispatch(
			LoadAction.addVehicleLastLocation({
				load_id: params?.load_id,
				job_load_driver_id: params?.job_load_driver_id,
				location: lastLocation
			})
		);
		setLastLocation('')
	}

	// useEffect(() => {
	//   const token = getItem("token");
	//   const pusher = new Pusher(process.env.REACT_APP_PUSHER_key, {
	//     cluster: "ap2",
	//     app_id: process.env.REACT_APP_PUSHER_APP_ID,
	//     encrypted: true,
	//     withCredentials: true,
	//     auth: {
	//       headers: {
	//         Authorization: `Bearer ${token}`,
	//         Accept: "application/json",
	//       },
	//     },
	//     authEndpoint: "https://mg-dev-web.herokuapp.com/api/v1/auth/pusher",
	//   });
	//   
	//   const channel = pusher.subscribe("private-chat");
	//   channel.bind("message", (data) => {
	//     
	//   });
	// }, []);

	function getActiveLoadLatLong() {
		dispatch(
			LoadAction.getVehicleMarker({
				load_id: params?.load_id,
				job_load_driver_id: params?.job_load_driver_id,
				cb: getVehicleLatLongCallBack,
			})
		);
	}
	// function getDriverLatLong() {
	//   if (driverDetail) {
	//     dispatch(
	//       LoadAction.getDriverMarker({
	//         job_load_driver_id: driverDetail?.driver.id || "",
	//         cb: getDriverLatLongCallBack,
	//       })
	//     );
	//   }
	// }

	let interval;
	// let driverInterval;

	function getVehicleLatLongCallBack(data) {
		if (data) {
			// if (firstRender) {
			if (data?.lat && data?.lng) {
				setCenter({ ...data });
				setMarker({ ...data });
				firstRender = false;
			}
			// }
		} else {
			clearInterval(interval);
			if (!showDriverCenter) {
				showDriverCenter = true
			}
		}
	}
	function getDriverLatLongCallBack(data) {
		if (data) {
			if (firstRender2) {
				// setCenter({ ...data });
				firstRender2 = false;
			}
			if (showDriverCenter) {
				// setCenter({ ...data });
				showDriverCenter = false
			}
			setDriverMarker({ ...data });
		} else {
			// clearInterval(driverInterval);
		}
	}
	useEffect(() => {
		interval = setInterval(
			getActiveLoadLatLong,
			Number(metaData?.tpl_interval || 60) * 1000
		);
		// getDriverLatLong();
		getActiveLoadLatLong()
		// driverInterval = setInterval(
		//   getDriverLatLong,
		//   Number(metaData?.driver_location_update_interval || 60) * 1000
		// );
		return () => {
			clearInterval(interval);
			// clearInterval(driverInterval);
		};
	}, []);

	const getTimeDifference = (first: any, second: any, format: string) => {
		const result = first.diff(second, format);
		return result.toString();
	};

	const handleDisable = () => {
		if (myDetail?.is_empty_return) {
			return driverChecks?.has_empty_returned ? false : true
		} else {
			return driverChecks?.has_delievered ? false : true
		}
	}

	const uploadFileRecipient = (data) => {
		let final_data = {
			...data,
			job_load_driver_id: params?.job_load_driver_id,
			load_id: params.load_id,
			path: data?.file_url,
		}
		dispatch(LoadAction.uploadFilesRecipient({ payload: final_data }))
	}

	const handleChangeFile = (event: any) => {
		const formData = new FormData();
		formData.append("file", event?.target?.files[0]);
		dispatch(ProfileAction.uploadFile({ data: formData, cb: uploadFileRecipient }));
	};


	const shareOnWhatsapp = () => {
		let text = null
		if (LoadDetail?.load_type == "Non-Containerized") {
			text = `MG Delivery Alert\nLoad Name: ${LoadDetail?.title}\nLoad ID: ${LoadDetail?.id} ${LoadDetail?.ref_no ? `\nClient Ref: ${LoadDetail?.ref_no}` : ""}\nFrom: ${LoadDetail?.pick_location_active?.title}\nTo: ${LoadDetail?.dropoff_location_active?.title}\n\nPlate Number: ${driverDetail?.vehicle?.plate_number}\nNo of Packages: ${driverDetail?.no_of_packages}${driverDetail?.weight_one && driverDetail?.weight_two ? `Weight 1: ${numeral(driverDetail?.weight_one).format("0,0")} Kg \nWeight 2 : ${numeral(driverDetail?.weight_two).format("0,0")} Kg` : driverDetail?.weight_one ? `Weight: ${numeral(driverDetail?.weight_one).format("0,0")} Kg \n` : ""}\nDriver Mobile: 0${driverDetail?.driver?.phone_no}\nCurrent Location: ${marker?.location ? marker?.location : driverDetail?.locations.length > 0 ? driverDetail?.locations[0]?.location : "--"}`
		} else {
			text = `MG Delivery Alert\nLoad Name: ${LoadDetail?.title}\nLoad ID: ${LoadDetail?.id} ${LoadDetail?.ref_no ? `\nClient Ref: ${LoadDetail?.ref_no}` : ""} \nFrom: ${LoadDetail?.pick_location_active?.title}\nTo: ${LoadDetail?.dropoff_location_active?.title}\n\nPlate Number: ${driverDetail?.vehicle?.plate_number}\n${driverDetail?.container_ref_one && driverDetail?.container_ref_two ? `Container Number 1: ${driverDetail?.container_ref_one}\nContainer Number 2: ${driverDetail?.container_ref_two}\n` : driverDetail?.container_ref_one ? `Container Number 1: ${driverDetail?.container_ref_one}\n` : ""} ${driverDetail?.no_of_packages ? `No. of Packages: ${driverDetail?.no_of_packages}\n` : ''}${driverDetail?.weight_one && driverDetail?.weight_two ? `Weight 1: ${numeral(driverDetail?.weight_one).format("0,0")} Kg \nWeight 2 : ${numeral(driverDetail?.weight_two).format("0,0")} Kg` : driverDetail?.weight_one ? `Weight
			: ${numeral(driverDetail?.weight_one).format("0,0")} Kg \n` : ""}\nDriver Mobile: 0${driverDetail?.driver?.phone_no}\nCurrent Location: ${marker?.location ? marker?.location : driverDetail?.locations?.length > 0 ? driverDetail?.locations[0]?.location : "--"}`
		}
		let encoded_data = window.encodeURIComponent(text)

		if (isBrowser) {
			window.open(`https://wa.me/?text=${encoded_data}`)
		} else {
			if (sendEventToAndroid("ShareViaWhatsapp")) {
				window.Android.ShareViaWhatsapp(
					String(encoded_data)
				);
			} else if (sendEventToiOS("ShareViaWhatsapp")) {
				window.webkit.messageHandlers.ShareViaWhatsapp.postMessage(
					JSON.stringify({
						encoded_data: encoded_data,
					})
				);
			}
		}
	}

	if (LoadDetail) {
		return (
			<TitleLayout fixed={true} progress={100} bottomTitle="Active load" titleOnly titleOnlyText={`Active Load`}>
				<>
					<div className="activeLoadDetailWrapper">
						<FadeInComponent>
							<div className="accordion-container">
								<Accordion
									className="accordion-background"
									expanded={expand}
									onChange={() => setExpand(!expand)}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<div className="fw-5">
											Load details
										</div>
									</AccordionSummary>
									<div style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										marginBottom: 15
									}}>
										<OutLineButton
											title="Share via whatsapp"
											// disabled={!myDetail?.show_recipient_mark_complete_btn || myDetail?.status_name == "Completed"}
											onClick={shareOnWhatsapp}
											buttonStyle={{
												width: "90%"
											}}
										/>
									</div>
									<div className="accordion-content">
										<div className="loadDetail">
											{driverDetail?.container_ref_one &&
												LoadDetail?.quote_by === "vehicle" && (
													<RenderRTL
														title={`${driverDetail?.container_ref_one && driverDetail?.container_ref_two ? "Container Number 1 " : "Container Number"}`}
														val={driverDetail?.container_ref_one}
													/>
												)}

											{driverDetail?.weight_one ? (
												<RenderRTL
													// title={"Weight"}
													title={`${driverDetail?.container_ref_one && driverDetail?.container_ref_two ? "Weight 1 " : "Weight"}`}
													val={`${numeral(driverDetail?.weight_one).format("0,0")} Kg`}
												/>
											) : null}

											{driverDetail?.container_ref_two &&
												LoadDetail?.quote_by === "vehicle" && (
													<RenderRTL
														title={"Container Number 2"}
														val={driverDetail?.container_ref_two}
													/>
												)}
											{LoadDetail?.quote_by !== "vehicle" && LoadDetail?.package_type?.title ? (
												<RenderRTL
													title={"Packaging type"}
													val={LoadDetail?.package_type?.title}
												/>
											) : null}
											{driverDetail?.no_of_packages ? (
												<RenderRTL
													title={"No. of Packages"}
													val={driverDetail?.no_of_packages}
												/>
											) : null}

											{driverDetail?.weight_two ? (
												<RenderRTL
													title={"Weight 2"}
													// val={driverDetail?.weight_two}

													val={`${numeral(driverDetail?.weight_two).format("0,0")} Kg`}
												/>
											) : null}

											{LoadDetail?.quote_by !== "vehicle" && LoadDetail?.vehicles_drivers_refs[0]?.vehicle?.master_vehicle_type
												?.title ? (
												<RenderRTL
													title={"Vehicle Type"}
													val={
														LoadDetail?.vehicles_drivers_refs[0]?.vehicle?.master_vehicle_type
															?.title
													}
												/>
											) : null}

											{activeLoadDetail?.vehicle?.title &&
												LoadDetail?.quote_by === "vehicle" && (
													<RenderRTL
														title={"Vehicle Type"}
														val={activeLoadDetail?.vehicle?.title}
													/>
												)}

											{activeLoadDetail?.container_type?.title &&
												LoadDetail?.quote_by === "vehicle" && (
													<RenderRTL
														title={"Container Type"}
														val={activeLoadDetail?.container_type?.title}
													/>
												)}
											{driverDetail?.vehicle?.plate_number && (
												<RenderRTL
													title={"Plate Number"}
													val={driverDetail?.vehicle?.plate_number}
												/>
											)}
											{activeLoadDetail?.is_unit_basis && activeLoadDetail?.gross_weight ? (
												<RenderRTL
													title={"Gross Weight"}
													val={activeLoadDetail?.gross_weight + " Ton"}
												/>
											) : null}
											{activeLoadDetail?.dimensions && (
												<RenderRTL
													title={"Dimensions"}
													val={activeLoadDetail?.dimensions}
												/>
											)}
											{activeLoadDetail?.dimensions && (
												<RenderRTL
													title={"No. of Items"}
													val={activeLoadDetail?.unit_quantity}
												/>
											)}
											{
												!state?.fromPoster && LoadDetail?.is_viewer !== true ?
													<OutLineButton
														disabled={!myDetail?.show_recipient_mark_complete_btn || LoadDetail?.status_name == "Completed"}
														onClick={() => {
															history.push("/bulk-add-vehicle-screen", {
																from: "active",
																vehicle_no: state?.vehicle_data?.index,
																load_id: state?.vehicle_data?.load_id,
																cat_id: state?.vehicle_data?.cat_id,
																already_added: state?.vehicle_data?.containers?.length,
																required_vehicles: state?.vehicle_data?.required_vehicles,
																vehicle: state?.vehicle_data?.vehicle
															});
														}}
														buttonStyle={{ height: 44 }}
														className="replace-vehicle-button"
														title={'Replace Vehicle'}
													/> : null
											}
										</div>
										<div>
											{
												driverDetail ?
													<div className="activeContent">
														<div className="grayLabel">
															Driver Detail
														</div>
														<ContactCard
															style={{ marginTop: 8 }}
															poster={{
																name: driverDetail?.driver?.name || "Driver",
																phone_code: driverDetail?.driver?.phone_code,
																phone_no: driverDetail?.driver?.phone_no
															}}
															details={!state?.fromPoster && LoadDetail?.is_viewer !== true}
															driverDetail={driverDetail}
															myDetail={myDetail}
														/>
													</div>
													: null
											}
										</div>
										<div className="addressBox">
											<div className="grayLabel">
												Pickup and Dropoff Address
											</div>
											{LoadDetail?.locations && (
												<LocationTimeline
													location={[{ ...LoadDetail?.locations[0] }, { ...LoadDetail?.locations[1] }]}
													handleModal={(value: boolean) => { }}
													fromActive={!state?.fromPoster ? false : true}
													is_viewer={state?.is_viewer || !state?.fromPoster ? true : false}
													LoadDetail={myDetail}
													type={"received"}
													recipient_status={{
														status: 4
													}}
													is_recipient={false}
												></LocationTimeline>
											)}
										</div>

									</div>
								</Accordion>
							</div>
						</FadeInComponent>

						<div className="">
							{/* <FadeInComponent> */}
							<div className="googleMapContainer">
								<div>
									<div className="label">
										<CustomText.OpenSansSemiBold textStyle={{

										}}>
											Location
										</CustomText.OpenSansSemiBold>
									</div>

									{marker?.location || "N/A"}
								</div>
								<div className="googleMap" style={{ height: 320 }}>
									{
										!isBrowser ?
											<div onClick={() => {
												setisFulScreen(!isFulScreen)
												if (!isFulScreen) {
													document.querySelector('.googleMap').classList.add('inside-map-div1')
													document.querySelector('.googleMap').classList.remove('googleMap')
												} else {
													document.querySelector('.inside-map-div1').classList.add('googleMap')
													document.querySelector('.googleMap').classList.remove('inside-map-div1')
												}
											}} className="map-zoom-button">
												<img style={{
													height: '100%',
													width: '100%'
												}} src={!isFulScreen ? Images.ZoomMap : Images.UnZoomMap} />
											</div> : null
									}

									<GoogleMap
										// zoom={
										// 	hasLatLong && marker ==null ? 4 :
										// 		// hasLatLong && marker !==null ? 16 :
										// 			pickup_dropoff_location && marker == null ? 12 :
										// 				marker !== null || pickup_dropoff_location ? 16 :
										// 					additional_locations?.length > 0 ? 16 :
										// 						// marker ? 16 :
										// 							marker?.length > 0 || additional_locations?.length > 0 ? 16 :
										// 								10}
										zoom={pickup_dropoff_location[0]?.lat == null && pickup_dropoff_location[0]?.long == null ||
											pickup_dropoff_location[1]?.lat == null && pickup_dropoff_location[1]?.long == null
											? 12 :
											pickup_dropoff_location[0]?.lat !== null && pickup_dropoff_location[0]?.long !== null ||
												pickup_dropoff_location[1]?.lat !== null && pickup_dropoff_location[1]?.long !== null
												? 16
												: pickup_dropoff_location[0]?.lat !== null && pickup_dropoff_location[0]?.long !== null ||
													pickup_dropoff_location[1]?.lat !== null && pickup_dropoff_location[1]?.long !== null
													&&
													marker?.length == null ? 10 :
													16}
										screen="active_load"
										marker={marker ? [marker] : []}
										driverMarker={driverMarker}
										center={center}
										locations={pickup_dropoff_location}
										additional_locations={additional_locations}
									/>
								</div>
							</div>
							{/* </FadeInComponent> */}

							<div>
								{
									!state?.fromPoster ?
										<FadeInComponent>
											<div className="inputField">
												<div className="label">Add Location</div>
												<div className="field">
													<TextInput
														value={lastLocation}
														disabled={!myDetail?.show_recipient_mark_complete_btn || myDetail?.status_name == "Completed"}
														onChange={(e) => {
															if (e.target.value.length <= 100) {
																setLastLocation(e.target.value)
															}
														}}
														placeholder={"Enter location name"}
														type={'text'}
													/>
												</div>
											</div>
											<OutLineButton
												onClick={() => {
													addVehicleLastLocation()
												}}
												disabled={!lastLocation}
												title={"Add location"}
												buttonStyle={{
													height: 44,
													marginBottom: '1.5rem'
												}}
												buttonTitleStyle={{
													marginTop: 5,
													fontSize: "0.875rem"
												}}
											/>
										</FadeInComponent> : null
								}
							</div>

							<FadeInComponent>
								<div className="accordion-container">
									<Accordion
										className="accordion-background"
										expanded={expandChecklist}
										onChange={() => setExpandChecklist(!expandChecklist)}
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<div className="fw-5">
												{" Delivery Checklist"}
											</div>
										</AccordionSummary>


										<div className="accordion-content">
											<div
												// style={{ marginTop: 15 }} 
												className="deliveryChecklistWrapper">
												<div className="checklistWrapper">
													{myDetail?.is_empty_return ? (
														<DeliveryChecklist
															label="Empty Pickup"
															value={driverChecks?.has_empty_pickup}
															id={6}
															disabled={myDetail?.status_name == "Completed" || !myDetail?.show_recipient_mark_complete_btn ||
																myDetail?.status_name == "Completed" && driverDetail?.checklist?.has_empty_pickup_updated_by !== profile?.id ? true : false
																	|| driverDetail?.checklist?.has_empty_pickup_updated_by ? driverDetail?.checklist?.has_empty_pickup_updated_by !== profile?.id ? true : false : false}
															urdutitle="ایمپٹی پک اپ"
															time={driverDetail?.checklist?.has_empty_pickup_at}
															userName={driverDetail?.checklist?.hasEmptyPickupUpdatedBy?.name}
														// updatedBySys={driverDetail?.checklist?.hasEmptyPickupUpdatedBy}

														/>
													) : null}

													<DeliveryChecklist
														label="Arrived for loading"
														value={driverChecks?.has_arrived_for_loading}
														id={1}
														disabled={myDetail?.status_name == "Completed"
															|| !myDetail?.show_recipient_mark_complete_btn ||
															myDetail?.status_name == "Completed" && driverDetail?.checklist?.has_arrived_for_loading_updated_by !== profile?.id ? true : false
																|| driverDetail?.checklist?.has_arrived_for_loading_updated_by ? driverDetail?.checklist?.has_arrived_for_loading_updated_by !== profile?.id ? true : false : false}
														time={driverDetail?.checklist?.has_arrived_for_loading_at}
														urdutitle="گاڑی لوڈنگ کے ليئے پہنچ گئ ہے"
														userName={driverDetail?.checklist?.hasArrivedForLoadingUpdatedBy?.name}

														// updatedBySys={driverDetail?.checklist?.hasArrivedForLoadingUpdatedBy}

														updatedBySys={driverDetail?.checklist?.has_arrived_for_loading_at_by_system}
													/>

													<DeliveryChecklist
														label="Loading completed"
														value={driverChecks?.has_cargo_loaded}
														id={2}
														disabled={myDetail?.status_name == "Completed" || !myDetail?.show_recipient_mark_complete_btn ||
															myDetail?.status_name == "Completed" && driverDetail?.checklist?.has_cargo_loaded_updated_by !== profile?.id ? true : false
																|| driverDetail?.checklist?.has_cargo_loaded_updated_by ? driverDetail?.checklist?.has_cargo_loaded_updated_by !== profile?.id ? true : false : false}
														urdutitle="لوڈنگ مکمل"
														time={driverDetail?.checklist?.has_cargo_loaded_at}
														userName={driverDetail?.checklist?.hasCargoLoadedUpdatedBy?.name}
													// updatedBySys={driverDetail?.checklist?.hasCargoLoadedUpdatedBy}

													/>


													<DeliveryChecklist
														label="Arrived at destination"
														value={driverChecks?.has_arrived_at_destination}
														id={3}
														disabled={myDetail?.status_name == "Completed" || !myDetail?.show_recipient_mark_complete_btn ||
															myDetail?.status_name == "Completed" && driverDetail?.checklist?.has_arrived_at_destination_updated_by !== profile?.id ? true : false ||
																driverDetail?.checklist?.has_arrived_at_destination_updated_by ? driverDetail?.checklist?.has_arrived_at_destination_updated_by !== profile?.id ? true : false : false}
														urdutitle="گاڑی منزل پر پہنچ گئ ہے"
														time={driverDetail?.checklist?.has_arrived_at_destination_at}
														userName={driverDetail?.checklist?.hasArrivedAtDestinationUpdatedBy?.name}

														// updatedBySys={driverDetail?.checklist?.hasArrivedAtDestinationUpdatedBy}

														updatedBySys={driverDetail?.checklist?.has_arrived_at_destination_at_by_system}

													/>

													<DeliveryChecklist
														label="Off-loaded"
														value={driverChecks?.has_delievered}
														id={4}
														disabled={myDetail?.status_name == "Completed" || !myDetail?.show_recipient_mark_complete_btn ||
															myDetail?.status_name == "Completed" && driverDetail?.checklist?.has_delievered_updated_by !== profile?.id ? true : false
																|| driverDetail?.checklist?.has_delievered_updated_by ? driverDetail?.checklist?.has_delievered_updated_by !== profile?.id ? true : false : false}
														urdutitle="گاڑی خالی ہوگئ ہے"
														time={driverDetail?.checklist?.has_delievered_at}
														userName={driverDetail?.checklist?.hasDelieveredUpdatedBy?.name}
													// updatedBySys={driverDetail?.checklist?.hasDelieveredUpdatedBy}

													/>

													{myDetail?.is_empty_return ? (
														<DeliveryChecklist
															label="Empty Container Returned"
															value={driverChecks?.has_empty_returned}
															id={5}
															disabled={myDetail?.status_name == "Completed" || !myDetail?.show_recipient_mark_complete_btn ||
																myDetail?.status_name == "Completed" && driverDetail?.checklist?.has_empty_returned_updated_by !== profile?.id ? true : false
																	|| driverDetail?.checklist?.has_empty_returned_updated_by ? driverDetail?.checklist?.has_empty_returned_updated_by !== profile?.id ? true : false : false}
															urdutitle="ايمپٹی واپس"
															time={driverDetail?.checklist?.has_empty_returned_at}
															userName={driverDetail?.checklist?.hasEmptyReturnedUpdatedBy?.name}
														// updatedBySys={driverDetail?.checklist?.hasEmptyReturnedUpdatedBy}

														/>
													) : null}
												</div>
											</div>

											{/* <div className="fw-5">
												Last Updated Locations
											</div>

											<div>
												{driverDetail?.locations?.length > 0 &&
													slicedData?.map((loc, index) => {
														
														return (
															<div className="sectionBox white customSectionBox">
																<>
																	<div key={index}>
																		<CustomText.OpenSansSemiBold>
																			{loc?.location}
																		</CustomText.OpenSansSemiBold>
																		<CustomText.OpenSansRegular>
																			{`(Updated by ${loc?.added_by})`}
																		</CustomText.OpenSansRegular>
																	</div>

																	<CustomText.OpenSansRegular>
																		{moment(loc?.created_at).format('DD-MM-YYYY hh:mm A')}
																	</CustomText.OpenSansRegular>
																</>

															</div>
														)
													})
												}
												{
													driverDetail?.locations?.length > 5 ?
														<div className="moreLink" onClick={() => {
															setShowModal(true)
														}}>
															<CustomText.OpenSansSemiBold textStyle={{
																textDecoration: 'underline',
																color: Colors.Primary,
																cursor: "pointer"

															}}>More</CustomText.OpenSansSemiBold>
														</div> : null
												}
											</div> */}

											{/* <div className="arrivalTimeDetail formGrid">
												<div className="grayLabel">
													Estimated time of arrival
												</div>
												{driverDetail?.est_delivery_at ? (
													<div className="activeLoadTiming fw-5">
														<div>
															{driverDetail?.est_delivery_at &&
																moment(driverDetail?.est_delivery_at).format(
																	"DD-MM-YYYY, hh:mm A"
																)}
														</div>
														<div className="grayLabel">
															{moment().isBefore(driverDetail?.est_delivery_at) &&
																driverDetail?.est_delivery_at && (
																	<>
																		Arrival in{" "}
																		{getTimeDifference(
																			moment(driverDetail?.est_delivery_at),
																			moment(),
																			"hours"
																		)}{" "}
																		hrs
																	</>
																)}
														</div>
													</div>
												) : (
													<div>
														<div className="fw-5">
															Not added by driver
														</div>
													</div>
												)}
											</div> */}

										</div>
									</Accordion>
								</div>
							</FadeInComponent>

							{/* location history */}
							<FadeInComponent>
								<div className="accordion-container">
									<Accordion
										className="accordion-background"
										expanded={expandLocationHistory}
										onChange={() => setExpandLocationHistory(!expandLocationHistory)}
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<div className="fw-5">
												Location History
											</div>
										</AccordionSummary>

										<div className="accordion-content">
											<div>
												{driverDetail?.locations?.length > 0 &&
													slicedData?.map((loc, index) => {

														return (
															<div className="sectionBox white customSectionBox">
																<>
																	<div key={index}>
																		<CustomText.OpenSansSemiBold>
																			{loc?.location}
																		</CustomText.OpenSansSemiBold>
																		<CustomText.OpenSansRegular>
																			{`(Updated by ${loc?.added_by})`}
																		</CustomText.OpenSansRegular>
																	</div>

																	<CustomText.OpenSansRegular>
																		{moment(loc?.created_at).format('DD-MM-YYYY hh:mm A')}
																	</CustomText.OpenSansRegular>
																</>
															</div>
														)
													})
												}
												{
													driverDetail?.locations?.length > 5 ?
														<div className="moreLink" onClick={() => {
															setShowModal(true)
														}}>
															<CustomText.OpenSansSemiBold textStyle={{
																textDecoration: 'underline',
																color: Colors.Primary,
																cursor: "pointer"

															}}>More</CustomText.OpenSansSemiBold>
														</div> : null
												}
											</div>
										</div>
									</Accordion>
								</div>
							</FadeInComponent>

							{/* new changes for document */}
							{
								!state?.fromPoster && (
									<FadeInComponent>
										<div className="uploadDocumentFileBoxWrap">
											<div className="documentWrapper">
												<div style={{ display: "flex" }}>
													<div className={`docTitle`}>Upload Document/EIR/POD/Bilty</div>
												</div>
												<div
												// className={classes.root}
												>
													{/* <div style={{
														pointerEvents: myDetail?.show_recipient_mark_complete_btn ? 'auto' : 'none'
													}}>
														<label htmlFor="upload-nic-front" id="upload-button-container">
															<Button
																variant="contained"
																className="outline-button"
																disabled={!myDetail?.show_recipient_mark_complete_btn || myDetail?.status_name == "Completed"}

																component="span"
																style={{ border: myDetail?.status_name !== "Completed" ? `1px solid ${Colors.Primary}` : '1px solid rgb(204, 204, 204)' }}
															>
																<p
																	style={{
																		color: myDetail?.status_name !== "Completed" ? Colors.Primary : 'rgb(204, 204, 204)'
																	}}
																	className="button-title" >Upload File</p>
															</Button>
														</label>
														<input
															className="hide-input"
															id="upload-nic-front"
															multiple
															type="file"
															onChange={handleChangeFile}
															disabled={!myDetail?.show_recipient_mark_complete_btn || myDetail?.status_name == "Completed"}

														/>
													</div> */}
													<div className="accordion-content upload-doc">
														<UploadDocByRecipient
															loadDetail={LoadDetail}
															handleChangeFile={handleChangeFile}
															// recipient_uploaded={recipient_uploaded}
															// set_recipient_uploaded={set_recipient_uploaded}
															uploadFileRecipient={uploadFileRecipient}
														/>
													</div>
												</div>
											</div>
										</div>
									</FadeInComponent>
								)
							}

							{
								LoadDetail?.quote_by === "vehicle" || LoadDetail?.is_unit_basis ? (
									<FadeInComponent>
										<UploadedDocumentsRecipient loadDetail={myDetail} data={activeLoadDetail?.drivers?.length && activeLoadDetail?.drivers[0]?.documents?.length && activeLoadDetail?.drivers[0]?.documents} uploadedBy={!state?.fromPoster ? "You" : "Recipient"} is_poster={false} fromActive={true} />
									</FadeInComponent>
								) : (
									<FadeInComponent>
										<UploadedDocumentsRecipient loadDetail={myDetail} data={activeLoadDetail && activeLoadDetail?.documents?.length && activeLoadDetail?.documents} uploadedBy={!state?.fromPoster ? "You" : "Recipient"} is_poster={false} fromActive={true} />
									</FadeInComponent>
								)
							}
						</div>
					</div>


					<CustomModal
						showModal={showModal}
						toggleModal={() => {
							setShowModal(false)
						}}
						className={"viewerModal"}
						modalStyle={{ width: 218 }}
					>

						<div>
							<span className="icons-cross floatRight" onClick={() => { setShowModal(false); }}></span>
							<div className="insideViewerData">
								{
									driverDetail?.locations?.length > 5 &&
									slicedSixData?.map((loc) => {

										return (
											<div className="sectionBox white customSectionBox modalCustomSectionBox">
												<div>
													<CustomText.OpenSansSemiBold>
														{loc?.location}
													</CustomText.OpenSansSemiBold>
													<CustomText.OpenSansRegular>
														{`(Updated by ${loc?.added_by})`}
													</CustomText.OpenSansRegular>
												</div>

												<CustomText.OpenSansRegular>
													{moment(loc?.created_at).format('DD-MM-YYYY hh:mm A')}
												</CustomText.OpenSansRegular>
											</div>
										)
									})
								}
							</div>
						</div>
					</CustomModal>
				</>
			</TitleLayout>
		);
	} else {
		return (
			<div></div>
		)
	}

};
export default Page;
