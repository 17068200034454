import React, { useEffect, useState, useRef } from "react";
import { ILoadDetailsPageProps } from "./types";
import "./style.scss";
import { Accordion, Grid, AccordionSummary } from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import { AuthAction, LoadAction, ProfileAction } from "@temp/store/actions";
import {
  showMessage,
  getDate,
  getTime,
  setItem,
  getFullTime,
  isObjectEmpty,
} from "@temp/utils/helper";
import { RenderRecipient } from "../components/RenderRecipient";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";

import domToPdf from "dom-to-pdf";
import { isBrowser } from "react-device-detect";
import { parse } from "query-string";
import {
  AdditionalDetail,
  LocatonDetail,
  PaymentTerms,
  Poster,
  TransactionType,
  UploadedDocuments,
  RenderRecipientLCL,
  CategoryCargoHeading,
  ThreeColumnRTL,
  TwoColumnRTLWeight,
  TwoColumnRTLVehicle,
  LoadViewer,
  ActiveVehicles,
  UploadedDocumentsRecipient,
} from "../components/";
import {
  CustomText,
  PrimaryButton,
  TitleLayout,
  MaterialUIPickers,
  CustomModal,
  Counter,
  OutLineButton,
  GoogleMap,
} from "@components";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { RenderRTL } from "../components/RenderRTL";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import {
  convertDataForFclAndBulkLoadDetailRedux,
  convertDataForLoadDetailRedux,
} from "@temp/utils/dataFormat";
import { LOAD_TYPE } from "@temp/constants";
import dayjs from "dayjs";
import { Images } from "@temp/assets";

const Page: React.FC<ILoadDetailsPageProps> = (props) => {
  const vehiclePinPointMarker = useSelector(
    (state: RootState) => state?.Load?.vehiclePinPointMarker
  );

  const locationCompRef = useRef();
  const downloadRef = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();
  const loactions = useLocation();
  const history = useHistory();
  const [toShowAccordion, setToShowAccordion] = useState(false);


  useEffect(() => {
    const queryParams = parse(history.location.search);
    dispatch(AuthAction.setNativeToken(queryParams?.token));
    if (queryParams?.token) {
      setItem("token", queryParams?.token);
      setItem("toc_accepted", true);
      setItem("changed_password", true);
    }
    if (queryParams?.pdf_generate) {
      setToShowAccordion(true);
    }
    setTimeout(() => {
      dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));

    }, 200);
  }, []);


  const loadDetail = useSelector((state: RootState) => state.Load.loadDetail);

  let pickup_dropoff_location = [];
  let additional_locations = [];
  if (loadDetail) {
    if (loadDetail?.additional_dropoffs.length) {
      pickup_dropoff_location.push(loadDetail?.locations[0]);
      additional_locations.push(loadDetail?.locations[1]);
      let tempIndex = null;
      loadDetail?.additional_dropoffs?.forEach((element, index) => {
        if (element?.lat && element?.long) {
          pickup_dropoff_location[1] = element;
          tempIndex = index;
        }
      });
      loadDetail?.additional_dropoffs?.forEach((element, index) => {
        if (element?.lat && element?.long && index !== tempIndex) {
          additional_locations.push(element);
        }
      });

      if (pickup_dropoff_location.length !== 2) {
        pickup_dropoff_location[1] = loadDetail?.locations[1];
      }
    } else {
      pickup_dropoff_location.push(loadDetail?.locations[0]);
      pickup_dropoff_location.push(loadDetail?.locations[1]);
    }
  }
  const [awarded, setAwarded] = useState([]);
  const [currentAward, setCurrentAward] = useState(null);
  const [expand, setExpand] = useState(true);
  const [activeExpand, setActiveExpand] = useState(false);
  const [awardIndex, setAwardIndex] = useState(null);
  const [lastAwardIndex, setLastAwardIndex] = useState(null);
  const [awardLcl, setAwardLcl] = useState([]);
  const [isDeadlineComplete, setIsDeadlineComplete] = useState(false);
  const [totalContainers, setTotalContainers] = useState(0);
  const [genericDate, setGenericDate] = useState(null);
  const [genericTime, setGenericTime] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [showModalGeneric, setShowModalGeneric] = useState(false);
  const [dateType, setDateType] = useState(null);
  const [already_awarded, set_already_awarded] = useState([]);
  const [showModalNoti, setShowModalNoti] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
    }, 200);
  }, [])

  useEffect(() => {
    if (loadDetail) {
      if (loadDetail?.is_load_history) {
        cloneALoadCallBackLoadHistory(loadDetail);
        setShowDetail(false);
      } else {
        setShowDetail(true);
      }
    }
  }, [loadDetail]);

  useEffect(() => {
    let data = [];
    if (loadDetail?.categories?.length) {
      loadDetail?.categories.forEach((cat) => {
        cat?.recipients?.forEach((recipt) => {
          data.push(recipt);
        });
      });
    } else {
      loadDetail?.recipients?.forEach((recipt) => {
        data.push(recipt);
      });
    }
    set_already_awarded(data);
  }, [loadDetail]);

  useEffect(() => {
    if (toShowAccordion) {
      showAccordion();
    }
  }, [loadDetail]);

  const showAccordion = () => {
    document.querySelector(".loadDetailWrapper").classList.add("activePrint");
    setToShowAccordion(true);
  };

  const closeAccordion = () => {
    document
      .querySelector(".loadDetailWrapper")
      .classList.remove("activePrint");
    setToShowAccordion(false);
  };

  useEffect(() => {
    const target = (message) => {
      closeAccordion();
    };
    window.addEventListener("pdfDownloadComplete", target);
  }, []);

  useEffect(() => {
    if (loadDetail) {
      let totalCat = 0;
      if (loadDetail?.categories?.length > 0) {
        loadDetail?.categories.forEach((element) => {
          if (element?.is_twin_load) {
            if (loadDetail?.load_type?.title === "Containerized") {
              totalCat += Number(element.vehicle_quantity) * 2;
            } else {
              totalCat += Number(element.vehicle_quantity);
            }
          } else {
            totalCat += Number(element.vehicle_quantity);
          }
        });
      }
      setTotalContainers(totalCat);
    }
  }, [loadDetail]);

  const generatePdf = () => {
    const options = {
      filename: `Load ID ${loadDetail?.id}`,
    };
    domToPdf(downloadRef?.current, options, () => { });
    setTimeout(() => {
      closeAccordion();
    }, 1000);
  };

  const getMillisec = (t1, t2) => {
    let new_date = moment(
      `${dayjs(t1).format("YYYY-MM-DD")} ${dayjs(t2).format("HH:mm:ss")}`
    ).format("x");
    return Number(new_date);
  };

  // update response deadline
  const updateDeadlineResponse = () => {
    let final_time =
      String(getDate(genericDate)) +
      " " +
      dayjs(genericTime).format("HH:mm:ss");
    // return
    if (dateType === "Response Deadline") {
      if (
        getMillisec(genericDate, dayjs(genericTime)) >
        new Date().getTime() + 5000
      ) {
        if (loadDetail?.pickup_date && loadDetail?.pickup_time) {
          if (
            getMillisec(genericDate, dayjs(genericTime)) >
            new Date(
              `${loadDetail?.pickup_date} ${loadDetail?.pickup_time}`
            ).getTime() +
            5000
          ) {
            showMessage.current({
              message: "Response time should be less than pickup time.",
              status: "error",
            });
          } else {
            setShowModalGeneric(false);
            dispatch(
              LoadAction.updateResponseDeadline({
                deadline_at: final_time,
                load_id: loadDetail?.id,
              })
            );
          }
        } else {
          if (
            moment(genericDate).format("YYYY-MM-DD") <= loadDetail?.pickup_date
          ) {
            setShowModalGeneric(false);
            dispatch(
              LoadAction.updateResponseDeadline({
                deadline_at: final_time,
                load_id: loadDetail?.id,
              })
            );
          } else {
            showMessage.current({
              message: "Response date should be less than pickup date.",
              status: "error",
            });
          }
        }
      } else {
        showMessage.current({
          message: "Response time should be greater than current time.",
          status: "error",
        });
      }
    } else if (dateType === "Pick Up Date") {
      if (!genericTime) {
        if (
          moment(genericDate).format("YYYY-MM-DD") >=
          moment(loadDetail?.deadline_at).format("YYYY-MM-DD")
        ) {
          setShowModalGeneric(false);
          dispatch(
            LoadAction.updateResponseDeadline({
              pickup_date: moment(genericDate).format("YYYY-MM-DD"),
              pickup_time: null,
              load_id: loadDetail?.id,
            })
          );
        } else {
          showMessage.current({
            message: "Pick-up time should be greater than response deadline.",
            status: "error",
          });
        }
      } else {
        if (
          getMillisec(genericDate, genericTime) >=
          new Date(loadDetail?.deadline_at).getTime() + 5000
        ) {
          setShowModalGeneric(false);
          dispatch(
            LoadAction.updateResponseDeadline({
              pickup_date: moment(genericDate).format("YYYY-MM-DD"),
              pickup_time: dayjs(genericTime).format("HH:mm:ss"),
              load_id: loadDetail?.id,
            })
          );
        } else {
          showMessage.current({
            message: "Pick-up time should be greater than response deadline.",
            status: "error",
          });
        }
      }
    } else if (dateType === "Delivery Deadline") {
      let new_pickup = `${loadDetail?.pickup_date} ${loadDetail?.pickup_time}`;
      if (
        getMillisec(genericDate, genericTime) >
        new Date(new_pickup).getTime() + 5000
      ) {
        setShowModalGeneric(false);
        dispatch(
          LoadAction.updateResponseDeadline({
            delivery_at: final_time,
            load_id: loadDetail?.id,
          })
        );
      } else {
        showMessage.current({
          message:
            // "Delivery time should be greater than pickup.",
            "Delivery time should be greater than pickup deadline.",
          status: "error",
        });
      }
    } else if (dateType === "Empty Return Deadline") {
      setShowModalGeneric(false);
      dispatch(
        LoadAction.updateResponseDeadline({
          empty_return_deadline_at: final_time,
          load_id: loadDetail?.id,
        })
      );
      return;
    } else if (dateType === "Rate Validity") {
      setShowModalGeneric(false);
      dispatch(
        LoadAction.updateResponseDeadline({
          rate_validity_date: moment(genericDate).format("YYYY-MM-DD"),
          load_id: loadDetail?.id,
        })
      );
    }
  };

  const preFilledVehicle = (data) => {
    let new_data = [...awarded];
    let findd = new_data.find((item) => item?.cat_id == data.cat_id);
    if (!findd) {
      new_data.push({
        job_load_recipient_id: data?.id,
        no_of_vehicles: 1,
        cat_id: data?.cat_id,
        user_id: data?.recipient?.id,
      });
      setAwarded(new_data);
      setCurrentAward(data?.cat_id);
      setAwardIndex(data?.index);
      if (lastAwardIndex !== data?.index) {
        setLastAwardIndex(data?.index);
      }
    }
  };

  useEffect(() => {
    return () => {
      setAwarded([]);
      setCurrentAward(null);
      setAwardIndex(null);
      setLastAwardIndex(null);
    };
  }, []);

  // award increment vehicle based
  // const awardIncrement = (item) => {
  //   console.log(item, "itemmmmmmInc")
  //   if (awarded?.length && currentAward != item?.cat_id) {
  //     let awardede = [];
  //     setAwarded(awardede);
  //     return;
  //   }
  //   let new_award = [...awarded];
  //   let find = new_award.find((itm) => itm.job_load_recipient_id == item?.id);
  //   let total = 0;
  //   awarded.forEach((item) => {
  //     total = total + Number(item?.no_of_vehicles);
  //   });
  //   if (find) {
  //     find["no_of_vehicles"] += 1;
  //   } else {
  //     new_award.push({
  //       job_load_recipient_id: item?.id,
  //       no_of_vehicles: 1,
  //       cat_id: item?.cat_id,
  //       user_id: item?.recipient?.id,
  //       // totalRequiredVehicle: item?.totalRequiredVehicle,
  //       // responded: item.responded
  //     });
  //   }
  //   setAwarded(new_award);
  //   setCurrentAward(item?.cat_id);
  //   setAwardIndex(item?.index);
  //   if (lastAwardIndex !== item?.index) {
  //     setLastAwardIndex(item?.index);
  //   }
  // };

  const awardIncrement = (item) => {
    if (awarded?.length && currentAward != item?.cat_id) {
      let awardede = [];
      setAwarded(awardede);
      return;
    }
    let new_award = [...awarded];
    let find = new_award.find((itm) => itm.job_load_recipient_id == item?.id);
    let total = 0;
    awarded.forEach((item) => {
      total = total + Number(item?.no_of_vehicles);
    });

    if ((total >= item?.total_vehicle || find?.no_of_vehicles >= item?.total_vehicle)) {
      if (loadDetail?.is_unit_basis) {
        showMessage.current({
          message:
            // "Awarded Item can not be greater than total Item",
            "You cannot award Item more than the required",
          status: "error",
        });
      } else {
        showMessage.current({
          message: "Awarded vehicle can not be greater than total vehicle",
          status: "error",
        });
      }

    } else {
      if (find) {
        if (find["no_of_vehicles"] >= item?.bid?.no_of_vehicles) {
          if (loadDetail?.is_unit_basis) {
            showMessage.current({ message: "You can not award Items more than responded", status: 'error' })
          } else {
            showMessage.current({ message: "You can not award vehicles more than responded", status: 'error' })
          }
          return
        } else {
          find["no_of_vehicles"] += 1;
        }
      } else {
        new_award.push({
          job_load_recipient_id: item?.id,
          no_of_vehicles: 1,
          cat_id: item?.cat_id,
          user_id: item?.recipient?.id,
          totalRequiredVehicle: item?.totalRequiredVehicle,
          responded: item.responded
        });
      }
    }
    setAwarded(new_award);
    setCurrentAward(item?.cat_id);
    setAwardIndex(item?.index);
    if (lastAwardIndex !== item?.index) {
      setLastAwardIndex(item?.index);
    }
  };

  const [validateCategoryAward, setValidateCategoryAward] = useState({
    required: 0,
    entered: 0,
  });

  const [validateRespond, setValidateRespond] = useState({
    responded: 0,
    entered: 0,
    required: 0
  });

  useEffect(() => {
    let result = awarded.reduce(function (acc, obj) {
      return acc + obj.no_of_vehicles;
    }, 0);

    if (result > awarded[0]?.totalRequiredVehicle) {
      if (loadDetail?.is_unit_basis) {
        showMessage.current({
          message: "You can not award Items more than total items",
          status: "error",
        });
      } else {
        showMessage.current({
          message: "You can not award vehicles more than total vehicles",
          status: "error",
        });
      }
    }
    setValidateCategoryAward({
      required: awarded[0]?.totalRequiredVehicle || 0,
      entered: result,
    });
  }, [awarded]);


  const awardIncrementOnInput = (item) => {
    if (
      !loadDetail?.locations[0]?.raw_address ||
      !loadDetail?.locations[1]?.raw_address ||
      !loadDetail?.locations[0]?.contact_name ||
      !loadDetail?.locations[0]?.contact_phone_no ||
      !loadDetail?.locations[1]?.contact_name ||
      !loadDetail?.locations[1]?.contact_phone_no
    ) {
      locationCompRef?.current?.openModal();
    } else {

      if (awarded?.length && currentAward != item?.cat_id) {
        let awardede = [];
        setAwarded(awardede);
        // return;
      }
      let new_award =
        awarded?.length && currentAward != item?.cat_id ? [] : [...awarded];
      let find = new_award.find((itm) => itm.job_load_recipient_id == item?.id);
      let total = 0;
      new_award.forEach((it) => {
        total = total + Number(it?.no_of_vehicles);
      });
      if (find) {
        find["no_of_vehicles"] = item.val;
      } else {
        new_award.push({
          job_load_recipient_id: item?.id,
          no_of_vehicles: item.val,
          cat_id: item?.cat_id,
          user_id: item?.recipient?.id,
          totalRequiredVehicle: item?.totalRequiredVehicle,
          responded: item.responded
        });
      }
      // }
      setAwarded(new_award);
      setValidateRespond({
        entered: item.val,
        responded: item?.responded,
        required: item?.totalRequiredVehicle
      })
      setCurrentAward(item?.cat_id);
      setAwardIndex(item?.index);
      if (lastAwardIndex !== item?.index) {
        setLastAwardIndex(item?.index);
      }
    }
  };


  // award when is partial load, cross stufing and container deposit is on
  const awardIncrementMultiple = (item) => {
    if (
      !loadDetail?.locations[0]?.raw_address ||
      !loadDetail?.locations[1]?.raw_address ||
      !loadDetail?.locations[0]?.contact_name ||
      !loadDetail?.locations[0]?.contact_phone_no ||
      !loadDetail?.locations[1]?.contact_name ||
      !loadDetail?.locations[1]?.contact_phone_no
    ) {
      locationCompRef?.current?.openModal();
    } else {
      let new_award = [...awarded];
      let find = new_award.find((itm) => itm.job_load_recipient_id == item?.id);
      let find_index = new_award.findIndex(
        (item) => item?.job_load_recipient_id == item?.id
      );
      if (new_award?.length) {
        let find_user = new_award.find(
          (it) => it.user_id == item?.recipient?.id
        );
        if (find_user) {
          if (find) {
            new_award.splice(find_index, 1);
          } else {
            new_award.push({
              job_load_recipient_id: item?.id,
              no_of_vehicles: item?.vehicles,
              cat_id: item?.cat_id,
              user_id: item?.recipient?.id,
            });
          }
        } else {
          if (
            loadDetail?.is_container_deposit_by_transporter ||
            loadDetail?.is_cross_stuffing_required
          ) {
            showMessage.current({
              message:
                "You can not award different recipients in case of cross stuffing or container deposit by transporter",
              status: "error",
            });
          } else {
            if (!loadDetail?.is_allow_partial_load) {
              showMessage.current({
                message:
                  // "Partial award is not allowed, You can not award different recipients.",
                  "Partial Load is not allowed, You can not award different recipients.",
                status: "error",
              });
            }
          }
        }
      } else {
        new_award.push({
          job_load_recipient_id: item?.id,
          no_of_vehicles: item?.vehicles,
          cat_id: item?.cat_id,
          user_id: item?.recipient?.id,
        });
      }
      setAwarded(new_award);
      setCurrentAward(item?.cat_id);
      setAwardIndex(item?.index);
      if (lastAwardIndex !== item?.index) {
        setLastAwardIndex(item?.index);
      }
    }
  };

  // award decrement vehicle based
  const awardDecrement = (item) => {
    let new_award = [...awarded];
    let find = new_award.find((itm) => itm.job_load_recipient_id == item?.id);
    if (Number(find?.no_of_vehicles) <= 0) {
      showMessage.current({
        message: "Vehicle should be minimum one.",
        status: "error",
      });
      return;
    }
    if (find) {
      find["no_of_vehicles"] -= 1;
      if (find["no_of_vehicles"] < 1) {
        new_award.splice(
          new_award.findIndex((it) => it.job_load_recipient_id == item?.cat_id),
          1
        );
        // new_award.splice(new_award.findIndex((it) => it.cat_id == item?.cat_id), 1);
        setAwardIndex(null);
        setAwardIndex(lastAwardIndex);
      }
    }
    setAwarded(new_award);
    if (!awarded?.filter((item) => item?.no_of_vehicles > 0)?.length) {
      setAwarded([]);
      showMessage.current({
        message: "Vehicle should be minimum one.",
        status: "error",
      });
    }
  };

  // const callBack = (param) => {
  // 	if (param == "422") {
  // 	// return
  // 	} 
  // }

  // award job api call
  const award = () => {
    let new_awarded = awarded.filter((item) => item?.no_of_vehicles > 0);
    if (new_awarded?.length) {
      // return
      dispatch(
        LoadAction.awardJob({
          load_id: params?.id,
          awarded: new_awarded,
          category_id: currentAward,
          // callBack
        })
      );
      setAwarded([]);
    } else {
      showMessage.current({
        message: "Please select atleast one vehicle.",
        status: "error",
      });
    }
  };

  // decline by poster api call
  const declineByPoster = (id) => {
    setAwarded([]);
    setCurrentAward(null);
    setAwardIndex(null);
    dispatch(
      LoadAction.posterDeclined({
        load_id: params?.id,
        job_load_recipient_id: id,
      })
    );
  };

  // lcl and bulk change freight ton input field
  const hangleChangeFrightTon = (data) => {

    let new_data = [...awardLcl];
    let index = new_data.findIndex((item) => item?.id == data?.id);
    new_data[index]["awarded_freight_ton"] = data?.e.target.value;
    if (
      Number(new_data[index]["awarded_freight_ton"]) >
      Number(new_data[index]?.bid?.bid_freight_ton)
    ) {
      showMessage.current({
        message:
          // `${loadDetail?.price_unit} should not be greater than the ${loadDetail?.price_unit} offered by recipient.`,
          ` Awarded ${loadDetail?.price_unit} cannot be greater than offered ${loadDetail?.price_unit} by the recipient`,
        status: "error",
      });
      new_data[index]["awarded_freight_ton"] = Number(
        new_data[index]?.bid?.bid_freight_ton
      );

    }
    setAwardLcl(new_data);
  };


  // lcl and bulk weight and volume base checkbox to award
  const clickCheckboxAward = (data) => {
    if (
      !loadDetail?.locations[0]?.raw_address ||
      !loadDetail?.locations[1]?.raw_address ||
      !loadDetail?.locations[0]?.contact_name ||
      !loadDetail?.locations[0]?.contact_phone_no ||
      !loadDetail?.locations[1]?.contact_name ||
      !loadDetail?.locations[1]?.contact_phone_no
    ) {
      locationCompRef?.current?.openModal();
    } else {
      let arr = [...awardLcl];
      if (arr.find((item) => item?.id == data?.id)) {
        arr.splice(
          arr.findIndex((item) => item?.id == data?.id),
          1
        );
      } else {
        if (arr?.length > 0 && loadDetail?.is_allow_partial_load == 0) {
          showMessage.current({
            message:
              "Partial load not allowed, You can not award multiple recipients.",
            status: "error",
          });
        }
        else {
          if (loadDetail?.is_allow_partial_load == 0) {
            arr.unshift({
              awarded_freight_ton: data?.bid?.bid_freight_ton,
              // loadDetail?.total_freight_ton,
              bid: data?.bid,
              recipient: data?.recipient,
              id: data?.id,
              field_disabled: true,
            });
          } else {
            arr.unshift({
              awarded_freight_ton: "",
              bid: data?.bid,
              recipient: data?.recipient,
              id: data?.id,
              field_disabled: false,
            });
          }
        }
      }
      setAwardLcl(arr);
    }
  };

  // award api call for lcl and bulk wight and volume
  const awardWeightLoad = () => {
    let payload = [];
    let total = 0;
    let orignalFreightTon;
    if (loadDetail?.price_unit == "F.Ton") {
      orignalFreightTon = Number(loadDetail.total_freight_ton);
    } else if (loadDetail?.price_unit == "Ton") {
      orignalFreightTon = Number(loadDetail.gross_weight);
    } else if (loadDetail?.price_unit == "CBM") {
      orignalFreightTon = Number(loadDetail.cargo_volume);
    } else if (loadDetail?.price_unit == "Kg") {
      orignalFreightTon = Number(loadDetail.gross_weight) * 1000;
    } else if (loadDetail?.price_unit == "Liter") {
      orignalFreightTon = Number(loadDetail.cargo_liter);
    }

    let emptyFlag = false;
    awardLcl.forEach((item) => {
      total = total + Number(item?.awarded_freight_ton);
      if (item?.awarded_freight_ton == "") {
        emptyFlag = true;
      }
      else if (item?.awarded_freight_ton == ".0" || item?.awarded_freight_ton == ".00") {
        emptyFlag = true;
      }
      payload.push({
        awarded_freight_ton: item?.awarded_freight_ton,
        // orignalFreightTon,
        // item?.awarded_freight_ton, //
        job_load_recipient_id: item?.id,
      });
    });

    if (!emptyFlag) {
      if (total > orignalFreightTon) {
        showMessage.current({
          message: `${loadDetail?.price_unit} should be less than total ${loadDetail?.price_unit} set by poster.`,
          status: "error",
        });
      }
      else {
        // showMessage.current({message:'api call', status:'success'})
        //
        //
        // return
        dispatch(
          LoadAction.awardJob({
            load_id: params?.id,
            awarded: payload,
          })
        );
        setAwardLcl([]);
      }
    } else {
      showMessage.current({
        message:
          // "You can not award a recipient without freight ton.",
          `You can not award without ${loadDetail?.price_unit}.`,
        status: "error",
      });
    }
  };




  // useEffect(() => {
  //   if (loadDetail?.contract_id && loadDetail?.total_awarded > 0) {
  //
  //     setIsDeadlineComplete(true)
  //   }
  // }, [loadDetail])

  const renderComplete = () => {
    setIsDeadlineComplete(true);
    // dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
  };

  const addViewer = (data: any) => {
    history.push("/select-recipient/add-viewer", data);
  };

  // useEffect(() => {
  //   if (moment(loadDetail?.deadline_at).format('x') >= moment().format('x')) {
  //     if (!loadDetail?.contract_id) {
  //       setIsDeadlineComplete(false);
  //     }
  //   } else {
  //     setIsDeadlineComplete(true);
  //
  //   }
  // }, [loadDetail])

  const RenderResponseDeadline = ({ date, completed, iconComponent }: any) => {
    if (moment(date).format("x") >= moment().format("x")) {
      if (!loadDetail?.contract_id) {
        setIsDeadlineComplete(false);
      } else if (loadDetail?.contract_id) {
        if (loadDetail?.total_awarded > 0) {
          setIsDeadlineComplete(true);
        } else {
          setIsDeadlineComplete(false);
        }
      }
    } else {
      setIsDeadlineComplete(true);
    }
    return !isDeadlineComplete ? (
      <div
        className="details-Content"
        style={{ marginTop: 0, display: "flex", flexDirection: "column" }}
      >
        <div className="" style={{ paddingRight: 5 }}>
          <p className="customCounterTitle fw-5">
            {`${loadDetail?.transaction_type?.title == "Bid" ? "Bidding" : "Offer"} Response Deadline`}
          </p>
        </div>
        <div>
          <Counter
            iconComponent={iconComponent}
            date={moment(date).toDate()}
            renderComplete={renderComplete}
          />
        </div>
      </div>
    ) : null;
  };

  // download pdf
  const clickDownloadPdf = () => {
    showAccordion();
    if (isBrowser) {
      setTimeout(() => {
        generatePdf();
      }, 1000);
    } else {
      if (sendEventToAndroid("loadDetailPDF")) {
        window.Android.loadDetailPDF(String(params?.id));
      } else if (sendEventToiOS("loadDetailPDF")) {
        window.webkit.messageHandlers.loadDetailPDF.postMessage(
          JSON.stringify({
            url: params?.id,
          })
        );
      }
    }
  };
  const postLoadMeta = useSelector(
    (state: RootState) => state?.Profile?.postLoadMeta
  );
  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const load_type = postLoadMeta?.load_types?.find(
    (val: any) => val?.id === PostLoad?.load_type
  );
  const packageType = postLoadMeta.package_types;
  const inquiry_type = postLoadMeta.inquiry_types;
  const PreferredVehicleType = postLoadMeta?.preferred_vehicles;

  const cloneALoadCallBackLoadHistory = (loadDetailHistory: any) => {
    setShowModalNoti(false);
    const callBack = () => {
      if (!isObjectEmpty(loadDetailHistory)) {
        let data = {};
        if (
          loadDetailHistory.load_type.title === LOAD_TYPE.bulk ||
          loadDetailHistory.load_type.title === LOAD_TYPE.bulk
        ) {
          data = convertDataForFclAndBulkLoadDetailRedux(
            loadDetailHistory,
            packageType,
            load_type,
            inquiry_type,
            PreferredVehicleType,
            "history",
            "Loads Posted"
          );
        } else {
          data = convertDataForLoadDetailRedux(
            loadDetailHistory,
            packageType,
            load_type,
            inquiry_type,
            "history",
            "Loads Posted"
          );
        }

        // return
        if (!isObjectEmpty(data)) {
          dispatch(ProfileAction.postLoadData({ ...data }));
          history.push("/confirm-load", {
            from_load_history: true,
            fromNoti: true,
            loadDetail: loadDetailHistory,
          });
        }
      }
    };
    dispatch(ProfileAction.JobLoadMeta(callBack));

  };

  const [isInteractingWithMap, setIsInteractingWithMap] = useState(false);

  const handleMapInteractionStart = () => setIsInteractingWithMap(true);
  const handleMapInteractionEnd = () => setIsInteractingWithMap(false);

  const handleTouchStart = (event) => {
    const target = event.target.closest(".mapContent");
    if (target) {
      event.stopPropagation(); // Prevent bubbling to PullToRefresh
      handleMapInteractionStart();
    }
  };

  const handleTouchEnd = (event) => {
    const target = event.target.closest(".mapContent");
    if (target) {
      event.stopPropagation(); // Prevent bubbling to PullToRefresh
      handleMapInteractionEnd();
    }
  };

  // const handleRefresh = async () => {
  //   dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
  //   dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
  // };

  const handleRefresh = async () => {
    try {
      // Dispatch actions to load data
      await dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
      await dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));

      // Optionally, show success message or handle any other state after refresh
    } catch (error) {
      // Handle any error that might occur during dispatch
    }
  };

  let notIsDeadline =
    getFullTime(loadDetail?.deadline_at) > getFullTime(new Date());
  let isDeadline =
    getFullTime(new Date()) > getFullTime(loadDetail?.deadline_at);
  if (loadDetail && showDetail) {
    let isRateValidityCheck =
      moment(new Date()).format("x") <
      moment(loadDetail?.rate_validity_date).format("x");
    return (
      <div ref={downloadRef}>
        <TitleLayout
          progressFlag={true}
          progress={0}
          active={toShowAccordion ? false : true}
          titleOnly
          titleOnlyText={`Load ID - ${loadDetail?.id}`}
        >
          {loadDetail?.id &&
            (params?.type == "received" || params?.type === "posted") ? (
            <>

              <PullToRefresh onRefresh={handleRefresh} disabled={isInteractingWithMap}>
                <div className="download-pdf-button-wrapper">
                  {!toShowAccordion && (
                    <OutLineButton
                      classProp="tinyBtn"
                      dataColor="green"
                      title="Export PDF"
                      icon={<span className="icons-export"></span>}
                      onClick={clickDownloadPdf}
                    />
                  )}
                </div>

                <div className="loadDetailWrapper">
                  <div>
                    {/*next step action*/}
                    {loadDetail?.next_action_step && (
                      <div className="error-container info">
                        <div>
                          <InfoIcon className="error-icon" />
                        </div>
                        <div className="error-text">
                          {loadDetail?.next_action_step}
                        </div>
                      </div>
                    )}
                    {/* response deadline  */}
                    {!isDeadlineComplete ? (
                      <div className="three-column-rtl is_deadline_wrapper">
                        <RenderResponseDeadline
                          date={loadDetail?.deadline_at}
                          iconComponent={
                            !isDeadlineComplete ? (
                              <div
                                style={{ width: "5%", paddingLeft: "5px" }}
                                className="three-column-rtl-inner"
                              >
                                <div
                                  onClick={() => {
                                    setDateType(
                                      loadDetail?.deadline_at
                                        ? "Response Deadline"
                                        : null
                                    );
                                    setGenericDate(loadDetail?.deadline_at);
                                    setGenericTime(loadDetail?.deadline_at);
                                    setMinDate(new Date());
                                    setShowModalGeneric(!showModalGeneric);
                                    setMaxDate(
                                      loadDetail?.rate_validity_date
                                        ? loadDetail?.rate_validity_date
                                        : "3050-01-01"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span className="icons-edit"></span>
                                </div>
                              </div>
                            ) : null
                          }
                        />
                      </div>
                    ) : null}

                    {/* poster detail  */}
                    {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                    <Poster
                      loadDetail={loadDetail}
                      expand={expand}
                      setExpand={setExpand}
                      generatePdf={generatePdf}
                      totalContainers={totalContainers}
                      showMessage={true}
                    />
                    {/* </FadeInComponent> */}

                    {/* Active Vehicles Details */}

                    {loadDetail?.active_vehicles?.length > 0 ? (
                      // <FadeInComponent is_apply={toShowAccordion ? false : true}>
                      <div
                        className="activeVehiclesContainer"
                        // onTouchStart={handleMapInteractionStart}
                        // onTouchEnd={handleMapInteractionEnd}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                        onMouseDown={handleMapInteractionStart}
                        onMouseUp={handleMapInteractionEnd}
                        style={{
                          touchAction: "pan-y", // Allow vertical scrolling for accordion
                        }}
                      >
                        <ActiveVehicles
                          loadDetail={loadDetail}
                          expandVehicle={activeExpand}
                          setExpandVehicle={setActiveExpand}
                          fromPoster={true}
                          markersVehicle={vehiclePinPointMarker}
                        />
                      </div>
                    ) : // </FadeInComponent>
                      null}


                    {/* Location Details */}

                    {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                    <LocatonDetail
                      loadDetail={loadDetail}
                      setGenericDate={setGenericDate}
                      setGenericTime={setGenericTime}
                      setMinDate={setMinDate}
                      setShowModalGeneric={setShowModalGeneric}
                      showModalGeneric={showModalGeneric}
                      setDateType={setDateType}
                      type={params?.type}
                      setMaxDate={setMaxDate}
                      ref={locationCompRef}
                    />
                    {/* </FadeInComponent> */}

                    {/* Load Tracking Credentials */}
                    {loadDetail?.tracking_urls?.length > 0 ? (
                      // <FadeInComponent is_apply={toShowAccordion ? false : true}>
                      <div className="accordion-container">
                        <Accordion className="accordion-background">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <p className="fw-5">Load Tracking Credentials</p>
                          </AccordionSummary>

                          <div className="accordion-content">
                            {loadDetail?.tracking_urls?.map((item, index) => {
                              return (
                                <div
                                  style={{
                                    border: "0.5px solid green",
                                    padding: 5,
                                    margin: 5,
                                    borderRadius: 10,
                                  }}
                                >
                                  <RenderRTL
                                    title={"Recipient"}
                                    val={item?.recipient?.name}
                                  />
                                  <RenderRTL
                                    title={"User Name"}
                                    val={item?.username}
                                  />
                                  <RenderRTL
                                    title={"Password"}
                                    val={item?.password}
                                  />
                                  <div
                                    style={{
                                      marginTop: 10,
                                    }}
                                    className="urlFieldWrap"
                                  >
                                    <CustomText.OpenSansRegular>
                                      Url
                                    </CustomText.OpenSansRegular>
                                    <p
                                      className="linkk"
                                      onClick={() => {
                                        if (item?.url.split("")[0] === "w") {
                                          if (isBrowser) {
                                            window.open(
                                              `https://${item?.url}`,
                                              "_blank"
                                            );
                                          }
                                          {
                                            if (
                                              sendEventToAndroid("OpenWebLink")
                                            ) {
                                              window.Android.OpenWebLink(
                                                String(`https://${item?.url}`)
                                              );
                                            } else if (
                                              sendEventToiOS("OpenWebLink")
                                            ) {
                                              window.webkit.messageHandlers.OpenWebLink.postMessage(
                                                JSON.stringify({
                                                  url: `https://${item?.url}`,
                                                })
                                              );
                                            }
                                          }
                                        } else {
                                          if (isBrowser) {
                                            window.open(item?.url, "_blank");
                                          } else {
                                            if (
                                              sendEventToAndroid("OpenWebLink")
                                            ) {
                                              window.Android.OpenWebLink(
                                                String(item?.url)
                                              );
                                            } else if (
                                              sendEventToiOS("OpenWebLink")
                                            ) {
                                              window.webkit.messageHandlers.OpenWebLink.postMessage(
                                                JSON.stringify({
                                                  url: item?.url,
                                                })
                                              );
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      {item?.url}
                                    </p>
                                  </div>
                                  <div style={{ height: 16 }}></div>
                                </div>
                              );
                            })}
                          </div>
                        </Accordion>
                      </div>
                    ) : // </FadeInComponent>
                      null}

                    {/* Payment Terms */}
                    {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                    <PaymentTerms loadDetail={loadDetail} />
                    {/* </FadeInComponent> */}

                    {/* additional detail */}
                    {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                    <AdditionalDetail
                      loadDetail={loadDetail}
                      fromPoster={true}
                      type={params?.type}
                    />
                    {/* </FadeInComponent> */}

                    {/* Transaction type */}
                    {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                    <TransactionType loadDetail={loadDetail} />
                    {/* </FadeInComponent> */}

                    {/* Categioess for qoute type by vehicle */}
                    {loadDetail?.categories?.length ? (
                      <div>
                        {loadDetail?.categories?.map((item, index) => {
                          let final_category = item;
                          let totalAwarded = item?.recipients?.reduce(function (
                            acc,
                            obj
                          ) {
                            return acc + obj.bid?.awarded_no_of_vehicles;
                          }, 0);
                          return (
                            // <FadeInComponent is_apply={toShowAccordion ? false : true}>
                            <div className="accordion-container">
                              <Accordion className="accordion-background">
                                <CategoryCargoHeading
                                  loadDetail={loadDetail}
                                  item={item}
                                  index={index}
                                  categoryLength={
                                    loadDetail?.categories?.length
                                  }
                                />
                                <div className="accordion-content vehicleCategoryThreeGrid">
                                  <ThreeColumnRTL
                                    loadDetail={loadDetail}
                                    item={item}
                                  />
                                  <TwoColumnRTLVehicle
                                    loadDetail={loadDetail}
                                    item={item}
                                    type={params?.type}
                                  />
                                  {loadDetail?.transaction_type?.title ==
                                    "Bid" &&
                                    notIsDeadline && (
                                      <>
                                        {/* <div className="dividing-bordered"></div> */}
                                        <div
                                          className="error-container info mtop8"
                                          style={{ marginBottom: 12 }}
                                        >
                                          <div>
                                            <InfoIcon className="error-icon" />
                                          </div>
                                          <div className="error-text">
                                            Note: You will be able to see the
                                            Bid responses after the response
                                            deadline has passed.
                                          </div>
                                        </div>
                                      </>
                                    )}
                                </div>

                                {/* Render Recipients in each categories */}
                                <div className="accordion-content">
                                  {item?.recipients?.map((item2, index2) => {
                                    return (
                                      <div key={index2}>
                                        <RenderRecipient
                                          item2={item2}
                                          cat_id={item?.id}
                                          total_vehicle={
                                            loadDetail?.is_unit_basis
                                              ? item?.unit_quantity
                                              : item?.vehicle_quantity
                                          }
                                          index={index + 1}
                                          awardIncrement={awardIncrement}
                                          awardDecrement={awardDecrement}
                                          declineByPoster={declineByPoster}
                                          awarded={awarded}
                                          loadDetail={loadDetail}
                                          location={loactions}
                                          final_category={final_category}
                                          preFilledVehicle={preFilledVehicle}
                                          awardIncrementMultiple={
                                            awardIncrementMultiple
                                          }
                                          userRole={params?.type}
                                          isDeadlineComplete={
                                            isDeadlineComplete
                                          }
                                          locationCompRef={locationCompRef}
                                          setAwarded={setAwarded}
                                          awardIncrementOnInput={
                                            awardIncrementOnInput
                                          }
                                          // totalAwarded={totalAwarded}
                                          responded={item2?.bid?.no_of_vehicles}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>

                                {/* award button  */}
                                <div>
                                  {
                                    currentAward === item?.id &&
                                    validateCategoryAward?.entered > validateCategoryAward?.required
                                    || validateRespond?.entered > validateRespond?.responded
                                    && (
                                      <div className="error-container error">
                                        <div>
                                          <InfoIcon className="error-icon" />
                                        </div>
                                        <div className="error-text">
                                          {
                                            loadDetail?.is_unit_basis ? "You can not award Items more than responded" : "You can not award Vehicles more than responded"
                                          }
                                        </div>
                                      </div>
                                    )}
                                  {!loadDetail?.contract_id && (
                                    <>
                                      {loadDetail?.is_container_deposit_by_transporter ||
                                        loadDetail?.is_cross_stuffing_required ||
                                        !loadDetail?.is_allow_partial_load ? null : (
                                        <>
                                          {item?.recipients.filter(
                                            (award_done) =>
                                              award_done.status !== 4
                                          ).length ? (
                                            <div className="accordion-content">
                                              {
                                                // currentAward === item?.id ?
                                                <PrimaryButton
                                                  disabled={
                                                    !awarded?.find((it) => it?.cat_id == item?.id)
                                                    ||
                                                    validateCategoryAward?.entered === 0
                                                    ||
                                                    validateCategoryAward?.entered > validateCategoryAward?.required
                                                    || validateRespond?.entered > validateRespond?.responded
                                                  }
                                                  buttonStyle={{
                                                    height: 44,
                                                    marginBottom: 20,
                                                    marginTop: 20,
                                                  }}
                                                  title="award"
                                                  onClick={award}
                                                />
                                                // :null
                                              }
                                            </div>
                                          ) : null}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </Accordion>
                            </div>
                            // </FadeInComponent>
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        {/* for qoute by weight */}
                        {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                        <div className="accordion-container">
                          <Accordion className="accordion-background">
                            <CategoryCargoHeading loadDetail={loadDetail} />
                            <div className="accordion-content">
                              <ThreeColumnRTL loadDetail={loadDetail} />
                              <TwoColumnRTLWeight
                                loadDetail={loadDetail}
                                type={params?.type}
                              />
                              {loadDetail?.transaction_type?.title == "Bid" &&
                                notIsDeadline && (
                                  <>
                                    <div className="dividing-bordered"></div>
                                    <div className="error-container info">
                                      <div>
                                        <InfoIcon className="error-icon" />
                                      </div>
                                      <div className="error-text">
                                        Note: You will be able to see the Bid
                                        responses after the response deadline
                                        has passed.
                                      </div>
                                    </div>
                                    <div className="dividing-bordered"></div>
                                  </>
                                )}
                            </div>
                            {loadDetail?.transaction_type?.title == "Offer" ||
                              (isDeadline && (
                                <div>
                                  <div style={{ height: 16 }}></div>
                                  <div className="dividing-bordered"></div>
                                </div>
                              ))}

                            {/* Render Recipients */}
                            <div className="accordion-content">
                              {loadDetail?.recipients?.map((item2, index2) => {
                                let copy = JSON.parse(JSON.stringify(item2));
                                return (
                                  <div key={index2}>
                                    <RenderRecipientLCL
                                      item2={item2}
                                      copy_item={copy}
                                      cat_id={loadDetail?.id}
                                      total_vehicle={
                                        loadDetail?.vehicle_quantity
                                      }
                                      index={loadDetail}
                                      awardIncrement={awardIncrement}
                                      awardDecrement={awardDecrement}
                                      declineByPoster={declineByPoster}
                                      awarded={awarded}
                                      loadDetail={loadDetail}
                                      location={loactions}
                                      awardedLCL={awardLcl}
                                      handleChange={hangleChangeFrightTon}
                                      clickCheckboxAward={clickCheckboxAward}
                                      userRole={params?.type}
                                      isDeadlineComplete={isDeadlineComplete}
                                      locationCompRef={locationCompRef}
                                    />
                                  </div>
                                );
                              })}
                            </div>

                            {/* award non-containerized  */}
                            {!loadDetail?.contract_id && (
                              <>
                                {
                                  loadDetail?.is_allow_partial_load && loadDetail?.recipients.filter((award_done) =>
                                    award_done.status !== 4 && award_done?.status !== 0
                                  )?.length
                                    ? (
                                      <div className="accordion-content">
                                        {/* {isRateValidityCheck ? */}
                                        <PrimaryButton
                                          disabled={!awardLcl?.length}
                                          buttonStyle={{ height: 44, marginBottom: 20, marginTop: 20 }}
                                          title="award"
                                          onClick={awardWeightLoad}
                                        />
                                        {/* // : null} */}
                                      </div>
                                    ) : null
                                }

                                {
                                  !loadDetail?.is_allow_partial_load && loadDetail?.total_awarded < 1 && (
                                    <div className="accordion-content">
                                      {/* {isRateValidityCheck ? */}
                                      <PrimaryButton
                                        disabled={!awardLcl?.length}
                                        buttonStyle={{ height: 44, marginBottom: 20, marginTop: 20 }}
                                        title="award"
                                        onClick={awardWeightLoad}
                                      />
                                      {/* : null} */}
                                    </div>
                                  )
                                }
                              </>
                            )}
                            {/* load viewer button non-containerized */}
                          </Accordion>
                        </div>
                        {/* </FadeInComponent> */}
                      </div>
                    )}
                    {/* Documents Uploaded By Recipient */}
                    {loadDetail?.uploads_by_recipients?.length ? (
                      // <FadeInComponent is_apply={toShowAccordion ? false : true}>
                      <UploadedDocumentsRecipient
                        data={loadDetail?.uploads_by_recipients}
                        uploadedBy={"Recipient"}
                        is_poster={true}
                      />
                    ) : // </FadeInComponent>
                      null}

                    {/* Documents Uploaded By Poster  */}
                    {/* <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                    <UploadedDocuments
                      loadDetail={loadDetail}
                      uploadedBy={"You"}
                    />
                    {/* </FadeInComponent> */}

                    <div>
                      <LoadViewer
                        showViewer={loadDetail?.total_awarded > 0}
                        addViewer={addViewer}
                        loadDetail={loadDetail}
                      />
                    </div>

                    {/* award button for is_cross_stuffing and is_container_deposit_by_transporter ON */}
                    <div>
                      {(loadDetail?.is_container_deposit_by_transporter ||
                        loadDetail?.is_cross_stuffing_required ||
                        !loadDetail?.is_allow_partial_load) &&
                        awarded?.length ? (
                        <div className="accordion-content">
                          {/* {isRateValidityCheck ? */}
                          {/* // <FadeInComponent is_apply={toShowAccordion ? false : true}> */}
                          <PrimaryButton
                            disabled={!awarded?.length}
                            buttonStyle={{
                              height: 44,
                              marginBottom: 20,
                              marginTop: 20,
                            }}
                            title="award"
                            onClick={award}
                          />
                          {/* // </FadeInComponent>  */}
                          {/* : null} */}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </PullToRefresh>
            </>
          ) : null}

          {/* date picker modal  */}
          <CustomModal
            showModal={showModalGeneric}
            toggleModal={setShowModalGeneric}
          >
            <div className="DatePickerFieldWrap">
              <MaterialUIPickers
                date={genericDate}
                setDate={(e) => {
                  setGenericDate(e);
                }}
                setTime={(e) => {
                  setGenericTime(e);
                }}
                time={genericTime ? dayjs(genericTime) : null}
                // time={genericTime}
                dateType={dateType}
                minDate={minDate}
                maxDate={maxDate}
                fromTime={dateType === "Response Deadline" ? false : true}
              />
            </div>
            <PrimaryButton
              buttonStyle={{ height: 40, marginTop: 12 }}
              title="Save"
              onClick={() => {
                updateDeadlineResponse();
              }}
            />
          </CustomModal>
        </TitleLayout>
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default Page;
