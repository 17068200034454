import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { getItem } from "@utils/helper";

import { Route, Switch } from "react-router-dom";
import {
  LoginScreen,
  OTPScreen,
  WellcomeScreen,
  VideoScreen,
  UploadCnicScreen,
  RegistrationScreen,
  ReviewDetailScreen,
  SuccessScreen,
  DashBoardScreen,
  TermsScreen,
  SelectVehicle,
  AdditionalDetails,
  LoadTypeScreen,
  HelpScreen,
  ResetPasswordScreen,
  AccountBalanceScreen,
  ForgotPasswordScreen,
  CheckSMSScreen,
  InquiryTypeScreen,
  SignupScreen,
  BidScreen,
  ResponseDeadlineScreen,
  UploadedDocuments,
  OfferScreen,
  UploadDoc,
  SelectRecipient,
  AddReceipient,
  AddReceipientSuccessfully,
  AddLoadSuccessfully,
  SetupPassword,
  MyProfileScreen,
  EditProfileScreen,
  TermsDrawerScreen,
  VehicleScreen,
  LocationsScreen,
  NotificationsScreen,
  LoadDraftScreen,
  ConfirmLoadScreen,
  LoadDetailPoster,
  LoadDetailRecipient,
  AddNetworkScreen,
  AddVehicleScreen,
  AddLocation,
  LoadHistoryScreen,
  FCL_BULK_ADDITIONAL_DETAILS,
  ActiveLoadScreen,
  ContainerReferenceScreen,
  SelectRecipientScreen,
  ContractDetails,
  SelectVehicleFCL,
  ForwardFlowScreen,
  addNewLocationContract,
  addNewLocationSpot,
  CFLocation,
  ContractDetailRecipient,
  ContractDetailPoster,
  RequestVehilce,
  PromotionsScreen,
  PromotionDetailScreen,
  NetworkNewScreen,
  AllNotificationScreen,
  SelectVehicleContract,
  ContractVehicleDisplayNew,
  RequestVehilceLocations,
  RequestVehilceCategoires,
  AddReceipientSuccessfully2,
  HomeScreen,
  BulkAddVehicleScreen,
  MgExpressQuoteScreen,
  ActiveLoadsScreen,
  ReportScreen,
  ViewerDetail,
  NewDashBoardScreen,
  DashBoardVehicleDetailScreen,
  DashBoardLoadDetailScreen



} from "@views";
import { NotFound } from "@components";
import PrivateRoutes from "./PrivateRoutes";
import * as paths from "./paths";
import {
  AddAddress,
  SelectLocation,
  DropoffLocation,
  PaymentTermsScreen,
} from "@temp/views/CreateLoadRequest";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import { StyleGuide } from "@temp/views/StyleGuide";

export const Routes: React.FC = () => {
  const currentToken = getItem("token");
  const history = useHistory();
  const dispatch = useDispatch();

  const handlePushNotifcation = (e: any) => {
    e.preventDefault();
    if (e?.detail) {
      history.push(e?.detail);
    }
  };

  const getProfile = () => {
    dispatch(ProfileAction.GetProfile());
  }

  const updateProfileMobile = (message) => {
    getProfile();
  };

  useEffect(() => {
    window.addEventListener("OnNotificationClick", handlePushNotifcation);
    window.addEventListener("UpdateProfileSucces", updateProfileMobile);
    // return () => {
    //   window.removeEventListener("OnNotificationClick", handlePushNotifcation)
    //   window.removeEventListener("UpdateProfileSucces", updateProfileMobile)
    // }
  }, []);

  return (
    <Switch>
      <Route path={paths.NOT_FOUND} component={NotFound} />
      <Route path={paths.STYLE_GUIDE} component={StyleGuide} />
      <Route
        path={paths.FCL_BULK_ADDITIONAL_DETAILS}
        exact
        component={FCL_BULK_ADDITIONAL_DETAILS}
      />
      <Route
        restricted={true}
        path={paths.CONTRACT_LOCATION}
        exact
        component={CFLocation}
      />
      <Route
        restricted={true}
        path={paths.VIEWER_DETAIL}
        exact
        component={ViewerDetail}
      />
      <Route
        restricted={true}
        path={paths.ALLNOTIFICATION_URL}
        exact
        component={AllNotificationScreen}
      />
      <Route
        restricted={true}
        path={paths.CONTRACT_ADD_NEW_LOCATION}
        exact
        component={addNewLocationContract}
      />

      <Route
        restricted={true}
        path={paths.SPOT_ADD_NEW_LOCATION}
        exact
        component={addNewLocationSpot}
      />

      <Route
        restricted={true}
        path={paths.REQUEST_VEHICLE_LOCATIONS}
        exact
        component={RequestVehilceLocations}
      />

      <Route
        restricted={true}
        path={paths.REQUEST_VEHICLE_CATEGORIES}
        exact
        component={RequestVehilceCategoires}
      />

      <Route
        restricted={true}
        path={paths.SELECT_VEHICLE_CONTRACT_NEW}
        exact
        component={SelectVehicleContract}
      />

      <Route
        restricted={true}
        path={paths.ADD_LOCATION}
        exact
        component={AddLocation}
      />

      <Route
        restricted={true}
        path={paths.PAYMENT_TERMS_URL}
        exact
        component={PaymentTermsScreen}
      />

      <Route
        restricted={true}
        path={paths.ADD_ADDRESS}
        exact
        component={AddAddress}
      />

      <Route
        restricted={true}
        path={paths.POSTED_SUCCESSFULLY}
        exact
        component={AddLoadSuccessfully}
      />
      <Route
        restricted={true}
        path={paths.ADD_RECIPIENT_SUCCESSFULLY}
        exact
        component={AddReceipientSuccessfully}
      />
      <Route
        restricted={true}
        path={paths.ADD_RECIPIENT_SUCCESSFULLY2}
        exact
        component={AddReceipientSuccessfully2}
      />
      <Route
        restricted={true}
        path={paths.ADD_RECIPIENT}
        exact
        component={AddReceipient}
      />
      <Route
        restricted={true}
        path={paths.SELECT_RECIPIENT}
        exact
        component={SelectRecipient}
      />
      <Route
        restricted={true}
        path={paths.Upload_Doc}
        exact
        component={UploadDoc}
      />
      <Route
        restricted={true}
        path={paths.ADDITIONAL_DETAILS}
        exact
        component={AdditionalDetails}
      />
      <Route
        restricted={true}
        path={paths.SELECT_VEHICLE}
        exact
        component={SelectVehicle}
      />
      <Route
        restricted={true}
        path={paths.RESPONSE_DEADLINE}
        exact
        component={ResponseDeadlineScreen}
      />
      <Route
        restricted={true}
        path={paths.UPLOADED_DOCUMENT}
        exact
        component={UploadedDocuments}
      />

      <Route
        restricted={true}
        path={paths.SELECT_LOCATION_DROPOFF}
        exact
        component={DropoffLocation}
      />
      <Route
        restricted={true}
        path={paths.SELECT_LOCATION}
        exact
        component={SelectLocation}
      />
      <Route
        restricted={true}
        path={paths.ADDITIONAL_DETAILS}
        exact
        component={AdditionalDetails}
      />
      <Route
        restricted={true}
        path={paths.SELECT_VEHICLE}
        exact
        component={SelectVehicle}
      />

      <Route
        restricted={true}
        path={paths.MG_EXPRESS_QUOTE}
        exact
        component={MgExpressQuoteScreen}
      />

      <PrivateRoutes
        restricted={true}
        component={ConfirmLoadScreen}
        path={paths.CONFIRM_LOAD_URL}
      />
      <PrivateRoutes
        restricted={true}
        component={BulkAddVehicleScreen}
        path={paths.BULK_ADD_VEHICLE_SCREEN}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.CONTRACT_DETAILS_URL}
        component={ContractDetails}
      />

      <PrivateRoutes
        restricted
        component={HomeScreen}
        path={paths.HOME_URL}
      />

      <PrivateRoutes
        restricted={true}
        component={LoadTypeScreen}
        path={paths.LOAD_TYPE_URL}
      />

      <PrivateRoutes
        restricted={true}
        component={InquiryTypeScreen}
        path={paths.INQUIRY_TYPE_URL}
      />

      <PrivateRoutes
        restricted
        component={DashBoardScreen}
        path={paths.DASHBOARD_URL}
      />
      <PrivateRoutes
        restricted
        component={NewDashBoardScreen}
        path={paths.NEW_DASHBOARD_URL}
      />

      <PrivateRoutes
        restricted
        component={DashBoardLoadDetailScreen}
        path={paths.LOAD_DETAIL_DASHBOARD_URL}
      />

      <PrivateRoutes
        restricted
        component={DashBoardVehicleDetailScreen}
        path={paths.VEHICLE_DETAIL_DASHBOARD_URL}
      />

      <PrivateRoutes
        restricted
        component={LoadTypeScreen}
        path={paths.LOAD_TYPE_URL}
      />

      <PrivateRoutes
        restricted
        path={paths.HELP_URL}
        component={HelpScreen}
      />

      <PrivateRoutes
        restricted
        path={paths.RESET_PASSWORD_URL}
        component={ResetPasswordScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.ACCOUNT_BALANCE_URL}
        component={AccountBalanceScreen}
      />

      <PrivateRoutes
        restricted={true}
        component={ActiveLoadScreen}
        path={paths.ACTIVE_LOAD_URL}
      />

      <Route
        restricted={true}
        path={paths.FORGOT_PASSWORD_URL}
        exact
        component={ForgotPasswordScreen}
      />

      <Route
        restricted={true}
        path={paths.CHECK_SMS_URL}
        exact
        component={CheckSMSScreen}
      />

      <Route
        restricted={true}
        path={paths.SETUP_PASSWORD}
        exact
        component={SetupPassword}
      />

      <PrivateRoutes
        restricted
        component={InquiryTypeScreen}
        path={paths.INQUIRY_TYPE_URL}
      />

      <PrivateRoutes
        restricted={true}
        component={BidScreen}
        path={paths.TRANSACTION_TYPE_BID_URL}
      />

      <PrivateRoutes
        restricted
        component={OfferScreen}
        path={paths.TRANSACTION_TYPE_OFFER_URL}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.MY_PROFILE_URL}
        component={MyProfileScreen}
      />

      <PrivateRoutes
        restricted
        path={paths.EDIT_PROFILE_URL}
        component={EditProfileScreen}
      />

      <PrivateRoutes
        restricted
        path={paths.VEHICLE_URL}
        component={VehicleScreen}
      />

      <PrivateRoutes
        restricted
        path={paths.LOCATIONS_URL}
        component={LocationsScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.LOAD_DETAIL_POSTER_URL}
        component={LoadDetailPoster}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.LOAD_DETAIL_RECIPIENT_URL}
        component={LoadDetailRecipient}
      />

      <PrivateRoutes
        restricted
        path={paths.NOTIFICATIONS_URL}
        component={NotificationsScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.CHANGE_PHONE_OTP_URL}
        component={OTPScreen}
      />

      <PrivateRoutes
        restricted
        component={LoadDraftScreen}
        path={paths.LOAD_DRAFT_URL}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.ADD_NETWORK_URL}
        component={AddNetworkScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.ADD_VEHICLE_URL}
        component={AddVehicleScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.LOAD_HISTORY_URL}
        component={LoadHistoryScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.CONTAINER_REFERNCE_URL}
        component={ContainerReferenceScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.SELECT_RECIPIENT_URL}
        component={SelectRecipientScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.SELECT_VEHICLE_CONTRACT}
        component={SelectVehicleFCL}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.CONTRACT_VEHICLE_DISPLAY_NEW}
        component={ContractVehicleDisplayNew}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.FORWARD_FLOW_URL}
        component={ForwardFlowScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.CONTRACT_DETAIL_RECEPIENT_URL}
        component={ContractDetailRecipient}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.CONTRACT_DETAIL_POSTER_URL}
        component={ContractDetailPoster}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.REQUEST_VEHICLE_URL}
        component={RequestVehilce}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.PROMOTIONS_URL}
        component={PromotionsScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.PROMOTION_DETAIL_URL}
        component={PromotionDetailScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.TERMS_DRAWER_URL}
        component={TermsDrawerScreen}
      />

      <PrivateRoutes
        restricted={true}
        component={NetworkNewScreen}
        path={paths.NETWORK_NEW_SCREEN_URL}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.ACTIVE_LOADS}
        component={ActiveLoadsScreen}
      />

      <PrivateRoutes
        restricted={true}
        path={paths.REPORTS}
        component={ReportScreen}
      />

      {/* public routes  */}
      {currentToken ? (
        <Redirect to={paths.HOME_URL} />
      ) : (
        <Route path={"/"}>
          <Route
            restricted
            path={paths.TERMS_URL}
            exact component={TermsScreen}
          />

          <Route
            restricted={false}
            path={paths.TERMS_DRAWER_URL}
            exact
            component={TermsDrawerScreen}
          />

          <Route
            restricted={false}
            path={paths.BASE_URL}
            exact
            component={WellcomeScreen}
          />

          <Route
            restricted={false}
            path={paths.LOGIN_URL}
            exact
            component={LoginScreen}
          />

          <Route
            restricted={false}
            path={paths.OTP_URL}
            exact
            component={OTPScreen}
          />

          <Route
            restricted={false}
            path={paths.SIGNUP_URL}
            exact
            component={SignupScreen}
          />

          <Route
            restricted={false}
            path={paths.SUCCESS_URL}
            exact
            component={SuccessScreen}
          />

          <Route
            restricted={false}
            path={paths.PROMO_VIDEO_URL}
            exact
            component={VideoScreen}
          />

          <Route
            restricted={false}
            path={paths.UPLOAD_CNIC_URL}
            exact
            component={UploadCnicScreen}
          />

          <Route
            restricted={false}
            path={paths.REGISTRATION_URL}
            exact
            component={RegistrationScreen}
          />

          <Route
            restricted={false}
            path={paths.REVIEW_DETAIL_URL}
            exact
            component={ReviewDetailScreen}
          />

          <Route
            restricted={false}
            path={paths.TERMS_POLICY}
            exact
            component={TermsDrawerScreen}
          />
        </Route>
      )}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
