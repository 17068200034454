import React, { useEffect, useState } from "react";
import { CustomText, OutLineButton, PrimaryButton, SelectionModal } from "@temp/components";
import { Button } from "@mui/material/";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { fileIconDisplay } from "@temp/utils/helper";
import { Delete } from "@mui/icons-material";
import { LoadAction, ProfileAction } from "@temp/store/actions";
import { useDispatch } from "react-redux";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import './styles.scss'
export const UploadDocByRecipient = ({
  handleChangeFile,
  recipient_uploaded,
  set_recipient_uploaded,
  loadDetail,
  uploadFileRecipient
}) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const [showModalMedia, setShowModalMedia] = useState(false);


  const handleUploadImage = (event: any, value: number) => {
    const formData = new FormData();
    formData.append("file", event?.target?.files[0]);
    setShowModalMedia(false)
    dispatch(ProfileAction.uploadFile({ data: formData, cb: uploadFileRecipient }));

    // return
    // var cb = (payload: any) => {
    //   let documents = uploaded_documents
    //   if (Number(value.key) == 1) {
    //     setShowModalMedia(false);
    //   } else {
    //     if (payload?.mime_type) {
    //       setShowModalMedia(false);
    //     } else {
    //       showMessage.current({
    //         message: "Please upload File.",
    //         status: "error",
    //       });
    //       return;
    //     }
    //   }
    //   if (selectedDocumentType.key === "bill_of_ladings") {
    //     documents.bill_of_ladings = [...documents.bill_of_ladings, payload];
    //   } else if (selectedDocumentType.key === "packing_lists") {
    //     documents.packing_lists = [...documents.packing_lists, payload];
    //   } else {
    //     let isNameAlreadyExist = documents.other_uploads.find(
    //       (val: any) => val.title === otherType
    //     );

    //     if (isNameAlreadyExist) {
    //       let indexOfArray = documents.other_uploads.findIndex(
    //         (val: any) => val.title === otherType
    //       );
    //       documents.other_uploads[indexOfArray].files = [
    //         payload,
    //         ...documents.other_uploads[indexOfArray].files,
    //       ];
    //     } else {
    //       documents.other_uploads = [
    //         ...documents.other_uploads,
    //         { title: otherType, files: [payload] },
    //       ];
    //     }
    //   }
    //   set_uploaded_documents(documents)
    //   setHello([])
    // };
    // dispatch(ProfileAction.uploadFile({ data: formData, cb }));
  };

  const onClickMediaType = (values: any) => { setShowModalMedia(false) };
  const handleCamrea = (data) => {
    if (sendEventToAndroid("accessCamera")) {
      window.Android.accessCamera(
        String("access")
      );
    } else if (sendEventToiOS("accessCamera")) {
      window.webkit.messageHandlers.accessCamera.postMessage(
        JSON.stringify({
          access: "access",
        })
      );
    } else {
    }
  }

  const handleDoc = (data) => {
    if (sendEventToAndroid("accessDoc")) {
      window.Android.accessDoc(
        String("access-doc")
      );
    } else if (sendEventToiOS("accessDoc")) {
      window.webkit.messageHandlers.accessDoc.postMessage(
        JSON.stringify({
          access: "access-doc",
        })
      );
    } else {
    }
  }

  const handleGallery = (data) => {
    if (sendEventToAndroid("accessGallery")) {
      window.Android.accessGallery(
        String("access-gallery")
      );
    } else if (sendEventToiOS("accessGallery")) {
      window.webkit.messageHandlers.accessGallery.postMessage(
        JSON.stringify({
          access: "access-gallery",
        })
      );
    } else {
    }
  }

  const callBack2 = (payload) => {
    let new_payload = {
      file_name: payload?.fileName,
      cloudinary_url: payload?.cloudinaryUrl,
      file_url: payload?.fileUrl,
      mime_type: payload?.mimeType
    }
    uploadFileRecipient(new_payload)
    setShowModalMedia(false);
  }

    useEffect(() => {
      const target = message => {
        callBack2(JSON.parse(message?.detail))
      }
      window.addEventListener("AudioUploadsuccess", target);
      return () => {
        window.removeEventListener("AudioUploadsuccess", (e) => { })
      }
    }, [])

  return (
    <div>
      <div style={{
        paddingTop: 10,
        marginBottom: 15,
      }}>
        <div className="uploadDocumentFileBoxWrap">
          <div className="documentWrapper">
            <div className="doc-title" style={{ display: "flex", marginBottom: 20 }}>
              <div className={`docTitle`}>Upload Document</div>
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              flexDirection: 'column'
            }}>
              <label style={{
              }} htmlFor="upload-nic-front" id="upload-button-container" style={{ width: '100%' }}>
                <Button
                  onClick={() => {
                    setShowModalMedia(true)
                  }}
                  variant="contained"
                  className="outline-button customUploadButton"
                  component="span"
                  style={{
                    height: 44,
                    width: '100%',
                    border: `1px solid ${Colors.Primary}`,
                    color: Colors.Primary,
                    flexDirection: 'row'
                  }}
                >
                  <span className="button-title" >Upload File</span>
                  <span className="seperator">/</span>
                  <span className="urduLabel">اپ لوڈ فائل</span>
                </Button>
              </label>
              {/* <input
                className="hide-input"
                id="upload-nic-front"
                multiple
                type="file"
                onChange={handleChangeFile}
              /> */}
            </div>
            {/* <div style={{
              paddingLeft:20,
              paddingRight:20
            }}>
              {
                recipient_uploaded?.map((file, index) => {
                  return (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop:10
                    }}>
                      <div
                        onClick={() => {
                          if (sendEventToAndroid("UploadedDocuments")) {
                            window.Android.UploadedDocuments(
                              file?.path
                            );
                          } else if (sendEventToiOS("UploadedDocuments")) {
                            window.webkit.messageHandlers.UploadedDocuments.postMessage(
                              JSON.stringify({
                                document: file?.path,
                              })
                            );
                          } else {
                            window.open(file?.path, "_blank");
                          }
                        }}
                        className="fileWrapper"
                      >
                        <img
                          className="fileTypeIcon"
                          src={fileIconDisplay(file?.mime_type)}
                        ></img>

                        <div className="fileName">{file?.file_name}</div>
                      </div>
                      <span
                        className="deleteIcon icons-cross"
                        onClick={() => {
                          let new_data = [...recipient_uploaded]
                          new_data.splice(index, 1)
                          set_recipient_uploaded(new_data)
                        }}
                      ></span>
                    </div>
                  )
                })
              }
            </div> */}
            {/* <div style={{ marginTop: 15 }}>
              <PrimaryButton
                title={"upload"}
                disabled={!recipient_uploaded.length}
                onClick={() => {
                  let payload = {
                    load_id: loadDetail?.id,
                    documents: recipient_uploaded
                  }
                  dispatch(LoadAction.uploadDocRecipient(payload))
                  set_recipient_uploaded([])
                }}
              />
            </div> */}
          </div>
        </div>
      </div>
      <SelectionModal
        showModal={showModalMedia}
        title={"Media"}
        type="media"
        handleUploadImage={handleUploadImage}
        data={[
          {
            key: 0,
            iconClass: 'icons-camera',
            title: "Camera",
          },

          {
            key: 1,
            iconClass: 'icons-gallery',
            title: "Gallery",
          },
          {
            key: 2,
            iconClass: 'icons-document',
            title: "Document",
          }
        ]}
        onClick={onClickMediaType}
        handleModal={(value: boolean) => {
          setShowModalMedia(value);
        }}
        handleCamrea={handleCamrea}
        handleDoc={handleDoc}
        handleGallery={handleGallery}
      />
    </div>
  );
};