import { PrimaryButton, OutLineButton, SimpleInput, SimpleCheckBox, CustomModal, PhoneInput } from "@temp/components";
import React, { FC, createRef, useEffect, useRef, useState } from "react";
import RoomIcon from "@mui/icons-material/Room";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
} from "@mui/material/";
import { useSelector, useDispatch } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import { useHistory } from "react-router";
import TitleLayout from "@temp/components/TitleLayout";
import { DrawerAction } from "@temp/store/actions";
import { ExpandMore } from "@mui/icons-material";
import { preventNonNumericalInput, showMessage, validateNameNumber } from "@temp/utils/helper";
import { LOAD_TYPE } from "@temp/constants";
import InfoIcon from "@mui/icons-material/Info";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { log } from "console";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";

const initial_pickup_and_dropoff = {
	address: "",
	lat: null,
	lng: null,
	title: "",
	description: "",
	timestamp: null,
	contact_name: "",
	contact_phone_no: "",
	contact_phone_code: "92",
	pickup_accordion: false,
	dropoff_accordion: false
}

export const addNewLocationSpot: FC = (props: any) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
	const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
	const default_address = postLoadMeta?.load_configurations?.default_address_setting
	const [is_empty_return, setEmpty] = useState(false);
	const [empty_return_yard_name, setYard] = useState("");
	const [searchAddress, setSearchAddress] = useState("");
	const [searchAddressDrop, setSearchAddressDrop] = useState("");
	const [info, setInfo] = useState("");
	const [showModal, toggleModal] = useState(false);
	const load_type = postLoadMeta.load_types.find((val: any) => val.id === PostLoad.load_type);
	const [pickups_locations, set_pickups_locations] = useState([])
	const [dropoffs_locations, set_dropoffs_locations] = useState([])

	const handlePaste = (e, index) => {
		e.preventDefault();

		let pasteData = e.clipboardData.getData('text');
		pasteData = pasteData.replace(/\D/g, '');
		if (pasteData.length > 10) {
			pasteData = pasteData.slice(0, 10);
		}

		let temp_obj = {
			...pickups_locations[index],
			contact_phone_no: pasteData
		};
		let change_data = [...pickups_locations];
		change_data[index] = temp_obj;
		if (pasteData.startsWith('0') || pasteData.startsWith('92')) {
			return;
		}
		set_pickups_locations(change_data);
	};

	const handlePasteDrop = (e, index) => {
		e.preventDefault();

		let pasteData = e.clipboardData.getData('text');
		pasteData = pasteData.replace(/\D/g, '');
		if (pasteData.length > 10) {
			pasteData = pasteData.slice(0, 10);
		}

		let temp_obj = {
			...dropoffs_locations[index],
			contact_phone_no: pasteData
		};
		let change_data = [...dropoffs_locations];
		change_data[index] = temp_obj;
		if (pasteData.startsWith('0') || pasteData.startsWith('92')) {
			return;
		}
		set_dropoffs_locations(change_data);
	};

	const validateInput = (value) => {
		// Prevent input starting with 0, 92, or a hyphen
		const invalidPatterns = /^(0|92|-)/;
		return !invalidPatterns.test(value);
	};

	useEffect(() => {
		let pickup_data = []
		let dropoff_data = []
		if (PostLoad?.location?.length) {
			pickup_data[0] = PostLoad?.location[0]
			dropoff_data[0] = PostLoad?.location[1]
			if (PostLoad?.additional_pickups.length) {
				pickup_data = [...pickup_data, ...PostLoad?.additional_pickups]
			}
			if (PostLoad?.additional_dropoffs.length) {
				dropoff_data = [...dropoff_data, ...PostLoad?.additional_dropoffs]
			}
		} else {
			let default_pickup = {
				address: default_address?.pickup?.raw_address || "",
				lat: default_address?.pickup?.lat || null,
				lng: default_address?.pickup?.long || null,
				title: default_address?.pickup?.title || "",
				description: default_address?.pickup?.description || "",
				timestamp: null,
				contact_name: default_address?.pickup?.contact_name || "",
				contact_phone_no: default_address?.pickup?.contact_phone_no || "",
				contact_phone_code: '92',
				pickup_accordion: false,
				city: default_address?.pickup?.city || ""

			}
			let default_dropoff = {
				address: default_address?.dropoff?.raw_address || "",
				lat: default_address?.dropoff?.lat || null,
				lng: default_address?.dropoff?.long || null,
				title: default_address?.dropoff?.title || "",
				description: default_address?.dropoff?.description || "",
				timestamp: null,
				contact_name: default_address?.dropoff?.contact_name || "",
				contact_phone_no: default_address?.dropoff?.contact_phone_no || "",
				contact_phone_code: '92',
				dropoff_accordion: false,
				city: default_address?.dropoff?.city || ""

			}
			pickup_data.push(default_pickup)
			dropoff_data.push(default_dropoff)
		}
		set_pickups_locations(pickup_data)
		set_dropoffs_locations(dropoff_data)

		if (PostLoad?.is_empty_return) {
			setEmpty(true)
		} else {
			setEmpty(false)
		}
		if (PostLoad?.empty_return_yard_name) {
			setYard(PostLoad?.empty_return_yard_name)
		}

	}, [])

	// pickup for city (stable)

	const getCity = (place) => {
		for (const component of place.address_components) {
			if (component.types.includes("locality")) {
				return component.long_name;
			}
		}
		return "";
	};

	const handleAutoComplete = (e, location, index) => {
		var element = document.getElementById(`map-address${index}`) as HTMLInputElement;
		let temp_obj = {
			...location,
			lat: null,
			lng: null,
			address: e.target.value,
			city: null
		}
		let change_data = [...pickups_locations]
		change_data[index] = temp_obj
		set_pickups_locations(change_data)

		if (element) {
			const options = {
				componentRestrictions: { country: "pk" },
				fields: ["address_components", "geometry", "icon", "name"],
				strictBounds: false,
				types: ["establishment"],
			};
			const autocomplete = new window.google.maps.places.Autocomplete(
				element,
				options
			);

			autocomplete.addListener("place_changed", () => {

				const place = autocomplete.getPlace();
				const new_address = document.querySelector(`#map-address${index}`)?.value;

				if (place) {
					const city = getCity(place);
					let temp_obj_2 = {
						...location,
						city: city, // Add the city to the object
					};

					if (place.geometry && place.geometry.location) {
						const coordinates = {
							lat: place.geometry.location.lat(),
							lng: place.geometry.location.lng(),
						};
						const lat = coordinates?.lat;
						const lng = coordinates?.lng;
						const raw_address = new_address;
						temp_obj_2 = {
							...temp_obj_2,
							lat: lat,
							lng: lng,
							address: raw_address,
							city: city
						};
					}

					let change_data_2 = [...pickups_locations];
					change_data_2[index] = temp_obj_2;
					set_pickups_locations(change_data_2);
				}
			});
		}
	};

	const handleManualAddressInput = (e, location, index) => {
		var element = document.getElementById(`map-address${index}`) as HTMLInputElement;

		if (element) {
			const manual_address = element.value.trim();

			// Reset city and clear location details
			let temp_obj_2 = {
				...location,
				city: "", // Ensure city is explicitly set to empty string
				lat: null,
				lng: null,
				address: e.target.value ?? "", // Clear address
			};

			let change_data_2 = [...pickups_locations];
			change_data_2[index] = temp_obj_2;
			set_pickups_locations(change_data_2);
			
		}
	};

	//drop off city (stable)

	const handleAutoCompleteDrop = (e, location, index) => {
		var element = document.getElementById(`map-address-drop${index}`) as HTMLInputElement;
		let temp_obj = {
			...location,
			lat: null,
			lng: null,
			address: e.target.value,
			city: null
		}
		let change_data = [...dropoffs_locations]
		change_data[index] = temp_obj
		set_dropoffs_locations(change_data)

		if (element) {
			const options = {
				componentRestrictions: { country: "pk" },
				fields: ["address_components", "geometry", "icon", "name"],
				strictBounds: false,
				types: ["establishment"],
			};
			const autocomplete = new window.google.maps.places.Autocomplete(
				element,
				options
			);

			autocomplete.addListener("place_changed", () => {

				const place = autocomplete.getPlace();
				const new_address = document.querySelector(`#map-address-drop${index}`)?.value;

				if (place) {
					const city = getCity(place);
					let temp_obj_2 = {
						...location,
						city: city, // Add the city to the object
					};

					if (place.geometry && place.geometry.location) {
						const coordinates = {
							lat: place.geometry.location.lat(),
							lng: place.geometry.location.lng(),
						};
						const lat = coordinates?.lat;
						const lng = coordinates?.lng;
						const raw_address = new_address;
						temp_obj_2 = {
							...temp_obj_2,
							lat: lat,
							lng: lng,
							address: raw_address,
							city: city
						};
					}

					let change_data_2 = [...dropoffs_locations];
					change_data_2[index] = temp_obj_2;
					set_dropoffs_locations(change_data_2);
				}
			});
		}
	};

	const handleManualAddressInputDrop = (e, location, index) => {
		var element = document.getElementById(`map-address-drop${index}`) as HTMLInputElement;

		if (element) {
			const manual_address = element.value.trim();

			if (manual_address === '') {
				// Reset city and clear location details
				let temp_obj_2 = {
					...location,
					city: "", // Ensure city is explicitly set to empty string
					lat: null,
					lng: null,
					address: "", // Clear address
				};

				let change_data_2 = [...dropoffs_locations];
				change_data_2[index] = temp_obj_2;
				set_dropoffs_locations(change_data_2);
				return;
			}

			// Geocode API call for manual address
			const geocoder = new window.google.maps.Geocoder();
			geocoder.geocode({ address: manual_address, componentRestrictions: { country: "pk" } }, (results, status) => {
				if (status === "OK" && results[0]) {
					const place = results[0];
					const city = getCity(place);

					let temp_obj_2 = {
						...location,
						city: city || "", // Update the city name retrieved from manual input or set to empty string if not found
					};

					if (place.geometry && place.geometry.location) {
						const coordinates = {
							lat: place.geometry.location.lat(),
							lng: place.geometry.location.lng(),
						};
						const lat = coordinates.lat;
						const lng = coordinates.lng;
						const raw_address = manual_address;
						temp_obj_2 = {
							...temp_obj_2,
							lat: lat,
							lng: lng,
							address: raw_address,
						};
					} else {
						// Clear location details if no geometry is found
						temp_obj_2 = {
							...temp_obj_2,
							lat: null,
							lng: null,
							address: manual_address,
						};
					}

					let change_data_2 = [...dropoffs_locations];
					change_data_2[index] = temp_obj_2;
					set_dropoffs_locations(change_data_2);
				} else {

					// Optionally, reset the city name or display an error message to the user
					let temp_obj_2 = {
						...location,
						city: "", // Reset city name
						lat: null,
						lng: null,
						address: "", // Clear address
					};

					let change_data_2 = [...dropoffs_locations];
					change_data_2[index] = temp_obj_2;
					set_dropoffs_locations(change_data_2);
				}
			});
		}
	};

	// end code

	const addAdditionalPickup = () => {
		let pickup_data = [...pickups_locations]
		pickup_data.push(initial_pickup_and_dropoff)
		set_pickups_locations(pickup_data)
	}

	const addAdditionalDropoff = () => {
		let pickup_data = [...dropoffs_locations]
		pickup_data.push(initial_pickup_and_dropoff)
		set_dropoffs_locations(pickup_data)
	}

	const deleteLocation = (index, type) => {
		if (type == 'pickup') {
			let data = [...pickups_locations]
			data.splice(index, 1)
			set_pickups_locations(data)
		} else {
			let data = [...dropoffs_locations]
			data?.splice(index, 1)
			set_dropoffs_locations(data)
		}
	}
	const [isFocused, setIsFocused] = useState(false);

	return (
		<TitleLayout
			pageNumber={6}
			progress={60}
			title={`Add Location - ${load_type?.title}`}
			screen="addAddress"
		>
			<div className="formFeildWrapper addressPageWrapper">
				{/* pickup locations  */}
				<div>
					{/* pickups location render  */}
					<div>
						{
							pickups_locations?.map((location, index) => {
								return (
									<div key={index} className="sectionBox">
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between'
										}}>
											<p className="titleSection">Pickup Location {index + 1}</p>
											{
												index > 0 && (
													<div onClick={() => {
														deleteLocation(index, 'pickup')
													}} className="icons-cross active" style={{
														width: 25,
														height: 25,
														cursor: 'pointer',
														display: 'flex',
														justifyContent: 'center'
													}}>
													</div>
												)
											}
										</div>
										{
											[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length ?
												<div className="addressAccordion" style={{ width: "100%", overflowY: "scroll", marginBottom: 10 }} >
													<Accordion
														expanded={location?.pickup_accordion}
														onChange={() => {
															let change_data = [...pickups_locations]
															change_data[index] = { ...location, pickup_accordion: !location?.pickup_accordion }
															set_pickups_locations(change_data)
															// set_pickup_accordion(!pickup_accordion)
														}
														}
													>
														<AccordionSummary
															expandIcon={<ExpandMore />}
															aria-controls="panel1a-content"
															id="panel-header"
														>
															<label className="fw-5">Select From Saved Addresses</label>
														</AccordionSummary>
														<AccordionDetails>
															<div className={`faqas-item`} style={{ width: '100%' }}>
																<>
																	{
																		[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length > 1 ?
																			<div className="searchContainer" style={{ marginBottom: '1rem' }} >
																				<input
																					className="searchInput"
																					onChange={(e) => setSearchAddress(e.target.value)}
																					type="text"
																					name="search"
																					value={searchAddress}
																					placeholder="Search by name"
																				/>
																				<span className={searchAddress ? 'icons-cross' : 'icons-search'} onClick={() => {
																					setSearchAddress("");
																				}}></span>
																			</div> : null
																	}
																	<div>
																		{[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].filter((item) =>
																			item?.title
																				?.toLowerCase()
																				.includes(searchAddress.toLowerCase())
																		).sort((a, b) => {
																			if (a?.title?.toLowerCase().indexOf(searchAddress.toLowerCase()) > b?.title?.toLowerCase().indexOf(searchAddress.toLowerCase())) {
																				return 1;
																			} else if (a?.title?.toLowerCase().indexOf(searchAddress.toLowerCase()) < b?.title?.toLowerCase().indexOf(searchAddress.toLowerCase())) {
																				return -1;
																			} else {
																				if (a.title > b.title)
																					return 1;
																				else
																					return -1;
																			}
																		})?.map((val, ind) => {
																			return (
																				<Button
																					key={ind}
																					onClick={() => {
																						const { raw_address, long, lng, lat, title, description, contact_name, contact_phone_no, city } = val;

																						let temp_obj = {
																							...location,
																							address: raw_address,
																							lat: lat,
																							lng: long,
																							title: title,
																							timestamp: null,
																							description: description || "",
																							contact_phone_code: '92',
																							contact_name: contact_name,
																							contact_phone_no: contact_phone_no,
																							pickup_accordion: false,
																							city: city || ""


																						}
																						let change_data = [...pickups_locations]
																						change_data[index] = temp_obj
																						set_pickups_locations(change_data)
																						setSearchAddress("");
																					}}
																					className="locationItem"
																				>
																					<RoomIcon className="icon" />
																					<label className="title">
																						{val?.title || ""}
																					</label>
																				</Button>
																			)
																		})
																		}
																	</div>
																</>
															</div>
														</AccordionDetails>
													</Accordion>
												</div> : null

										}
										<div className="inputField">
											<div className="field">
												<div className={`input-container-new ${location?.title ? 'filled' : ''}`}>
													<input
														className="new-inut-field"
														onChange={(e) => {
															let temp_obj = {
																...location,
																title: e.target.value
															}
															let change_data = [...pickups_locations]
															change_data[index] = temp_obj
															set_pickups_locations(change_data)
														}}
														type="text"
														autoComplete="off"
														name="search"
														value={location?.title}
														placeholder="Enter your pickup location name"
														maxLength={30}
													/>
													<span className="inputSlide"></span>
												</div>
											</div>
										</div>
										<div className="searchContainer" style={{ width: "100%", marginBottom: '1rem' }}>
											<input
												className="searchInput"
												id={`map-address${index}`}
												onChange={(e) => {
													handleAutoComplete(e, location, index)
												}}
												type="text"
												autoComplete="off"
												name="search"
												style={{ width: "100%" }}
												value={location?.address}
												placeholder="Enter detailed address"
												maxLength={100}
											/>
											<span className={`icons-search ${!location?.address?.length ? 'active' : ''}`}></span>
											<span
												className={`icons-cross ${location?.address?.length ? 'active' : ''}`}
												onClick={() => {
													let change_data = [...pickups_locations]
													if (location?.address) {
														let clearAdress = {
															...location,
															address: "",
															city: "",
															lat: "",
															lng: ""

														}
														change_data[index] = clearAdress
														set_pickups_locations(change_data)
													}
												}}>
											</span>
										</div>
										<div className="inputField mtop8">
											<div className="label">Contact Name <InfoIcon onClick={() => {
												setInfo('Contact information will only be visible when load is awarded')
												toggleModal(true)
											}} /></div>
											<div className="field">
												<SimpleInput
													type="text"
													value={location?.contact_name}
													onChange={(e) => {
														let temp_obj = {
															...location,
															contact_name: e.target.value
														}
														let change_data = [...pickups_locations]
														change_data[index] = temp_obj
														set_pickups_locations(change_data)
													}}
													placeholder="Enter Contact Name"
												/>
											</div>
										</div>
										<div className="inputField driverNumberField">
											<div className="label">Contact Number</div>
											<div className="field">
												{/* <PhoneInput
													value={location?.contact_phone_no}
													phoneCode={location?.contact_phone_code}
													onChange={(e) => {
														if (e.target.value.length < 11) {
															let temp_obj = {
																...location,
																contact_phone_no: e.target.value
															}
															let change_data = [...pickups_locations]
															change_data[index] = temp_obj
															set_pickups_locations(change_data)
														}
													}}
													id="phone-number"
													placeholder={"3000000000"}
												/> */}
												<SimplePhoneInput
													value={location?.contact_phone_no}
													onChange={(e) => {
														const { name, value } = e.target;
														if (validateInput(value) || value === '') {
															if (e.target.value.length < 11) {
																let temp_obj = {
																	...location,
																	contact_phone_no: e.target.value
																}
																let change_data = [...pickups_locations]
																change_data[index] = temp_obj
																set_pickups_locations(change_data)
															}
														}
													}}
													onPaste={(e) => handlePaste(e, index)}
													placeholder="3000000000"
													maxLength={10}
													id="phone-number"
													index={index}
												/>

											</div>
										</div>
									</div>
								)
							})
						}
					</div>

					{/* add additional pickup button  */}
					<OutLineButton
						type={"submit"}
						buttonStyle={{ marginBottom: "1rem", }}
						title="Additional Pickup Location"
						disabled={pickups_locations.every(item => item.title) ? false : true}
						onClick={() => {
							addAdditionalPickup()
						}}
					/>

					{/* add to address button  */}
					<OutLineButton
						type={"submit"}
						title="Add to address book"
						buttonStyle={{ marginBottom: "1rem", }}
						disabled={
							![...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff]?.find(item => item?.raw_address == pickups_locations[pickups_locations.length - 1]?.address && item?.title == pickups_locations[pickups_locations.length - 1]?.title)?.id && pickups_locations[pickups_locations.length - 1]?.address && pickups_locations[pickups_locations.length - 1]?.title
								? false
								: true
						}
						onClick={() => {
							if (validateNameNumber(pickups_locations[pickups_locations.length - 1]?.contact_name, pickups_locations[pickups_locations.length - 1]?.contact_phone_no)) {
								dispatch(
									DrawerAction.addLocation({
										data: { ...pickups_locations[pickups_locations.length - 1], type: 'pickup', raw_address: pickups_locations[pickups_locations.length - 1]?.address, model_name: 'user', lat: pickups_locations[pickups_locations.length - 1]?.lat ,  lng: pickups_locations[pickups_locations.length - 1]?.lng },
									})
								);
							}
						}}
					/>
				</div>

				{/* dropoff locations  */}
				<div>
					<FadeInComponent>
						{/* dropoffs location render  */}
						<div>
							{
								dropoffs_locations.map((location, index) => {
									return (
										<div key={index} className="sectionBox">
											<div style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}>
												<p className="titleSection">Drop-off Location {index + 1} </p>
												{
													index > 0 && (
														<div onClick={() => {
															deleteLocation(index, 'dropoff')
														}} className="icons-cross active" style={{
															width: 25,
															height: 25,
															cursor: 'pointer',
															display: 'flex',
															justifyContent: 'center'
														}}>
														</div>
													)
												}
											</div>

											{
												[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length ?
													<div className="addressAccordion" style={{ width: "100%", overflowY: "scroll", marginBottom: 10 }}>
														<Accordion
															expanded={location?.dropoff_accordion}
															onChange={() => {
																let change_data = [...dropoffs_locations]
																change_data[index] = { ...location, dropoff_accordion: !location?.dropoff_accordion }
																set_dropoffs_locations(change_data)
															}}
														>
															<AccordionSummary
																expandIcon={<ExpandMore />}
																aria-controls="panel1a-content"
																id="panel-header"
															>
																<label className="fw-5">Select From Saved Addresses</label>
															</AccordionSummary>
															<AccordionDetails>
																<div className={`faqas-item`} style={{ width: '100%' }}>
																	<>
																		{
																			[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].length > 1 ?
																				<div className="searchContainer" style={{ width: "100%" }}>
																					<input
																						className="searchInput"
																						onChange={(e) => setSearchAddressDrop(e.target.value)}
																						type="text"
																						name="search"
																						value={searchAddressDrop}
																						placeholder="Search by name"
																						style={{ width: "100%" }}
																					/>
																					<span className={searchAddressDrop ? 'icons-cross' : 'icons-search'} onClick={() => {
																						setSearchAddressDrop("");
																					}}></span>
																				</div> : null
																		}

																		<div >
																			{[...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff].filter((item) =>
																				item?.title?.toLowerCase()
																					.includes(searchAddressDrop.toLowerCase())

																			)?.sort((a, b) => {
																				if (a?.title?.toLowerCase().indexOf(searchAddressDrop.toLowerCase()) > b?.title?.toLowerCase().indexOf(searchAddressDrop.toLowerCase())) {
																					return 1;
																				} else if (a?.title?.toLowerCase().indexOf(searchAddressDrop.toLowerCase()) < b?.title?.toLowerCase().indexOf(searchAddressDrop.toLowerCase())) {
																					return -1;
																				} else {
																					if (a.title > b.title)
																						return 1;
																					else
																						return -1;
																				}
																			})?.map((val, ind) => {
																				return (
																					<Button
																						key={ind}
																						onClick={() => {
																							const { raw_address, long, lat, title, description, contact_name, contact_phone_no, city } = val;
																							let temp_obj = {
																								...location,
																								address: raw_address,
																								lat: lat,
																								lng: long,
																								title: title,
																								timestamp: null,
																								description: description || "",
																								contact_phone_code: '92',
																								contact_name: contact_name,
																								contact_phone_no: contact_phone_no,
																								dropoff_accordion: false,
																								city: city || ""
																							}
																							let change_data = [...dropoffs_locations]
																							change_data[index] = temp_obj
																							set_dropoffs_locations(change_data)
																							setSearchAddressDrop("");
																						}}
																						className="locationItem"
																					>
																						<RoomIcon className="icon" />
																						<label className="title">
																							{val?.title || ""}
																						</label>
																					</Button>
																				)
																			})
																			}
																		</div>
																	</>
																</div>
															</AccordionDetails>
														</Accordion>
													</div> : null
											}

											<div className="inputField">
												<div className="field">
													<div className={`input-container-new ${location?.title ? 'filled' : ''}`}>
														<input
															className="new-inut-field"
															onChange={(e) => {
																let temp_obj = {
																	...location,
																	title: e.target.value
																}
																let change_data = [...dropoffs_locations]
																change_data[index] = temp_obj
																set_dropoffs_locations(change_data)
															}}
															type="text"
															autoComplete="off"
															name="search"
															style={{ width: "100%" }}
															value={location?.title}
															placeholder="Enter your dropoff location name"
															maxLength={30}
														/>
														<span className="inputSlide"></span>
													</div>
												</div>
											</div>

											<div className="searchContainer" style={{ width: "100%" }}>
												<input
													className="searchInput"
													id={`map-address-drop${index}`}
													onChange={(e) => {
														handleAutoCompleteDrop(e, location, index)
													}}
													type="text"
													autoComplete="off"
													name="search"
													style={{ width: "100%" }}
													value={location?.address}
													placeholder="Enter detailed address"
													maxLength={100}
												/>
												<span className={`icons-search ${!location?.address?.length ? 'active' : ''}`}></span>
												<span
													className={`icons-cross ${location?.address?.length ? 'active' : ''}`}
													onClick={() => {
														let change_data = [...dropoffs_locations]
														if (location?.address) {
															let clearAdress = {
																...location,
																address: "",
																city: "",
																lat: "",
																lng: ""
															}
															change_data[index] = clearAdress
															set_dropoffs_locations(change_data)
														}
													}}>
												</span>
											</div>
											<div className="inputField mtop8">
												<div className="label">Contact Name <InfoIcon onClick={() => {
													setInfo('Contact information will only be visible when load is awarded')
													toggleModal(true)
												}} /></div>
												<div className="field">
													<SimpleInput
														type="text"
														value={location?.contact_name}
														onChange={(e) => {
															let temp_obj = {
																...location,
																contact_name: e.target.value
															}
															let change_data = [...dropoffs_locations]
															change_data[index] = temp_obj
															set_dropoffs_locations(change_data)
														}}
														placeholder="Enter Contact Name"
													/>
												</div>
											</div>
											<div className="inputField driverNumberField">
												<div className="label">Contact Number</div>
												<div className="field">
													{/* <PhoneInput
														value={location?.contact_phone_no}
														phoneCode={location?.contact_phone_code}
														onChange={(e) => {
															if (e.target.value.length < 11) {
																let temp_obj = {
																	...location,
																	contact_phone_no: e.target.value
																}
																let change_data = [...dropoffs_locations]
																change_data[index] = temp_obj
																set_dropoffs_locations(change_data)
															}
														}}
														id="phone-number"
														placeholder={"3000000000"}
													/> */}

													<SimplePhoneInput
														value={location?.contact_phone_no}
														onChange={(e) => {
															const { name, value } = e.target;
															if (validateInput(value) || value === '') {
																if (e.target.value.length < 11) {
																	let temp_obj = {
																		...location,
																		contact_phone_no: e.target.value
																	}
																	let change_data = [...dropoffs_locations]
																	change_data[index] = temp_obj
																	set_dropoffs_locations(change_data)
																}
															}
														}}
														onPaste={(e) => handlePasteDrop(e, index)}
														placeholder="3000000000"
														maxLength={10}
														id="phone-number"
														index={index}
													/>
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
					</FadeInComponent>
					{/* add additional dropoff button */}
					<FadeInComponent>
						<OutLineButton
							type={"submit"}
							title="Additional Dropoff Location"
							buttonStyle={{ marginBottom: "1rem", }}
							disabled={dropoffs_locations.every(item => item.title) ? false : true}
							onClick={() => {
								addAdditionalDropoff()
							}}
						/>
					</FadeInComponent>

					{

					}

					{/* add to address button  */}
					<FadeInComponent>
						<OutLineButton
							type={"submit"}
							title="Add to address book"
							buttonStyle={{ marginBottom: "1rem", }}
							disabled={
								![...postLoadMeta?.locations?.pickup, ...postLoadMeta?.locations?.dropoff]?.find(item => item?.raw_address == dropoffs_locations[dropoffs_locations.length - 1]?.address && item?.title == dropoffs_locations[dropoffs_locations.length - 1]?.title)?.id && dropoffs_locations[dropoffs_locations.length - 1]?.address && dropoffs_locations[dropoffs_locations.length - 1]?.title
									? false
									: true
							}
							onClick={() => {
								if (validateNameNumber(dropoffs_locations[dropoffs_locations.length - 1]?.contact_name, dropoffs_locations[dropoffs_locations.length - 1]?.contact_phone_no)) {
									dispatch(
										DrawerAction.addLocation({
											data: { ...dropoffs_locations[dropoffs_locations.length - 1], type: 'dropoff', raw_address: dropoffs_locations[dropoffs_locations.length - 1]?.address, model_name: 'user',  lat: dropoffs_locations[dropoffs_locations.length - 1]?.lat ,  lng: dropoffs_locations[dropoffs_locations.length - 1]?.lng},
										})
									);
								}
							}}
						/>
					</FadeInComponent>
				</div>

				{
					PostLoad?.inquiry_type !== 2 ?
						<FadeInComponent>
							<div className={
								is_empty_return
									? "selectBoxWrapper"
									: ""
							}>
								{load_type?.title === LOAD_TYPE?.fcl && (
									<SimpleCheckBox
										checked={is_empty_return}
										handleChange={(e) => {
											if (!e.target.checked) {
												setYard("");
											}
											setEmpty(e.target.checked);
										}}
										labelName={"Empty Pickup or Return Yard / City"}
										title={"Empty Pickup or Return Yard / City"}
									/>
								)}
								{is_empty_return && (
									<div className="inputWrapper">
										<div className="inputField">
											<div className="label">
												Name of Yard/City
											</div>
											<div className="field">
												<SimpleInput
													isPKR={false}
													value={empty_return_yard_name}
													placeholder="Enter Name of Yard/City"
													onChange={(e) => {
														setYard(e.target.value);
													}}
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						</FadeInComponent>
						: null
				}
			</div>

			<PrimaryButton
				buttonStyle={{
					marginTop: 14
				}}
				rightIcon={true}
				title="Confirm Location details"
				type="submit"
				disabled={(dropoffs_locations.every(item => item.title) && pickups_locations.every(item => item.title)) ? false : true}
				onClick={() => {
					let new_location = [pickups_locations[0], dropoffs_locations[0]]
					let additional_pickups = pickups_locations.slice(1)
					let additional_dropoffs = dropoffs_locations.slice(1)
					if (new_location[0]?.title == new_location[1]?.title) {
						showMessage.current({ message: "Pickup and dropoff location should not be same", status: 'error' })
					} else {
						dispatch(
							ProfileAction.postLoadData({
								location: new_location,
								is_empty_return,
								empty_return_yard_name,
								additional_pickups: additional_pickups.map((item) => { return { ...item, type: "pickup" } }),
								additional_dropoffs: additional_dropoffs.map((item) => { return { ...item, type: "dropoff" } })
							})
						);
						history.push('/response-deadline')
					}
				}}
			/>

			<CustomModal
				modalStyle={{ width: 300, minHeight: 100 }}
				className="infoModal"
				showModal={showModal}
				toggleModal={toggleModal}
			>
				<div>
					<InfoIcon className="icon" />
					<p className="title">Information</p>
					<p>{info}</p>
				</div>
			</CustomModal>
		</TitleLayout >
	);
};